@if (loadingCount) {
  <mat-progress-bar> </mat-progress-bar>
}

@if (loadingCount === 0) {
  <mat-form-field>
    <mat-label>
      {{ selectedMixIdFormControl.value ? 'Mix ' + selectedMixIdFormControl.value + ' Selected' : 'Select Mix' }}
    </mat-label>
    <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" />
    @if (selectedMixIdFormControl.value) {
      <button
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="selectedMixIdFormControl.setValue(null); myControl.setValue(null)"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
    <mat-autocomplete
      (optionSelected)="selectedMixIdFormControl.setValue($event.option.value.mix.id)"
      #auto="matAutocomplete"
      [displayWith]="displayFn"
    >
      @for (mix of filteredMixes | async; track mix) {
        <mat-option [value]="mix">
          {{ mix.mix.title }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
