<h1 class="slumber-text-align-center">People</h1>

<div class="slumber-add-container">
  <button (click)="reloadPeople()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>
  <a routerLink="create" mat-button> Add New Person </a>
</div>

@if (peopleList.loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="persons-table-container">
  <table mat-table [dataSource]="people" matSort matSortActive="person.id" matSortDisableClear matSortDirection="asc">
    <ng-container matColumnDef="person.id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.person.id }}</td>
    </ng-container>

    <ng-container matColumnDef="person.imageFile.url">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="img-container">
        @if (element.person.imageFile && element.person.imageFile.url) {
          <img [src]="element.person.imageFile.url" alt="" />
        }
        @if (!element.person.imageFile || !element.person.imageFile.url) {
          <img src="/assets/person_blank.png" alt="" />
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="person.personTranslation.displayName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.person.personTranslation.firstName }} {{ element.person.personTranslation.lastName }}
        @if (element.person.personTranslation.displayName) {
          <span> ({{ element.person.personTranslation.displayName }}) </span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="person.gender">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gender</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        <div>
          @switch (element.person.gender) {
            <!-- todo: make sure Gender is correct -->
            @case (1) {
              Male
            }
            @case (2) {
              Female
            }
            @case (9) {
              Other
            }
            @default {
              Unknown
            }
          }
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="person.personTranslation.detail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Detail</th>
      <td mat-cell *matCellDef="let element">{{ element.person.personTranslation.detail }}</td>
    </ng-container>

    <ng-container matColumnDef="isNarrator">
      <th mat-header-cell *matHeaderCellDef>Author / Narrator</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        @if (element.isNarrator) {
          @if (element.isAuthor) {
            Author, Narrator
          }
          @if (!element.isAuthor) {
            Narrator
          }
        }
        @if (!element.isNarrator) {
          @if (element.isAuthor) {
            Author
          }
          @if (!element.isAuthor) {
            -
          }
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="'edit/' + element.person.id">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
