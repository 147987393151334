<h1>Create Category</h1>
@if (categoryFormGroup) {
  <form (ngSubmit)="save()">
    <app-category-form-elements [categoryFormGroup]="categoryFormGroup"> </app-category-form-elements>
    <div>
      <a routerLink=".." mat-button> Cancel </a>
      <button [disabled]="categoryFormGroup.invalid" type="submit" mat-button>Create Category</button>
      @if (loadingCount) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </form>
}
