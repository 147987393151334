import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { MaterialModule } from '../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadModule } from '../upload/upload.module';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { ConfirmNumberComponent } from './confirm-number/confirm-number.component';
import { ConfirmBoolComponent } from './confirm-bool/confirm-bool.component';

@NgModule({
  declarations: [ConfirmComponent, ConfirmNumberComponent, ConfirmBoolComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, UploadModule, FormElementsModule],
  exports: [ConfirmComponent],
})
export class DialogModule {}
