import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { StatusCodes } from '../constants';
import { ShowErrorService } from '../error-reporting/show-error.service';
import { Errors } from '../errors';
import { Item } from '../form-elements/chip-list2/chip-list2.component';
import { SlumberItemDTO } from '../items-helper.service';
import { ImageFile } from '../track/track.model';
import { isTrimmed } from '../utils';

export interface CollectionTranslation {
  id: number;
  collectionId: number;
  languageId: number;
  title: string;
  detail: string;
}

export interface Collection {
  id: number;
  statusCode: number;
  releasedAt: string;
  unpublishedAt: string;
  imageFileId: number;
  isSeries: boolean;
  collectionTranslation: CollectionTranslation;
  imageFile: ImageFile;
}

export interface CollectionInfoObsolete {
  collection: Collection;
  orderedTracks: any[]; // todo: was trackInfo / TrackInfo
  order?: number;
  isPinned?: boolean;
}

export interface CollectionDTO {
  collection: Collection;
  items: SlumberItemDTO[]; // tracks
}

export interface CollectionInfoWithCount extends CollectionInfoObsolete {
  countTracks: number;
}

export interface CollectionList {
  collections: CollectionInfoObsolete[];
  loadingCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionsService {
  public collectionList: CollectionList = {
    collections: [],
    loadingCount: 0,
  };
  collectionList$ = new Subject<CollectionList>();
  collections: CollectionInfoWithCount[];

  constructor(private http: HttpClient, private showErrorService: ShowErrorService) {}

  getCollections(): Observable<CollectionInfoObsolete[]> {
    return this.http.get<CollectionInfoObsolete[]>('/api/collections');
  }

  getCollectionsWithCounts(): Observable<CollectionInfoWithCount[]> {
    return this.http.get<CollectionInfoWithCount[]>('/api/collections/with-counts');
  }

  getCollection(id) {
    return this.http.get<CollectionDTO>(`/api/collections/${id}`);
  }

  makeCreateOrUpdateCollectionPayload(collectionFormGroupValue) {
    const collectionPayload = {
      ...collectionFormGroupValue,
    };
    if (collectionPayload.collection.imageFileId === 0) {
      delete collectionPayload.collection.imageFileId;
    }
    return collectionPayload;
  }

  updateCollection<T>(collectionPayload: T) {
    return this.http.put<T>(`/api/collections`, collectionPayload);
  }

  createCollection<T>(collectionPayload: T) {
    return this.http.post<T>(`/api/collections`, collectionPayload);
  }

  cleanForm(collectionPayload: any): [string[], boolean] {
    const warnings: string[] = [];
    if (isTrimmed(collectionPayload.collection.collectionTranslation.detail) === false) {
      collectionPayload.collection.collectionTranslation.detail =
        collectionPayload.collection.collectionTranslation.detail.trim();
      if (!collectionPayload.collection.collectionTranslation.detail) {
        return [warnings, false];
      }
      warnings.push('Detail has been trimmed automatically');
    }
    if (isTrimmed(collectionPayload.collection.collectionTranslation.title) === false) {
      collectionPayload.collection.collectionTranslation.title =
        collectionPayload.collection.collectionTranslation.title.trim();
      if (!collectionPayload.collection.collectionTranslation.title) {
        return [warnings, false];
      }
      warnings.push('Title has been trimmed automatically');
    }
    return [warnings, true];
  }

  reload() {
    this.collectionList.loadingCount += 1;
    this.getCollections()
      .pipe(
        finalize(() => {
          this.collectionList.loadingCount -= 1;
          this.collectionList$.next(this.collectionList);
        })
      )
      .subscribe({
        next: (collections: CollectionInfoObsolete[]) => {
          this.collectionList.collections.length = 0;
          this.collectionList.collections.push(...collections);
        },
        error: () => {
          this.showErrorService.showError(Errors.COLLECTION_LOADING_ERROR);
        },
      });
  }

  checkIsSeries(title: string, detail: string): boolean {
    const bookTerms = ['part', 'parts', 'series', 'classic', 'novel', 'novella', 'book', 'written', 'published'];
    return bookTerms.some((term) => title.toLowerCase().includes(term) || detail.toLowerCase().includes(term));
  }

  checkIfTracksContainCompilation(tracks: Item[]) {
    if (!tracks) return null;
    const seriesTerms = ['full book', 'complete', 'full series', 'full story'];
    return tracks.find((item) => {
      return seriesTerms.some((term) => item.name.toLowerCase().includes(term));
    });
  }

  checkIfTracksArePartOfASeries(tracks: Item[]) {
    if (!tracks) return false;
    const seriesTerms = ['part', 'full book', 'complete', 'full series', 'full story'];
    const track = tracks.find((item) => {
      return seriesTerms.some((term) => item.name.toLowerCase().includes(term));
    });
    return !!track;
  }

  checkFormError(formGroup: FormGroup, collectionId: number) {
    const collectionPayload = this.makeCreateOrUpdateCollectionPayload(formGroup.getRawValue());
    const [, isClean] = this.cleanForm(collectionPayload);
    if (isClean === false) {
      return { cleanFormRequired: true };
    }
    const collection = formGroup.get('collection');
    const statusCode = collection.get('statusCode').value;
    if (statusCode === StatusCodes.SCHEDULE || statusCode === StatusCodes.PUBLISHED) {
      if (!collection.get('imageFileId').value) {
        return { imageFileRequired: true };
      }
    }

    if (this.collections) {
      for (const collection of this.collections) {
        if (collection.collection.id === collectionId) continue;
        if (
          formGroup.get('collection.collectionTranslation.title').value.toLowerCase() ===
          collection.collection.collectionTranslation.title.toLowerCase()
        ) {
          return { collectionTitleExists: true };
        }
      }
    }
    return {};
  }
}
