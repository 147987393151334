<h1 class="slumber-text-align-center">Mixes</h1>

<div class="slumber-add-container">
  <button (click)="readMixes()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>
  <a routerLink="create" mat-button> Add New Mix </a>
</div>

@if (loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="mixes-table-container">
  <table mat-table [dataSource]="mixes" matSort matSortActive="mix.id" matSortDisableClear matSortDirection="asc">
    <ng-container matColumnDef="mix.id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.mix.id }}</td>
    </ng-container>

    <ng-container matColumnDef="mix.title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">{{ element.mix.title }}</td>
    </ng-container>

    <ng-container matColumnDef="mixTrackCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Track Count</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.mixTrackCount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="mixBackgroundTrackCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Background Track Count</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.mixBackgroundTrackCount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="'edit/' + element.mix.id">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
