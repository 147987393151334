import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { LANGUAGE_ID, StatusCodes } from '../../constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { CollectionsService } from '../collections.service';

@Component({
  selector: 'app-create-collection',
  templateUrl: './create-collection.component.html',
  styleUrls: ['./create-collection.component.scss'],
})
export class CreateCollectionComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  collectionFormGroup = this.fb.group(
    {
      collection: this.fb.group({
        statusCode: StatusCodes.DRAFT,
        releasedAt: null,
        unpublishedAt: null,
        imageFileId: null,
        isSeries: [false, Validators.required],
        collectionTranslation: this.fb.group({
          title: ['', Validators.required],
          detail: '',
          languageId: LANGUAGE_ID,
        }),
      }),
      items: [[]],
    },
    { validators: this.publishingRequirementsValidator.bind(this) },
  );

  initialForm: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private collectionsService: CollectionsService,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.collectionFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.collectionFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.initialForm = this.collectionFormGroup.value;
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    return this.collectionsService.checkFormError(formGroup, 0);
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const collectionPayload = this.collectionsService.makeCreateOrUpdateCollectionPayload(
        this.collectionFormGroup.getRawValue(),
      );
      const [warnings, _] = this.collectionsService.cleanForm(collectionPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.collectionsService
        .createCollection(collectionPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/collections`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.COLLECTION_CREATE_ERROR);
          },
        });
    });
  }
}
