import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

// can use this for testing

@Component({
  selector: 'app-test-upload',
  templateUrl: './test-upload.component.html',
  styleUrls: ['./test-upload.component.scss'],
})
export class TestUploadComponent implements OnInit {
  testIdCtrl = new FormControl('');

  constructor() {}

  ngOnInit(): void {}
}
