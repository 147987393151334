import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionsService } from './collections/collections.service';
import { Item, Kind } from './form-elements/chip-list2/chip-list2.component';
import { PeopleService } from './person/people.service';
import { TracksService } from './track/tracks.service';

export interface SlumberItemDTO {
  // used in Collections and in Categories etc
  kindId: number;
  order: number;
  kind: string; // track, person, collection
  isPinned: boolean;
}

const kindMap = { track: Kind.Track, person: Kind.Person, collection: Kind.Collection };

@Injectable({
  providedIn: 'root',
})
export class ItemsHelperService {
  constructor(
    private tracksService: TracksService,
    private collectionsService: CollectionsService,
    private peopleService: PeopleService
  ) {}

  allItems$(items: SlumberItemDTO[]): Observable<{ allItems: Item[]; selectedItems: Item[] }> {
    const people$ = this.peopleService.getPeople();
    const tracks$ = this.tracksService.getTracks();
    const collections$ = this.collectionsService.getCollections();
    // todo: make this a single api call
    return forkJoin([people$, tracks$, collections$]).pipe(
      map(([people, tracks, collectionsInfo]) => {
        const existingItems = [
          new Map<number, SlumberItemDTO>(),
          new Map<number, SlumberItemDTO>(),
          new Map<number, SlumberItemDTO>(),
        ];
        for (const item of items) {
          const kind: Kind = kindMap[item.kind];
          existingItems[kind].set(item.kindId, item);
        }
        const allItems: Item[] = [];
        for (const personInfo of people) {
          let order = null;
          let selected = false;
          let isPinned = false;
          if (existingItems[Kind.Person].has(personInfo.person.id)) {
            selected = true;
            const it = existingItems[Kind.Person].get(personInfo.person.id);
            order = it.order;
            isPinned = it.isPinned;
          }
          allItems.push({
            isFree: false,
            kindId: personInfo.person.id,
            kind: Kind.Person,
            name: personInfo.person.personTranslation.displayName,
            selected,
            order,
            isPinned,
          });
        }
        for (const track of tracks) {
          let order = null;
          let selected = false;
          let isPinned = false;
          if (existingItems[Kind.Track].has(track.id)) {
            selected = true;
            const it = existingItems[Kind.Track].get(track.id);
            order = it.order;
            isPinned = it.isPinned;
          }
          allItems.push({
            isFree: !track.isPremium,
            kindId: track.id,
            kind: Kind.Track,
            name: track.title,
            selected,
            order,
            isPinned,
          });
        }
        for (const collectionInfo of collectionsInfo) {
          let order = null;
          let selected = false;
          let isPinned = false;
          if (existingItems[Kind.Collection].has(collectionInfo.collection.id)) {
            selected = true;
            const it = existingItems[Kind.Collection].get(collectionInfo.collection.id);
            order = it.order;
            isPinned = it.isPinned;
          }
          allItems.push({
            isFree: false,
            kindId: collectionInfo.collection.id,
            kind: Kind.Collection,
            name: collectionInfo.collection.collectionTranslation?.title,
            selected,
            order,
            isPinned,
          });
        }
        const selectedItems = allItems.filter((item) => item.selected);
        selectedItems.sort((a, b) => {
          return a.order - b.order;
        });
        return { allItems, selectedItems };
      })
    );
  }

  toggleItem(item: Item, selectedItems: Item[], isSeries: boolean, insertAtTheBeginning: boolean = true) {
    if (item.selected) {
      if (isSeries) {
        // add the item to the end
        item.order = selectedItems.length + 1;
        selectedItems.push(item);
      } else {
        // add the item at the start
        if (insertAtTheBeginning) {
          item.order = 1;
          selectedItems.unshift(item);
        } else {
          item.order = selectedItems.length + 1;
          selectedItems.push(item);
        }

        let ord = 2;
        for (let i = 1; i < selectedItems.length; i++) {
          if (selectedItems[i].isPinned) {
            const prev = selectedItems[i - 1];
            selectedItems[i - 1] = selectedItems[i];
            selectedItems[i] = prev;
            prev.order = ord;
          } else {
            selectedItems[i].order = ord;
          }
          ord += 1;
        }
      }
    } else {
      // delete the item
      const idx = selectedItems.indexOf(item);
      selectedItems.splice(idx, 1);
      let ord = selectedItems.length;
      for (let i = selectedItems.length - 1; i >= idx; i--) {
        if (i + 1 < selectedItems.length && selectedItems[i].isPinned) {
          const prev = selectedItems[i + 1];
          selectedItems[i + 1] = selectedItems[i];
          selectedItems[i] = prev;
          prev.order = ord;
        } else {
          selectedItems[i].order = ord;
        }
        ord -= 1;
      }
    }
  }

  selectedItemsToSlumberItems(selectedItems: Item[]): SlumberItemDTO[] {
    return selectedItems.map((item: Item): SlumberItemDTO => {
      let kind = 'unknown';
      switch (+item.kind) {
        case Kind.Track:
          kind = 'track';
          break;
        case Kind.Collection:
          kind = 'collection';
          break;
        case Kind.Person:
          kind = 'person';
          break;
        default:
          console.log('unknown collection item kind');
          break;
      }
      return {
        kindId: item.kindId,
        order: item.order,
        kind,
        isPinned: item.isPinned,
      };
    });
  }
}
