<h1 class="slumber-text-align-center">Background Tracks</h1>

<div class="slumber-add-container">
  <button (click)="readBackgroundTracks()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>
  <a routerLink="create" mat-button> Add New Background Track </a>
</div>

@if (loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="background-tracks-table-container">
  <table
    mat-table
    [dataSource]="backgroundTracks"
    matSort
    matSortActive="backgroundTrack.id"
    matSortDisableClear
    matSortDirection="asc"
  >
    <ng-container matColumnDef="backgroundTrack.id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.backgroundTrack.id }}</td>
    </ng-container>

    <ng-container matColumnDef="backgroundTrack.imageFile.url">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="img-container">
        @if (element.backgroundTrack.imageFile && element.backgroundTrack.imageFile.url !== '(unknown)') {
          <img [src]="element.backgroundTrack.imageFile.url" alt="" />
        } @else {
          <img src="/assets/placeholder.png" alt="" />
        }
        <ng-template #placeholder>
          <img src="/assets/placeholder.png" alt="" />
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="backgroundTrack.backgroundTrackTranslation.title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">{{ element.backgroundTrack.backgroundTrackTranslation.title }}</td>
    </ng-container>

    <ng-container matColumnDef="backgroundTrack.isMusic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Music</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        @if (element.backgroundTrack.isMusic) {
          <mat-icon>check</mat-icon>
        }
        @if (!element.backgroundTrack.isMusic) {
          <mat-icon>close</mat-icon>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="mixCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mix Count</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.mixCount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="'edit/' + element.backgroundTrack.id">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
