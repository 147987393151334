export enum Errors {
  SERVER_ERROR = 'server error',
  SAVING_ERROR = 'error saving',
  RE_ORDERING_ERROR = 'error re-ordering',
  DURATION_UPDATE_ERROR = 'error updating duration',
  UNSUPPORTED_FILE_ERROR = 'please upload a valid image format file: JPG, JPEG or PNG',
  UNCOMPRESSED_FILE_ERROR = 'please upload an uncompressed .wav format file',
  CHUNK_UPLOAD_ERROR = 'error uploading chunks, try again',
  USER_DELETE_ERROR = 'error deleting user',
  ROLE_CHANGE_ERROR = 'error changing role',
  BACKGROUND_CREATE_ERROR = 'error creating background track',
  BACKGROUND_UPDATE_ERROR = 'error updating background track',
  CATEGORY_CREATE_ERROR = 'error creating category',
  CATEGORY_UPDATE_ERROR = 'error updating category',
  CATEGORY_DELETE_ERROR = 'error deleting category',
  COLLECTION_LOADING_ERROR = 'error loading collections',
  COLLECTION_CREATE_ERROR = 'error creating collection',
  COLLECTION_UPDATE_ERROR = 'error updating collection',
  COLLECTION_DELETE_ERROR = 'error deleting collection',
  HOME_CREATE_ERROR = 'error creating home',
  HOME_UPDATE_ERROR = 'error updating home',
  HOME_DELETE_ERROR = 'error deleting home',
  MIX_LOADING_ERROR = 'error loading mixes',
  MIX_CREATE_ERROR = 'error creating mix',
  MIX_UPDATE_ERROR = 'error updating mix',
  MIX_DELETE_ERROR = 'error deleting mix',
  PEOPLE_LOADING_ERROR = 'error loading people',
  PERSON_CREATE_ERROR = 'error creating person',
  PERSON_UPDATE_ERROR = 'error updating person',
  PERSON_DELETE_ERROR = 'error deleting person',
  RUNNING_JOB_ERROR = 'error running all jobs',
  TRACK_LOADING_ERROR = 'error loading tracks',
  TRACK_CREATE_ERROR = 'error creating track',
  TRACK_UPDATE_ERROR = 'error updating track',
  TRACK_DELETE_ERROR = 'error deleting track',
}
