import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TrackList, TracksService } from '../tracks.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TrackListInfo } from '../track.model';

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss'],
})
export class TracksComponent implements OnInit, AfterViewInit {
  trackList = this.trackService.trackList;
  tracks = new MatTableDataSource<TrackListInfo>();
  displayedColumns: string[] = ['id', 'title', 'detail', 'isPremium', 'statusTitle', 'releasedAt', 'edit'];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private trackService: TracksService) {}

  ngOnInit(): void {
    this.reloadTracks();
  }

  reloadTracks() {
    this.trackService.reloadTracks();
    this.trackService.trackList$.subscribe((newData: TrackList) => {
      this.tracks.data = newData.tracks;
    });
  }

  ngAfterViewInit() {
    this.tracks.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tracks.filter = filterValue.trim().toLowerCase();
  }
}
