import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface Administrator {
  id: number;
  email: string;
  role_id: number;
}

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<Administrator[]> {
    return this.http.get<Administrator[]>('/api/administrators');
  }

  deleteUser(adminId: number) {
    return this.http.delete<any>(`/api/administrators/${adminId}`);
  }

  createUser(user: Administrator) {
    return this.http.post<any>('/api/administrators', user);
  }

  changeRole(user: Administrator, newRoleId: number): Observable<Administrator> {
    const newAdmin: Administrator = { ...user, ...{ role_id: newRoleId } };
    return this.http.put<Administrator>(`/api/administrators`, newAdmin);
  }
}
