<h1>Edit Person</h1>
@if (!personFormGroup) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (personFormGroup) {
  <form (ngSubmit)="save()">
    <app-person-form-elements [personFormGroup]="personFormGroup"> </app-person-form-elements>
    <div>
      <a routerLink="../.." mat-button> Cancel </a>
      <button [disabled]="personFormGroup.invalid" type="submit" mat-button>Update Person</button>
      @if (personFormGroup.invalid && personFormGroup.get('person').get('personTranslation.displayName').valid) {
        <button type="submit" mat-button>Incomplete, Save Anyway</button>
      }
      @if (loadingCount) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </form>
}
