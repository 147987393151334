export const makeKeyFromTitle = (title: string) => {
  return title
    .trim()
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '_')
    .replace(/\_+/g, '_')
    .toUpperCase();
};

export const toCapitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
