import { NgModule } from '@angular/core';
import { ErrorsListBottomSheetComponent } from './errors-list-bottom-sheet/errors-list-bottom-sheet.component';
import { CommonModule } from '@angular/common';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ErrorsListBottomSheetComponent],
  imports: [CommonModule, MatBottomSheetModule, MatBadgeModule, MatButtonModule, MatIconModule],
  bootstrap: [ErrorsListBottomSheetComponent], // bootstrapped entry component
})
export class ErrorReportingModule {}
