import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SlumberItemDTO } from '../items-helper.service';
import { isTrimmed } from '../utils';

export interface CategoryTranslation {
  id: number;
  categoryId: number;
  languageId: number;
  title: string;
  detail: string;
}

export interface Category {
  id: number;
  key: string;
  statusCode: number;
  releasedAt: string;
  unpublishedAt: string;
  order: number;
  categoryTypeId: number;
  translationContext: string;
  categoryTranslation: CategoryTranslation;
}

export interface CategoryDTO {
  category: Category;
  items: SlumberItemDTO[];
}

export interface CategoryType {
  id: number;
  title: string;
}

export interface CategoryInfoWithCount extends CategoryDTO {
  countTracks: number;
  countPeople: number;
  countCollections: number;
}

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  categories: CategoryInfoWithCount[];

  constructor(private http: HttpClient) {}

  getCategories(): Observable<CategoryDTO[]> {
    return this.http.get<CategoryDTO[]>('/api/categories');
  }

  getCategoryTypes(): Observable<CategoryType[]> {
    return this.http.get<CategoryType[]>('/api/category-types');
  }

  getCategoriesForType(categoryTypeId: number): Observable<CategoryDTO[]> {
    return this.http.get<CategoryDTO[]>(`/api/categories?categoryTypeID=${categoryTypeId}`);
  }

  getCategoriesWithCounts(): Observable<CategoryInfoWithCount[]> {
    return this.http.get<CategoryInfoWithCount[]>('/api/categories/with-counts');
  }

  changeOrder(orderedCategoryIds: number[]) {
    const payload = { orderedCategoryIds };
    return this.http.post<any>('/api/categories/change-order', payload);
  }

  getCategory(id: any) {
    return this.http.get<CategoryDTO>(`/api/categories/${id}`);
  }

  makeCreateOrUpdateCategoryPayload(categoryFormGroupValue) {
    // {
    // "category":{"id":13,"statusCode":800,"releasedAt":"2020-03-11T10:10:34Z","order":1},
    // "categoryTranslation":{"id":33,"categoryId":13,"title":"Music","detail":" ","languageId":1},
    // "orderedTrackIds":[5],"orderedPeopleIds":[1,2],"orderedCollectionIds":[1,2]
    // }
    return {
      ...categoryFormGroupValue,
    };
  }

  updateCategory<T>(categoryPayload: T) {
    return this.http.put<T>(`/api/categories`, categoryPayload);
  }

  createCategory<T>(categoryPayload: T) {
    return this.http.post<T>(`/api/categories`, categoryPayload);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete(`/api/categories/${categoryId}`);
  }

  cleanForm(categoryPayload: any): [string[], boolean] {
    const warnings: string[] = [];
    if (isTrimmed(categoryPayload.category.categoryTranslation.detail) === false) {
      categoryPayload.category.categoryTranslation.detail = categoryPayload.category.categoryTranslation.detail.trim();
      if (!categoryPayload.category.categoryTranslation.detail) {
        return [warnings, false];
      }
      warnings.push('Detail has been trimmed automatically');
    }
    if (isTrimmed(categoryPayload.category.categoryTranslation.title) === false) {
      categoryPayload.category.categoryTranslation.title = categoryPayload.category.categoryTranslation.title.trim();
      if (!categoryPayload.category.categoryTranslation.title) {
        return [warnings, false];
      }
      warnings.push('Title has been trimmed automatically');
    }
    if (isTrimmed(categoryPayload.category.translationContext) === false) {
      categoryPayload.category.translationContext = categoryPayload.category.translationContext.trim();
      if (!categoryPayload.category.translationContext) {
        return [warnings, false];
      }
      warnings.push('Translation context has been trimmed automatically');
    }
    return [warnings, true];
  }

  checkFormError(formGroup: FormGroup, categoryId: number) {
    const categoryPayload = this.makeCreateOrUpdateCategoryPayload(formGroup.getRawValue());
    const [, isClean] = this.cleanForm(categoryPayload);
    if (isClean === false) {
      return { cleanFormRequired: true };
    }

    if (this.categories) {
      for (const category of this.categories) {
        if (category.category.id === categoryId) continue;
        if (
          formGroup.get('category.categoryTranslation.title').value.toLowerCase() ===
          category.category.categoryTranslation.title.toLowerCase()
        ) {
          return { categoryTitleExists: true };
        }
      }
    }
    return {};
  }
}
