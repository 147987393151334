<h1 matDialogTitle>
  {{ confirmNumberOpts.title }}
</h1>

<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{ confirmNumberOpts.label }}</mat-label>
    <input type="number" matInput [(ngModel)]="returnNumber" />
    @if (confirmNumberOpts.icon) {
      <mat-icon matSuffix>{{ confirmNumberOpts.icon }}</mat-icon>
    }
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="{ number: returnNumber }">
    {{ confirmNumberOpts.confirmButtonText }}
  </button>
</mat-dialog-actions>
