import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ShowErrorService } from '../error-reporting/show-error.service';
import { finalize } from 'rxjs/operators';
import { PersonInfo } from './people.model';
import { isTrimmed } from '../utils';
import { Errors } from '../errors';

export interface PeopleList {
  people: PersonInfo[];
  loadingCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class PeopleService {
  constructor(private http: HttpClient, private showErrorService: ShowErrorService) {}

  public peopleList: PeopleList = {
    people: [],
    loadingCount: 0,
  };
  peopleList$ = new Subject();

  getPeople(): Observable<PersonInfo[]> {
    const url = '/api/people';
    return this.http.get<PersonInfo[]>(url);
  }

  getPerson(id): Observable<PersonInfo> {
    const url = `/api/people/${id}`;
    return this.http.get<PersonInfo>(url);
  }

  reload() {
    this.peopleList.loadingCount += 1;
    this.getPeople()
      .pipe(
        finalize(() => {
          this.peopleList.loadingCount -= 1;
          this.peopleList$.next(this.peopleList);
        })
      )
      .subscribe(
        (ppl: PersonInfo[]) => {
          this.peopleList.people.length = 0;
          this.peopleList.people.push(...ppl);
        },
        (err) => {
          this.showErrorService.showError(Errors.PEOPLE_LOADING_ERROR);
        }
      );
  }

  updatePerson<T>(personPayload: T) {
    return this.http.put<T>(`/api/people`, personPayload);
  }

  createPerson<T>(personPayload: T) {
    return this.http.post<T>(`/api/people`, personPayload);
  }

  makeCreateOrUpdatePersonPayload(personFormGroupValue: any) {
    // if (personPayload.collection.imageFileId === 0) {
    //   delete personPayload.collection.imageFileId;
    // }
    return {
      ...personFormGroupValue,
    };
  }

  cleanForm(personPayload: any): [string[], boolean] {
    const warnings: string[] = [];
    if (isTrimmed(personPayload.person.personTranslation.detail) === false) {
      personPayload.person.personTranslation.detail = personPayload.person.personTranslation.detail.trim();
      if (!personPayload.person.personTranslation.detail) {
        return [warnings, false];
      }
      warnings.push('Detail has been trimmed automatically');
    }
    if (isTrimmed(personPayload.person.personTranslation.displayName) === false) {
      personPayload.person.personTranslation.displayName = personPayload.person.personTranslation.displayName.trim();
      if (!personPayload.person.personTranslation.displayName) {
        return [warnings, false];
      }
      warnings.push('Display name has been trimmed automatically');
    }
    if (isTrimmed(personPayload.person.personTranslation.lastName) === false) {
      personPayload.person.personTranslation.lastName = personPayload.person.personTranslation.lastName.trim();
      if (!personPayload.person.personTranslation.lastName) {
        return [warnings, false];
      }
      warnings.push('Last name has been trimmed automatically');
    }
    if (isTrimmed(personPayload.person.personTranslation.firstName) === false) {
      personPayload.person.personTranslation.firstName = personPayload.person.personTranslation.firstName.trim();
      if (!personPayload.person.personTranslation.firstName) {
        return [warnings, false];
      }
      warnings.push('First name has been trimmed automatically');
    }
    return [warnings, true];
  }
}
