import { BrowserModule, Title } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { AdministerUsersComponent } from './administer-users/administer-users.component';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorReportingModule } from './error-reporting/error-reporting.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from './dialog/dialog.module';
import { PersonModule } from './person/person.module';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { UploadModule } from './upload/upload.module';
import { TrackModule } from './track/track.module';
import { FormElementsModule } from './form-elements/form-elements.module';
import { CategoriesModule } from './categories/categories.module';
import { CollectionsModule } from './collections/collections.module';
import { BackgroundTracksModule } from './background-tracks/background-tracks.module';
import { MixModule } from './mix/mix.module';
import { RunJobsModule } from './run-jobs/run-jobs.module';
import { AuthInterceptor } from './auth.interceptor';
import { FirebaseStuffModule } from './firebase-stuff/firebase-stuff.module';
import { NavigationModule } from './shared/navigation/navigation.module';
import { ApiExplorerModule } from './api-explorer/api-explorer.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

const bugSnagProviders = [];
if (!environment.disableBugsnag) {
  bugSnagProviders.push({
    provide: ErrorHandler,
    useFactory: errorHandlerFactory,
  });
}

@NgModule({
  declarations: [AppComponent, AdministerUsersComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    RouterModule,
    ErrorReportingModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    PersonModule,
    UploadModule,
    TrackModule,
    FormElementsModule,
    CategoriesModule,
    CollectionsModule,
    BackgroundTracksModule,
    MixModule,
    RunJobsModule,
    ApiExplorerModule,
    FirebaseStuffModule,
    NgxJsonViewerModule,
    NavigationModule,
  ],
  providers: [
    ...bugSnagProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
