<button style="margin-bottom: 1em; margin-top: -1em; margin-left: -1em" mat-icon-button (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<div style="margin-bottom: 2em">
  <ng-container
    *ngTemplateOutlet="
      uploaderTemplate;
      context: {
        name: 'track images',
        accept: 'image/*',
        uploader: imageUploader,
        onDropZone: {}
      }
    "
  >
  </ng-container>
</div>
<ng-container
  *ngTemplateOutlet="
    uploaderTemplate;
    context: {
      name: 'track audio',
      accept: 'audio/*',
      uploader: mediaUploader,
      onDropZone: {}
    }
  "
>
</ng-container>

<ng-template #uploaderTemplate let-accept="accept" let-name="name" let-uploader="uploader" let-onDropZone="onDropZone">
  <div class="header-bar">
    <div
      ng2FileDrop
      style="width: 220px"
      [ngClass]="{ 'red-border': onDropZone.yes }"
      (fileOver)="onDropZone.yes = $event"
      [uploader]="uploader"
      class="drop-zone"
    >
      Drag & drop {{ name }}
    </div>
    <div class="upload-button-container">
      <label>
        <input multiple [accept]="accept" type="file" ng2FileSelect [uploader]="uploader" style="display: none" />
        <a style="width: 220px" mat-raised-button>
          <mat-icon>file_upload</mat-icon>
          Bulk upload {{ name }}
        </a>
      </label>
    </div>
  </div>

  <div class="max-height">
    @for (item of uploader.queue; track item) {
      <app-upload-queue-item
        [thumb]="item['_thumb']"
        [audio]="item['_audio']"
        [fileName]="item?.file?.name"
        [fileSize]="item?.file?.size"
        [progress]="item?.progress"
        [isSuccess]="item?.isSuccess"
        [isError]="item?.isError"
      >
      </app-upload-queue-item>
    }
  </div>
</ng-template>
