import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { CollectionInfoWithCount, CollectionsService } from '../collections.service';

@Component({
  selector: 'app-collections-list',
  templateUrl: './collections-list.component.html',
  styleUrls: ['./collections-list.component.scss'],
})
export class CollectionsListComponent implements OnInit, AfterViewInit {
  loadingCount = 0;
  collections = new MatTableDataSource<CollectionInfoWithCount>();

  displayedColumns: string[] = [
    'collection.id',
    'collection.imageFile.url',
    'collection.collectionTranslation.title',
    'collection.collectionTranslation.detail',
    'collection.isSeries',
    'collection.statusCode',
    'countTracks',
    'edit',
  ];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private collectionsService: CollectionsService) {}

  ngOnInit(): void {
    this.reloadCollections();
  }

  reloadCollections() {
    this.loadingCount += 1;
    this.collectionsService
      .getCollectionsWithCounts()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((collections) => {
        this.collections.data = collections;
        this.collectionsService.collections = collections;
      });
  }

  ngAfterViewInit() {
    this.collections.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'collection.id':
          return item.collection.id;
        case 'collection.collectionTranslation.title':
          return item.collection.collectionTranslation.title;
        case 'collection.collectionTranslation.detail':
          return item.collection.collectionTranslation.detail;
        case 'collection.isSeries':
          return item.collection.isSeries;
        case 'collection.statusCode':
          return item.collection.statusCode;
        default:
          return item[property];
      }
    };
    this.collections.sort = this.sort;
  }
}
