<h1 matDialogTitle>
  {{ confirmBoolOpts.title }}
</h1>

<mat-dialog-content> </mat-dialog-content>

<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true">
    {{ confirmBoolOpts.trueButtonText }}
  </button>
  @if (showFalse) {
    <button mat-button [mat-dialog-close]="false">
      {{ confirmBoolOpts.falseButtonText }}
    </button>
  }
</mat-dialog-actions>
