import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { finalize } from 'rxjs/operators';
import { FormControl, FormRecord, Validators } from '@angular/forms';
import { ComponentType } from '@angular/cdk/overlay';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { Errors } from '../../errors';

type Kind =
  | 'image'
  | 'media'
  | 'text'
  | 'chip-list'
  | 'select'
  | 'radio'
  | 'toggle'
  | 'checkbox'
  | 'textarea'
  | 'datetime'
  | 'custom'
  | 'portal';
type HtmlString = string;
type Opt = [string, any];

interface Options {
  multiple?: boolean; // if mat-select can be multiple selection
  addSelectOption?: (opts: Opt[]) => void;
  opts?: Opt[];
  template?: TemplateRef<any>; // if type is custom, provide a template
  required?: boolean; // default false
  disabled?: boolean; // default false
  // warn?: boolean;
  subfolder?: 'person' | 'track' | 'collection' | 'background_track';
  portalComponentType?: ComponentType<any>; // could add TemplateRef too?
  portalAttached?: (componentRef: CdkPortalOutletAttachedRef, ctrl: CtrlContainer, keys: any) => any;
  createNgIf$?: (keys: any) => Observable<boolean>; // visible based on other form elements
}

// if key is email, validate for email
// [key, kind, optsIfSelect, required, label]
// ['role_id', 'select', [['admin', 1], ['manager', 1]], true, 'role', null]
type CtrlArgs = [string, Kind, string, Options];

interface TemplateOpts {
  context: any; // context of the ngTemplateOutlet
  template: TemplateRef<any>;
  isTemplate: boolean;
  order?: number; // set internally
}

type Row = HtmlString | CtrlArgs | TemplateOpts;

export function createTemplateArgs(context: any, template: TemplateRef<any>): TemplateOpts {
  return { context, template, isTemplate: true };
}

export function createCtrlArgs(key: string, kind: Kind, label: string, options: Options): Row {
  return [key, kind, label, options];
}

export interface ConfirmOpts {
  title?: string;
  cancelButton?: string;
  okButton?: string;
  value?: any;
  rows?: Row[];
  okMethod?: (result) => Observable<any>;
  allowSaveAnyway?: (keys: { [key: string]: CtrlContainer }) => boolean;
}

export interface CtrlContainer {
  formControl: FormControl;
  kind: Kind;
  key: string;
  label: string;
  order: number; // css flex ordering
  options: Options;
  componentPortal?: ComponentPortal<any>; // todo could make this generic
  ngIf$: Observable<boolean>;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
  // @ViewChildren(CdkPortalOutlet) portals: QueryList<CdkPortalOutlet>;
  portalCtrls: CtrlContainer[] = [];

  formRecord = new FormRecord<FormControl<string | null>>({});
  htmlStrings: { htmlString: HtmlString; order: number }[] = [];
  ctrls: CtrlContainer[] = [];
  templates: TemplateOpts[] = [];
  keys = {};
  @Input() settings: ConfirmOpts;
  loadingCount = 0;

  constructor(
    private dialogRef: MatDialogRef<ConfirmComponent>,
    private showErrorService: ShowErrorService,
  ) {}

  ngOnInit(): void {
    if (this.settings.rows?.length) {
      if (this.settings.value === undefined) {
        alert('error no value provided');
      }
      this.settings.value = JSON.parse(JSON.stringify(this.settings.value));
      this.settings.rows.forEach((row: Row, order: number) => {
        if (typeof row === 'string') {
          this.htmlStrings.push({
            htmlString: row,
            order,
          });
          return;
        } else if ((row as TemplateOpts)?.isTemplate) {
          const templateOpts: TemplateOpts = row as TemplateOpts;
          templateOpts.order = order;
          this.templates.push(templateOpts);
          return;
        }
        const key = row[0];
        const kind = row[1];
        const label = row[2];
        const options: Options = row[3];
        let value = this.settings.value;
        let container = this.settings.value;
        for (const subKey of key.split('.')) {
          container = value;
          if (value[subKey] === undefined) {
            value = null;
            break;
          }
          value = value[subKey];
        }
        const validators = [];
        if (options?.required) {
          validators.push(Validators.required);
        }
        if (key === 'email') {
          validators.push(Validators.email);
        } else {
          if (key.includes('email')) {
            console.log('email not detected', key);
          }
        }
        const fc = new FormControl(value, {
          validators,
          updateOn: 'change', // was set to blur for first and last name
        });
        if (options?.disabled) {
          fc.disable();
        }
        fc.valueChanges.subscribe((newV) => {
          let o = this.settings.value;
          const keys = key.split('.');
          for (let i = 0; i + 1 < keys.length; i++) {
            if (o[keys[i]] === undefined) {
              o[keys[i]] = {};
            }
            o = o[keys[i]];
          }
          o[keys[keys.length - 1]] = newV;
        });
        if (kind === 'chip-list') {
          if (value === null) {
            fc.setValue([]);
          }
        }

        let componentPortal = null;
        if (kind === 'portal') {
          componentPortal = new ComponentPortal(options.portalComponentType);
          // this.portalCtrls.push(ctrl);
        }

        const ctrl: CtrlContainer = {
          formControl: fc,
          kind,
          key,
          label,
          order,
          options,
          componentPortal,
          ngIf$: of(true), // compute second loop
        };
        this.formRecord.addControl(key, fc);
        this.keys[ctrl.key] = ctrl;
        this.ctrls.push(ctrl);
      });
      for (const ctrl of this.ctrls) {
        if (ctrl.options.createNgIf$ !== undefined) {
          ctrl.ngIf$ = ctrl.options.createNgIf$(this.keys);
        }
      }
    }
  }

  ok() {
    let obs$ = of(this.settings.value || true);
    if (this.settings.okMethod !== undefined) {
      obs$ = this.settings.okMethod(this.settings.value);
    }

    this.loadingCount += 1;
    obs$.pipe(finalize(() => (this.loadingCount -= 1))).subscribe({
      next: (r) => this.dialogRef.close(r),
      error: (e) => this.showErrorService.showError(Errors.SAVING_ERROR),
    });
  }
}
