import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { TenantAppRouter } from 'src/app/constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { CategoriesService, CategoryDTO } from '../categories.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss'],
})
export class EditCategoryComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  isDefaultSlumber = true;
  categoryFormGroup: FormGroup;
  categoryDTO: CategoryDTO;

  initialForm: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private categoriesService: CategoriesService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.categoryFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.categoryFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
    if (defaultAppFromURL === TenantAppRouter.DSS) {
      this.isDefaultSlumber = false;
    }

    this.loadingCount += 1;
    this.categoriesService
      .getCategory(this.activeRoute.snapshot.params.id)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((c: CategoryDTO) => {
        this.categoryDTO = c;
        this.categoryFormGroup = this.fb.group(
          {
            category: this.fb.group({
              id: { value: c.category.id, disabled: true },
              key: { value: c.category.key, disabled: true },
              translationContext: c.category.translationContext,
              statusCode: [c.category.statusCode, Validators.required],
              releasedAt: c.category.releasedAt,
              unpublishedAt: c.category.unpublishedAt,
              order: c.category.order,
              categoryTypeId: { value: c.category.categoryTypeId, disabled: true },
              categoryTranslation: this.fb.group({
                id: c.category.categoryTranslation.id,
                categoryId: c.category.categoryTranslation.categoryId,
                title: [c.category.categoryTranslation.title, Validators.required],
                detail: c.category.categoryTranslation.detail,
                languageId: c.category.categoryTranslation.languageId,
              }),
            }),
            items: [c.items],
          },
          { validators: this.publishingRequirementsValidator.bind(this) },
        );

        this.initialForm = this.categoryFormGroup.value;
      });
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    if (deepEqual(this.initialForm, formGroup.value)) {
      return { changeRequired: true };
    }
    return this.categoriesService.checkFormError(formGroup, parseInt(formGroup.get('category.id').value));
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const categoryPayload = this.categoriesService.makeCreateOrUpdateCategoryPayload(
        this.categoryFormGroup.getRawValue(),
      );
      const [warnings, _] = this.categoriesService.cleanForm(categoryPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.categoriesService
        .updateCategory(categoryPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/categories`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.CATEGORY_UPDATE_ERROR);
          },
        });
    });
  }

  deleteCategory() {
    this.dialogService
      .openConfirmBool({
        title: 'Are you sure you want to delete this category',
        trueButtonText: 'Delete',
        falseButtonText: 'Cancel',
      })
      .afterClosed()
      .subscribe((confirmDelete) => {
        if (confirmDelete) {
          this.loadingCount += 1;
          this.categoriesService
            .deleteCategory(this.activeRoute.snapshot.params.id)
            .pipe(finalize(() => (this.loadingCount -= 1)))
            .subscribe(
              () => {
                this.saved = true;
                let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
                this.router.navigateByUrl(`${defaultAppFromURL}/categories`);
              },
              () => {
                this.showErrorService.showError(Errors.CATEGORY_DELETE_ERROR);
              },
            );
        }
      });
  }
}
