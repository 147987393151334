<form (ngSubmit)="onSubmit()">
  <ng-container [formGroup]="transcriptFormGroup">
    <div class="transcriptFormGroup">
      <mat-form-field>
        <mat-label>Transcript</mat-label>
        <textarea matInput type="text" formControlName="textInput" rows="27"> </textarea>
      </mat-form-field>

      <div>
        <button (click)="cancel()" type="button" mat-button> Cancel </button>
        <button [disabled]="transcriptFormGroup.invalid" type="submit" mat-button>
          Save
        </button>
      </div>
    </div>
  </ng-container>
</form>
