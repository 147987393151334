import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { ConfirmOpts, createTemplateArgs } from '../../dialog/confirm/confirm.component';
import { skip, startWith } from 'rxjs/operators';
import { DialogService } from '../../dialog/dialog.service';
import { PersonInfo } from '../people.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-display-name',
  templateUrl: './display-name.component.html',
  styleUrls: ['./display-name.component.scss'],
})
export class DisplayNameComponent implements OnInit, OnDestroy {
  @Input() firstNameControl: FormControl;
  @Input() lastNameControl: FormControl;
  @Input() displayNameControl: FormControl;
  @Input() people: PersonInfo[];
  @ViewChild('duplicatePeopleTemplate', { static: true }) duplicatePeopleTemplate: TemplateRef<any>;
  sub = null;

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    if (!this.displayNameControl.value?.length) {
      this.sub = this.nameBlurs$().subscribe(([firstName, lastName]) => {
        const defaultName = this.displayName(firstName, lastName);
        if (!this.displayNameControl.touched) {
          this.displayNameControl.setValue(defaultName);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.sub !== null) {
      this.sub.unsubscribe();
    }
  }

  nameBlurs$(): Observable<[string, string]> {
    const firstNameFC = this.firstNameControl;
    const lastNameFC = this.lastNameControl;
    return combineLatest([
      firstNameFC.valueChanges.pipe(startWith(firstNameFC.value as string)),
      lastNameFC.valueChanges.pipe(startWith(lastNameFC.value as string)),
    ]).pipe(skip(1));
  }

  displayName(firstName, lastName): string {
    const names = [];
    if (firstName?.length) {
      names.push(firstName);
    }
    if (lastName?.length) {
      names.push(lastName);
    }
    return names.join(' ');
  }

  checkForDuplicate(id, firstName, lastName, displayName) {
    const fn = firstName.toLowerCase().trim();
    const ln = lastName.toLowerCase().trim();
    displayName = displayName.toLowerCase().trim();
    const duplicatePeople = new Set<PersonInfo>();
    for (const personInfo of this.people) {
      if (personInfo.person.id === id) {
        // skip for the same person
        continue;
      }
      if (fn.length && ln.length) {
        if (
          personInfo.person.personTranslation.firstName.toLowerCase() === fn &&
          personInfo.person.personTranslation.lastName.toLowerCase() === ln
        ) {
          duplicatePeople.add(personInfo);
        }
      }
      if (displayName.length && personInfo.person.personTranslation.displayName.toLowerCase().trim() === displayName) {
        duplicatePeople.add(personInfo);
      }
    }
    if (duplicatePeople.size) {
      console.log('dup', duplicatePeople);
      const opts: ConfirmOpts = {
        title: `Possible duplicate detected. A person with the same name already exists. Would you like to create the new person?`,
        okButton: 'Proceed',
        cancelButton: 'Cancel',
        value: {},
        rows: [createTemplateArgs({ dups: duplicatePeople }, this.duplicatePeopleTemplate)],
        allowSaveAnyway: (keys: any) => false,
      };

      return this.dialogService.openConfirm(opts);
    }

    return null;
  }
}
