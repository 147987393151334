import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SharedModule } from '../shared.module';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

@NgModule({
  declarations: [ToolbarComponent, FooterComponent, SidenavComponent, LoginComponent],
  imports: [SharedModule, MatButtonToggleModule],
  exports: [ToolbarComponent, FooterComponent, SidenavComponent, LoginComponent],
})
export class NavigationModule {}
