<ng-container [formGroup]="mixFormGroup">
  @if (mixFormGroup.get('mix.id')) {
    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="text" [formControl]="mixFormGroup.get('mix.id') | formControl" />
    </mat-form-field>
  }
  <ng-container formGroupName="mix">
    <mat-form-field>
      <mat-label>Title</mat-label>
      <input
        required
        matInput
        type="text"
        formControlName="title"
        (focusout)="onFocusOutEvent($event)"
        (input)="similarMixWarning = null"
      />
      @if (similarMixWarning) {
        <mat-hint>{{ similarMixWarning }}</mat-hint>
      }
    </mat-form-field>
  </ng-container>

  <h4 style="margin-top: 1em">Background Tracks</h4>
  <app-background-track-picker
    label="Select Background Tracks"
    [selectedBackgroundTracks]="mixFormGroup.get('selectedBackgroundTracks')"
  >
  </app-background-track-picker>

  <div class="slumber-color-red">
    @if (mixFormGroup.hasError('cleanFormRequired')) {
      <div>Please provide a value for all required fields</div>
    }
  </div>
</ng-container>
