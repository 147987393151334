import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetStageFromHost, Stages } from '../../constants';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';

@Component({
  selector: 'app-run-jobs',
  templateUrl: './run-jobs.component.html',
  styleUrls: ['./run-jobs.component.scss'],
})
export class RunJobsComponent implements OnInit {
  jobs = [];
  onDemandJobs = [];
  restoreDBJob = 'RestoreDatabase';

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private showErrorService: ShowErrorService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    interface JobList {
      jobsList: string[];
    }

    this.http.get<JobList>(`/api/jobs?jobType=all`).subscribe((r) => {
      this.jobs = r.jobsList;
      this.jobs.sort((a, b) => (a <= b ? -1 : 1));
    });

    this.http.get<JobList>(`/api/jobs?jobType=on-demand`).subscribe((r) => {
      r.jobsList.forEach((job) => {
        if (job === this.restoreDBJob && GetStageFromHost() === Stages.Prod) {
          return;
        }
        this.onDemandJobs.push(job);
      });
    });
  }

  runJob(job: string) {
    let title = `Run job ${job}`;
    let trueButtonText = 'Confirm';
    const falseButtonText = 'Cancel';

    if (job === this.restoreDBJob) {
      title =
        'Warning! Restoring the latest production database will erase all data currently on ' +
        'the staging server database and replace it with the data present on the last backup ' +
        'of the production database. Are you sure you want to proceed?';
      trueButtonText = 'Yes, wipe and restore';
    }

    this.dialogService
      .openConfirmBool({
        title,
        trueButtonText,
        falseButtonText,
      })
      .afterClosed()
      .subscribe((isConfirm) => {
        if (isConfirm) {
          this.http
            .post(
              `/api/jobs/${job}`,
              {},
              {
                responseType: 'text',
              }
            )
            .subscribe((r) => {
              this.snackBar.open(r, 'Close', { duration: 5000 });
            });
        }
      });
  }

  runAllScheduledJobs() {
    const path = '/api/jobs';
    this.dialogService
      .openConfirmBool({
        title: 'Run All Jobs',
        trueButtonText: 'Confirm',
        falseButtonText: 'Cancel',
      })
      .afterClosed()
      .subscribe((isConfirm) => {
        if (isConfirm) {
          this.http.post(path, {}).subscribe(
            (r) => {
              this.snackBar.open('All jobs running', 'Close', { duration: 5000 });
            },
            (err) => {
              this.showErrorService.showError(Errors.RUNNING_JOB_ERROR);
            }
          );
        }
      });
  }
}
