import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { CollectionDTO, CollectionsService } from '../collections.service';

@Component({
  selector: 'app-edit-collection',
  templateUrl: './edit-collection.component.html',
  styleUrls: ['./edit-collection.component.scss'],
})
export class EditCollectionComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  collectionFormGroup: FormGroup;

  initialForm: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private collectionsService: CollectionsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.collectionFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.collectionFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.loadingCount += 1;
    this.collectionsService
      .getCollection(this.activeRoute.snapshot.params.id)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((c: CollectionDTO) => {
        this.collectionFormGroup = this.fb.group(
          {
            collection: this.fb.group({
              id: { value: c.collection.id, disabled: true },
              statusCode: [c.collection.statusCode, Validators.required],
              releasedAt: [c.collection.releasedAt],
              unpublishedAt: c.collection.unpublishedAt,
              imageFileId: c.collection.imageFileId,
              isSeries: c.collection.isSeries,
              collectionTranslation: this.fb.group({
                id: c.collection.collectionTranslation.id,
                collectionId: c.collection.collectionTranslation.collectionId,
                title: [c.collection.collectionTranslation.title, Validators.required],
                detail: c.collection.collectionTranslation.detail,
                languageId: c.collection.collectionTranslation.languageId,
              }),
            }),
            items: [c.items],
          },
          { validators: this.publishingRequirementsValidator.bind(this) },
        );

        this.initialForm = this.collectionFormGroup.value;
      });
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    if (deepEqual(this.initialForm, formGroup.value)) {
      return { changeRequired: true };
    }
    return this.collectionsService.checkFormError(formGroup, parseInt(formGroup.get('collection.id').value));
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const collectionPayload = this.collectionsService.makeCreateOrUpdateCollectionPayload(
        this.collectionFormGroup.getRawValue(),
      );
      const [warnings, _] = this.collectionsService.cleanForm(collectionPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.collectionsService
        .updateCollection(collectionPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/collections`).then((r) => {});
          },
          error: (e) => {
            this.showErrorService.showError(Errors.COLLECTION_UPDATE_ERROR);
          },
        });
    });
  }
}
