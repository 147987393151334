import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  User,
  UserCredential,
  authState,
  signInWithPopup,
  signOut,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { EMPTY, Observable, first, from, of, switchMap } from 'rxjs';
import { StorageKeys } from '../../../constants';

@Component({
  selector: 'navigation-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  sendingToTracks = false;
  canSend = false;
  claimsLoading = 0;
  claims: { [p: string]: any };

  public user: Observable<User | null> = EMPTY;

  constructor(public afAuth: Auth, private http: HttpClient, private router: Router) {}

  ngOnInit() {
    this.user = authState(this.afAuth);
    authState(this.afAuth)
      .pipe(first())
      .subscribe((user) => {
        if (user == null) {
          console.log('no user is logged in');
          this.canSend = true;
        } else {
          this.verifyLogin(user, false);
        }
      });
  }

  public login() {
    signInWithPopup(this.afAuth, new GoogleAuthProvider()).then((uc: UserCredential) => {
      if (uc.user == null) {
        alert('user is null');
        return;
      }
      console.log('user logged in');
      this.verifyLogin(uc.user, true);
    });
  }

  public logout() {
    signOut(this.afAuth).then(
      () => {
        localStorage.removeItem(StorageKeys.VISITOR_ID);
        console.log('logout done');
      },
      () => {
        alert('logout failed');
      }
    );
  }

  private updateClaimsFromDB(user: User): Observable<{ roleChanged: boolean }> {
    return from(user.getIdToken(true)).pipe(
      switchMap((idToken) => {
        return this.http.post<{ roleChanged: boolean }>('/api/verify-login', { idToken });
      })
    );
  }

  public verifyLogin(user: User, force: boolean) {
    this.claimsLoading += 1;
    this.claims = null;
    console.log('verifying login force = ', force);
    let update$ = of({ roleChanged: false });
    if (force) {
      update$ = this.updateClaimsFromDB(user);
    }
    update$.subscribe({
      next: (r) => {
        user.getIdTokenResult(r.roleChanged).then(
          (token) => {
            // dont confuse token with idToken
            this.claims = token.claims;
            this.claimsLoading -= 1;
            if (this.claims[`administrator_role`]) {
              if (this.canSend) {
                this.sendingToTracks = true;
                setTimeout(() => {
                  this.router.navigateByUrl('');
                }, 2000);
              }
            }
          },
          () => {
            this.claimsLoading -= 1;
            setTimeout(() => {
              this.logout();
            }, 3000);
          }
        );
      },
      error: () => {
        this.claimsLoading -= 1;
        setTimeout(() => {
          this.logout();
        }, 3000);
      },
    });
  }
}
