@if (settings.title) {
  <div mat-dialog-title>
    {{ settings.title }}
  </div>
}
<form [formGroup]="formRecord" (ngSubmit)="ok()">
  <mat-dialog-content>
    <div style="display: flex; flex-direction: column">
      @for (htmlString of htmlStrings; track htmlString) {
        <div class="html-string-container" [style.order]="htmlString.order" [innerHTML]="htmlString.htmlString"></div>
      }

      @for (template of templates; track template) {
        <div [style.order]="template.order">
          <ng-container *ngTemplateOutlet="template.template; context: template.context"> </ng-container>
        </div>
      }

      @for (ctrl of ctrls; track ctrl) {
        <div [style.order]="ctrl.order">
          <!--   render embedded form element, either in provided wrapper or noop wrapper:   -->
          @if (ctrl.ngIf$ | async) {
            @if (true) {
              <ng-container *ngTemplateOutlet="noopWrappedFormElement; context: { $implicit: formElementTemplate }">
              </ng-container>
            }
          }
          <!--   capture form element in a template, to be passed to form element wrapper   -->
          <ng-template #formElementTemplate>
            <app-form-element [ctrl]="ctrl" [keys]="keys"> </app-form-element>
          </ng-template>
          <!--  -->
          <ng-template #noopWrappedFormElement let-formElementTemplateParam>
            <!--    do nothing special, just show the formelement    -->
            <ng-container *ngTemplateOutlet="formElementTemplateParam"> </ng-container>
          </ng-template>
        </div>
      }
    </div>

    <div style="height: 40px">
      <!--      spacer -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    @if (settings.cancelButton?.length) {
      <button [mat-dialog-close]="null" mat-button>
        {{ settings.cancelButton }}
      </button>
    }

    @if (settings.okButton) {
      <button [disabled]="formRecord.invalid" type="submit" mat-button>
        {{ settings.okButton }}
      </button>
    }

    @if (formRecord.invalid && settings.okButton && settings.allowSaveAnyway(keys)) {
      <button type="submit" mat-button>Incomplete, Save Anyway</button>
    }

    @if (loadingCount) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-dialog-actions>
</form>
