import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { MixesService } from '../mixes.service';

@Component({
  selector: 'app-create-mix',
  templateUrl: './create-mix.component.html',
  styleUrls: ['./create-mix.component.scss'],
})
export class CreateMixComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  mixFormGroup = this.fb.group(
    {
      mix: this.fb.group({
        title: '',
      }),
      selectedBackgroundTracks: [[]],
    },
    { validators: this.publishingRequirementsValidator.bind(this) },
  );

  initialForm: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private mixesService: MixesService,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.mixFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.mixFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.initialForm = this.mixFormGroup.value;
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    return this.mixesService.checkFormError(formGroup, 0);
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const mixPayload = this.mixesService.makeCreateOrUpdateMixPayload(this.mixFormGroup.getRawValue());
      const [warnings, _] = this.mixesService.cleanForm(mixPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.mixesService
        .createMix(mixPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/mixes`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.MIX_CREATE_ERROR);
          },
        });
    });
  }
}
