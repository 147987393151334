import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { deepEqual } from 'fast-equals';
import { DialogService } from '../../dialog/dialog.service';

@Component({
  selector: 'app-transcript',
  templateUrl: './transcript.component.html',
  styleUrl: './transcript.component.scss',
})
export class TranscriptComponent implements OnInit, AfterViewInit {
  initialForm: any;
  transcriptFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private ref: MatDialogRef<TranscriptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text: string | null },
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.transcriptFormGroup = this.fb.group({
      textInput: this.data.text,
    });

    this.initialForm = this.transcriptFormGroup.value;
  }

  cancel() {
    if (deepEqual(this.initialForm, this.transcriptFormGroup.value)) {
      this.ref.close(null);
    } else {
      this.dialogService.confirmCanDeactivate().subscribe((close) => {
        if (close === true) {
          this.ref.close(null);
        }
      });
    }
  }

  onSubmit() {
    this.ref.close(this.transcriptFormGroup.get('textInput').value);
  }
}
