import { Component, Input, OnInit } from '@angular/core';
import { PersonInfo } from '../people.model';

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class PersonComponent implements OnInit {
  @Input() personInfo: PersonInfo;
  constructor() {}

  ngOnInit(): void {}
}
