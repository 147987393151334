<ng-container [formGroup]="trackFormGroup">
  @if (trackFormGroup.get('track.id')) {
    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="text" [formControl]="trackFormGroup.get('track.id') | formControl" />
    </mat-form-field>
  }

  <ng-container formGroupName="track">
    <ng-container formGroupName="trackTranslation">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input
          required
          matInput
          type="text"
          formControlName="title"
          (focusout)="onFocusOutEvent($event)"
          (input)="similarTrackWarning = null"
        />
        @if (similarTrackWarning) {
          <mat-hint>{{ similarTrackWarning }}</mat-hint>
        }
      </mat-form-field>
    </ng-container>

    <mat-form-field>
      <mat-label>Key</mat-label>
      <input required matInput type="text" formControlName="key" />
    </mat-form-field>

    <ng-container formGroupName="trackTranslation">
      <mat-form-field>
        <mat-label>Detail</mat-label>
        <textarea required matInput type="text" formControlName="detail" rows="4"> </textarea>
      </mat-form-field>
    </ng-container>

    <div class="transcriptFieldContainer">
      <ng-container formGroupName="trackTranslation">
        <mat-form-field>
          <mat-label>Transcript</mat-label>
          <input matInput type="text" formControlName="transcript" readonly="true" />
        </mat-form-field>
      </ng-container>
      <button type="button" (click)="editTranscript()" mat-button>Edit</button>
    </div>

    @if (!isDefaultSlumber) {
      <mat-form-field>
        <mat-label>Translation Context</mat-label>
        <textarea matInput type="text" formControlName="translationContext" rows="2"> </textarea>
        <mat-hint class="translationContextHint"
          >The translation context is additional info that helps the translators make the best translation.
          <a
            href="https://www.notion.so/slumbergroup/Localization-75685da35cb244d29b791668dace4882?pvs=4#f8bd1b6184074a389c714607261a8d42"
            class="translationContextHintLink"
            target="_blank"
            >How to write a context.</a
          ></mat-hint
        >
      </mat-form-field>
    }

    <mat-slide-toggle [labelPosition]="'after'" formControlName="isPremium">
      <span class="label" style="display: grid; align-items: center; grid-gap: 1em; grid-template-columns: 100px 1fr">
        Is Premium
        <mat-icon matTooltip="True if the content is subscription only.  False if free."> info </mat-icon>
      </span>
    </mat-slide-toggle>

    @if (contentTypes !== null) {
      <mat-form-field>
        <mat-label>Content Type</mat-label>
        <mat-select required [value]="trackFormGroup.get('track').get('contentTypeId')" formControlName="contentTypeId">
          @for (contentType of contentTypes; track contentType) {
            <mat-option [value]="contentType.id"> {{ contentType.title }} </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </ng-container>

  @if (isDefaultSlumber) {
    <app-person-picker [personIdsControl]="trackFormGroup.get('narrators')" label="Narrator"> </app-person-picker>
  }
  @if (isDefaultSlumber) {
    <app-person-picker [personIdsControl]="trackFormGroup.get('authors')" label="Author"> </app-person-picker>
  }
  @if (categories === null) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  @if (categories !== null) {
    <mat-form-field>
      <mat-label>Categories</mat-label>
      <mat-select [required]="!isDefaultSlumber" [multiple]="true" formControlName="categories">
        @for (cat of categories; track cat) {
          <mat-option (onSelectionChange)="onCategorySelectionChange($event)" [value]="cat.category.id">
            {{ cat.category.categoryTranslation.title }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
  @if (collections === null) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }
  @if (collections !== null && isDefaultSlumber) {
    <mat-form-field>
      <mat-label>Collections</mat-label>
      <mat-select [multiple]="true" formControlName="collections">
        @for (col of collections; track col) {
          <mat-option [value]="col.collection.id"> {{ col.collection.collectionTranslation?.title }} </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <!-- todo: make this tags picker dropdown work on first click -->
  @if (allTags !== null && isDefaultSlumber) {
    <app-chip-list label="Tags" [optsAll]="allTags" [selectedOptsFormControl]="trackFormGroup.get('tags')">
    </app-chip-list>
  }

  <ng-container formGroupName="track">
    <app-publish-status
      [statusCodeControl]="statusCodeControl"
      [releasedAtControl]="releasedAtControl"
      [unpublishedAtControl]="unpublishedAtControl"
    >
    </app-publish-status>

    <label class="label">
      Image
      <app-media-upload
        style="margin-top: 0.5em; display: block"
        subfolder="track"
        fileType="image"
        [fileIdCtrl]="trackFormGroup.get('track').get('imageFileId') | formControl"
      >
      </app-media-upload>
    </label>

    <label class="label">
      Audio
      <app-media-upload
        style="margin-top: 0.5em; display: block"
        subfolder="track"
        fileType="media"
        [mediaPlatformFormatsInfo]="mediaPlatformFormatsInfo"
        [platforms]="trackFormGroup.get('platforms') | formControl"
        [parentActionType]="parentActionType"
        [fileIdCtrl]="trackFormGroup.get('track').get('mediaFileId') | formControl"
      >
      </app-media-upload>
    </label>
  </ng-container>

  @if (isDefaultSlumber) {
    <app-mix-picker [selectedMixIdFormControl]="selectedMixIdFormControl"></app-mix-picker>
  }

  <div class="slumber-color-red">
    @if (trackFormGroup.hasError('scheduleDate')) {
      <div>Scheduled without a publish date.</div>
    }
    @if (trackFormGroup.hasError('translationContextRequired')) {
      <div>Translation context is required for publishing.</div>
    }
    @if (trackFormGroup.hasError('imageFileRequired')) {
      <div>Image File required for publishing.</div>
    }
    @if (trackFormGroup.hasError('mediaFileRequired')) {
      <div>Media File required for publishing.</div>
    }
    @if (!trackFormGroup.get('narrators').value.length && isDefaultSlumber) {
      <div>
        Tracks without Narrators will not be visible to iOS app clients prior to version 3.2.0 released Nov 2020. If you
        want this track to be visible to all clients, you must include a Narrator.
      </div>
    }
    @if (trackFormGroup.hasError('audioProcessingRequired')) {
      <div>Media File did not process properly. Please contact the administrator.</div>
    }
    @if (trackFormGroup.hasError('cleanFormRequired')) {
      <div>Please provide a value for all required fields</div>
    }
    @if (trackFormGroup.hasError('mixRequired') && isDefaultSlumber) {
      <div>Mix is required for publishing.</div>
    }
    @if (trackFormGroup.hasError('titleAndKeySame')) {
      <div>Track title and key cannot be the same.</div>
    }
  </div>
</ng-container>
