import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { BackgroundTrackInfo, BackgroundTracksService } from '../../background-tracks/background-tracks.service';
import { SelectedBackgroundTrack } from '../mixes.service';

type Opt = [string, any];

@Component({
  selector: 'app-background-track-picker',
  templateUrl: './background-track-picker.component.html',
  styleUrls: ['./background-track-picker.component.scss'],
})
export class BackgroundTrackPickerComponent implements OnInit {
  @Input() selectedBackgroundTracks: AbstractControl;
  @Input() label = '';
  selectedOptsFormControl = new FormControl([]);
  loadingCount = 0;
  titleFromBackgroundTrackIdMap = {};
  showHotkeys = false;

  public trackOpts: Opt[] = [];

  constructor(private backgroundTrackService: BackgroundTracksService) {}

  ngOnInit(): void {
    this.loadingCount += 1;
    this.backgroundTrackService
      .getBackgroundTracks()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((backgroundTracks) => {
        this.setOpts(backgroundTracks);
      });
    this.selectedOptsFormControl.valueChanges.subscribe((opts) => {
      const backgroundVolMap = {};
      for (const selectedBackgroundTrack of this.selectedBackgroundTracks.value as SelectedBackgroundTrack[]) {
        backgroundVolMap[selectedBackgroundTrack.backgroundTrackId] = selectedBackgroundTrack.volume;
      }
      const newSelectedBackgroundTracks: SelectedBackgroundTrack[] = [];
      for (const [, backgroundTrackId] of opts) {
        if (backgroundTrackId === null) {
          console.log('error null track id');
        }
        let volume = 50;
        if (backgroundVolMap[backgroundTrackId] !== undefined) {
          volume = backgroundVolMap[backgroundTrackId];
        }
        newSelectedBackgroundTracks.push({ backgroundTrackId, volume });
      }
      this.selectedBackgroundTracks.setValue(newSelectedBackgroundTracks);
      if (newSelectedBackgroundTracks.length === 0) {
        this.selectedBackgroundTracks.setErrors({ incorrect: true });
      }
    });
  }

  onVolumeChange(event, backgroundTrack) {
    const updatedSelectedBackgroundTracks = this.selectedBackgroundTracks.value.map((b) => {
      let volume = b.volume;
      if (b.backgroundTrackId === backgroundTrack.backgroundTrackId) {
        volume = this.zeroTo100(parseInt(event.target.value) ?? event);
      }
      return {
        backgroundTrackId: b.backgroundTrackId,
        volume,
      };
    });
    this.selectedBackgroundTracks.setValue(updatedSelectedBackgroundTracks);
  }

  zeroTo100(v: number) {
    if (v < 0) {
      return 0;
    } else if (v > 100) {
      return 100;
    }
    return v;
  }

  setOpts(backgroundTracksInfo: BackgroundTrackInfo[]) {
    const optFromTrackId = {};
    this.trackOpts.length = 0;
    for (const backgroundTrackInfo of backgroundTracksInfo) {
      const names = [];
      names.push(backgroundTrackInfo.backgroundTrack.id.toString());
      if (backgroundTrackInfo.backgroundTrack.backgroundTrackTranslation.title?.trim().length) {
        names.push(backgroundTrackInfo.backgroundTrack.backgroundTrackTranslation.title);
      }
      const name = names.join(' ');
      const newOpt: Opt = [name, backgroundTrackInfo.backgroundTrack.id];
      this.trackOpts.push(newOpt);
      optFromTrackId[backgroundTrackInfo.backgroundTrack.id] = newOpt;
      this.titleFromBackgroundTrackIdMap[backgroundTrackInfo.backgroundTrack.id] = name;
    }
    const selectedOpts = [];
    for (const selectedBackgroundTrack of this.selectedBackgroundTracks.value as SelectedBackgroundTrack[]) {
      if (optFromTrackId[selectedBackgroundTrack.backgroundTrackId]) {
        selectedOpts.push(optFromTrackId[selectedBackgroundTrack.backgroundTrackId]);
      }
    }
    this.selectedOptsFormControl.setValue(selectedOpts, { emitEvent: false });
  }
}
