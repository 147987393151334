<h1>Create Track</h1>
@if (loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (trackFormGroup) {
  <form (ngSubmit)="save()">
    <app-track-form-elements
      [trackFormGroup]="trackFormGroup"
      [mediaPlatformFormatsInfo]="mediaPlatformFormatsInfo"
      parentActionType="createAction"
    >
    </app-track-form-elements>
    <div>
      <a routerLink=".." mat-button> Cancel </a>
      <button [disabled]="trackFormGroup.invalid" type="submit" mat-button>
        Create Track{{ !trackFormGroup.get('narrators').value.length && isDefaultSlumber ? ' without Narrator' : '' }}
      </button>
      @if (loadingCount) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </form>
}
