@if (alreadyPublished) {
  <div style="border: 1px solid lightgray; padding: 1em; border-radius: 5px">
    <label class="label">Publish</label>
    <div>
      @if (releasedAtControl?.value) {
        Published on {{ releasedAtControl.value | date: 'medium' }}.
      } @else {
        Unknown publish date
      }
      <a class="slumber-cursor-pointer slumber-color-red" (click)="unpublish()"> Unpublish </a>
    </div>
  </div>
} @else {
  <label class="label">
    Publish
    <mat-radio-group
      (change)="changeStatusCode($event)"
      style="display: flex; flex-direction: column"
      [formControl]="statusCodeCloneControl"
    >
      @for (opt of publishOpts; track opt) {
        <mat-radio-button style="margin: 5px" [value]="opt[1]">
          {{ opt[0] }}
        </mat-radio-button>
      }
    </mat-radio-group>
  </label>
  @if (showDateTimePicker) {
    <app-datetime-picker
      [label]="'Schedule ' + (releasedAtControl.value | date: 'medium')"
      [dateTimeFormControl]="releasedAtControl"
    >
    </app-datetime-picker>
  }
}
