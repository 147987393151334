<h1 style="margin-bottom: 1em">API Explorer</h1>

@if (!apiResponse) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="api-explorer">
  <div class="url-container">
    <mat-form-field appearance="fill" style="width: 800px; padding-top: 1.34375em">
      <mat-label>API URL</mat-label>
      <mat-select (selectionChange)="onApiURLChanged($event)" [value]="selectedApiURL">
        @for (apiURL of apiURLs; track apiURL) {
          <mat-option [value]="apiURL">
            {{ apiURL }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-button-toggle-group name="mobileClient" [value]="selectedMobileClient" (change)="onMobileClientChange($event)">
      <mat-button-toggle [value]="0">iOS</mat-button-toggle>
      <mat-button-toggle [value]="1">Android</mat-button-toggle>
    </mat-button-toggle-group>
    <section>
      <button mat-icon-button onclick="this.blur()" (click)="copyTapped()" matTooltip="Copy JSON">
        <mat-icon>file_copy</mat-icon>
      </button>
      <button mat-icon-button onclick="this.blur()" (click)="documentationTapped()" matTooltip="Swagger Documentation">
        <mat-icon>book</mat-icon>
      </button>
    </section>
  </div>

  @if (apiResponse) {
    <div class="api-explorer-container">
      <ngx-json-viewer [json]="apiResponse" [expanded]="false"></ngx-json-viewer>
    </div>
  }
</section>
