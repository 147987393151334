<h1 class="slumber-text-align-center">Categories</h1>

<div class="slumber-add-container">
  <button (click)="reloadCategories()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>

  @if (isDefaultSlumber) {
    <a routerLink="create" mat-button> Add New Category </a>
  }
</div>

@if (loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="categories-table-container">
  <table
    mat-table
    [dataSource]="categories"
    cdkDropList
    [cdkDropListData]="categories"
    (cdkDropListDropped)="onDrop($event)"
    [cdkDropListDisabled]="!isDefaultSlumber"
  >
    @if (isDefaultSlumber) {
      <ng-container matColumnDef="category.id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element" class="drag-handle">
          <ng-container [ngTemplateOutlet]="dragHandleTmpl"> </ng-container>
          {{ element.category.id }}
        </td>
      </ng-container>
    }

    @if (!isDefaultSlumber) {
      <ng-container matColumnDef="category.id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.category.id }}
        </td>
      </ng-container>
    }

    <ng-container matColumnDef="category.categoryTranslation.title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element">{{ element.category.categoryTranslation.title }}</td>
    </ng-container>

    <ng-container matColumnDef="category.categoryTranslation.detail">
      <th mat-header-cell *matHeaderCellDef>Detail</th>
      <td mat-cell *matCellDef="let element">{{ element.category.categoryTranslation.detail }}</td>
    </ng-container>

    <ng-container matColumnDef="category.statusCode">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">{{ element.category.statusCode }}</td>
    </ng-container>

    <ng-container matColumnDef="countTracks">
      <th mat-header-cell *matHeaderCellDef>Count</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.countTracks + element.countPeople + element.countCollections }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="'edit/' + element.category.id">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" cdkDrag [cdkDragData]="row"></tr>
  </table>
</section>

<ng-template #dragHandleTmpl>
  <svg
    class="bi bi-list handle"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
      clip-rule="evenodd"
    />
  </svg>
</ng-template>
