import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-queue-item',
  templateUrl: './upload-queue-item.component.html',
  styleUrls: ['./upload-queue-item.component.scss'],
})
export class UploadQueueItemComponent implements OnInit {
  @Input() thumb: string;
  @Input() audio: string;
  @Input() fileName: string;
  @Input() fileSize: number;
  @Input() progress: number;
  @Input() isSuccess: boolean;
  @Input() isError: boolean;

  constructor() {}

  ngOnInit(): void {}
}
