import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item, Kind } from '../../form-elements/chip-list2/chip-list2.component';

@Component({
  selector: 'app-reorder-items2',
  templateUrl: './reorder-items2.component.html',
  styleUrls: ['./reorder-items2.component.scss'],
})
export class ReorderItems2Component implements OnInit {
  Kind = Kind;
  @Input() enableCheckbox = true;
  @Input() items: Item[];
  @Output() change = new EventEmitter<CdkDragDrop<string[]>>();

  constructor() {}

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    let ord = 1;
    for (const item of this.items) {
      item.order = ord; // can update pinned items too.
      ord += 1;
    }
    this.change.emit();
  }
}
