import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { ConfirmOpts, createCtrlArgs } from '../dialog/confirm/confirm.component';
import { Errors } from '../errors';
import { Administrator, AdminUsersService } from '../admin-users.service';
import { ShowErrorService } from '../error-reporting/show-error.service';

interface AdminRow {
  admin: Administrator;
  roleCtrl: FormControl;
  deleted: boolean;
  loadingCount: number;
}

@Component({
  selector: 'app-administer-users',
  templateUrl: './administer-users.component.html',
  styleUrls: ['./administer-users.component.scss'],
})
export class AdministerUsersComponent implements OnInit {
  adminRows: AdminRow[] = [];
  name = new FormControl(2);
  loadingCount = 0;

  constructor(
    private showErrorService: ShowErrorService,
    private adminUsersService: AdminUsersService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.reloadAdmins();
  }

  reloadAdmins() {
    this.loadingCount += 1;
    this.adminUsersService
      .getUsers()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((u) => {
        this.adminRows = u.map((admin) => {
          const roleCtrl = new FormControl(admin.role_id);
          roleCtrl.valueChanges.subscribe((newRoleId: number) => this.changeRole(adminRow, newRoleId));
          const adminRow: AdminRow = {
            admin,
            roleCtrl,
            deleted: false,
            loadingCount: 0,
          };
          return adminRow;
        });
      });
  }

  delete(adminRow: AdminRow) {
    adminRow.loadingCount += 1;
    this.adminUsersService
      .deleteUser(adminRow.admin.id)
      .pipe(finalize(() => (adminRow.loadingCount -= 1)))
      .subscribe({
        next: () => {
          adminRow.deleted = true;
          setTimeout(() => {
            this.reloadAdmins();
          }, 500);
        },
        error: (e) => this.showErrorService.showError(Errors.USER_DELETE_ERROR),
      });
  }

  changeRole(adminRow: AdminRow, newRoleId: number) {
    adminRow.loadingCount += 1;
    this.adminUsersService
      .changeRole(adminRow.admin, newRoleId)
      .pipe(finalize(() => (adminRow.loadingCount -= 1)))
      .subscribe(
        (a: Administrator) => {
          adminRow.admin = a;
        },
        () => {
          this.showErrorService.showError(Errors.ROLE_CHANGE_ERROR);
        }
      );
  }

  addAdministrator() {
    const opts: ConfirmOpts = {
      title: 'Create Administrator',
      okButton: 'Create Administrator',
      cancelButton: 'Cancel',
      value: {},
      allowSaveAnyway: (keys: any) => false,
      okMethod: (v) => {
        return this.adminUsersService.createUser(v);
      },
      rows: [
        createCtrlArgs('role_id', 'select', 'role', {
          opts: [
            ['admin', 1],
            ['manager', 2],
            ['viewer', 3],
            ['developer', 4],
          ],
          required: true,
        }),
        createCtrlArgs('email', 'text', 'email', { required: true }),
      ],
    };
    this.dialogService
      .openConfirm(opts)
      .afterClosed()
      .subscribe((v) => {
        if (v) {
          this.reloadAdmins();
        }
      });
  }
}
