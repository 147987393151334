import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Platform } from '../audio-formats.service';
import { StatusCodes } from '../constants';
import { ImageFile, MediaFile } from '../track/track.model';
import { isTrimmed } from '../utils';

export interface BackgroundTrackTranslation {
  id: number;
  backgroundTrackId: number;
  title: string;
  languageId: number;
}

export interface BackgroundTrack {
  id: number;
  imageFileId: number;
  mediaFileId: number;
  isMusic: boolean;
  releasedAt: string;
  unpublishedAt: string;
  statusCode: number;
  backgroundTrackTranslation: BackgroundTrackTranslation;
  imageFile: ImageFile;
  mediaFile: MediaFile;
}

export interface BackgroundTrackInfo {
  backgroundTrack: BackgroundTrack;
  platforms: Platform[];
  mixCount: number;
}

@Injectable({
  providedIn: 'root',
})
export class BackgroundTracksService {
  backgroundTracks: BackgroundTrackInfo[];
  constructor(private http: HttpClient) {}

  getBackgroundTracks(): Observable<BackgroundTrackInfo[]> {
    return this.http.get<BackgroundTrackInfo[]>('/api/background-tracks');
  }

  getBackgroundTrack(id: any) {
    return this.http.get<BackgroundTrackInfo>(`/api/background-tracks/${id}`);
  }

  makeCreateOrUpdateBackgroundTrackPayload(backgroundTrackFormGroupValue) {
    const backgroundPayload = {
      ...backgroundTrackFormGroupValue,
    };
    if (backgroundPayload.backgroundTrack.imageFileId === 0) {
      delete backgroundPayload.backgroundTrack.imageFileId;
    }
    if (backgroundPayload.backgroundTrack.mediaFileId === 0) {
      delete backgroundPayload.backgroundTrack.mediaFileId;
    }
    return backgroundPayload;
  }

  updateBackgroundTrack<T>(backgroundTrackPayload: T) {
    return this.http.put<T>(`/api/background-tracks`, backgroundTrackPayload);
  }

  createBackgroundTrack<T>(backgroundTrackPayload: T) {
    return this.http.post<T>(`/api/background-tracks`, backgroundTrackPayload);
  }

  cleanForm(backgroundTrackPayload: any): [string[], boolean] {
    const warnings: string[] = [];
    if (isTrimmed(backgroundTrackPayload.backgroundTrack.backgroundTrackTranslation.title) === false) {
      backgroundTrackPayload.backgroundTrack.backgroundTrackTranslation.title =
        backgroundTrackPayload.backgroundTrack.backgroundTrackTranslation.title.trim();
      if (!backgroundTrackPayload.backgroundTrack.backgroundTrackTranslation.title) {
        return [warnings, false];
      }
      warnings.push('Title has been trimmed automatically');
    }
    return [warnings, true];
  }

  checkFormError(formGroup: FormGroup, backgroundTrackId: number) {
    const backgroundTrackPayload = this.makeCreateOrUpdateBackgroundTrackPayload(formGroup.getRawValue());
    const [, isClean] = this.cleanForm(backgroundTrackPayload);
    if (isClean === false) return { cleanFormRequired: true };

    const backgroundTrack = formGroup.get('backgroundTrack');
    const statusCode = backgroundTrack.get('statusCode').value;
    if (statusCode === StatusCodes.SCHEDULE || statusCode === StatusCodes.PUBLISHED) {
      if (!backgroundTrack.get('imageFileId').value) {
        return { imageFileRequired: true };
      }
      if (!backgroundTrack.get('mediaFileId').value) {
        return { mediaFileRequired: true };
      }
    } else if (statusCode === StatusCodes.PROCESSING_AUDIO_ERROR_CODE) {
      return { audioProcessingRequired: true };
    }

    if (this.backgroundTracks) {
      for (const backgroundTrack of this.backgroundTracks) {
        if (backgroundTrack.backgroundTrack.id === backgroundTrackId) continue;
        if (
          formGroup.get('backgroundTrack.backgroundTrackTranslation.title').value.toLowerCase() ===
          backgroundTrack.backgroundTrack.backgroundTrackTranslation.title.toLowerCase()
        ) {
          return { backgroundTrackTitleExists: true };
        }
      }
    }

    return {};
  }
}
