import { Pipe, PipeTransform } from '@angular/core';
import { GetStageFromHost, Stages } from '../constants';

@Pipe({
  name: 'slumberEnv',
})
export class SlumberEnvPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): Stages.Prod | Stages.Staging | Stages.Dev | Stages.Unknown {
    return GetStageFromHost();
  }
}
