import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponent, ConfirmOpts } from './confirm/confirm.component';
import { ConfirmNumberComponent, ConfirmNumberOpts } from './confirm-number/confirm-number.component';
import { ConfirmBoolComponent, ConfirmBoolOpts } from './confirm-bool/confirm-bool.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openConfirm(opts: ConfirmOpts): MatDialogRef<ConfirmComponent> {
    const ref = this.dialog.open(ConfirmComponent, {
      width: '500px',
    });
    ref.componentInstance.settings = opts;
    return ref;
  }

  openConfirmNumber(opts: ConfirmNumberOpts): MatDialogRef<ConfirmNumberComponent> {
    const ref = this.dialog.open(ConfirmNumberComponent, {
      width: '500px',
      disableClose: false,
    });
    ref.componentInstance.confirmNumberOpts = opts;
    return ref;
  }

  openConfirmBool(opts: ConfirmBoolOpts, showFalse: boolean = true): MatDialogRef<ConfirmBoolComponent> {
    const ref = this.dialog.open(ConfirmBoolComponent, {
      width: '500px',
      disableClose: true,
    });
    ref.componentInstance.confirmBoolOpts = opts;
    ref.componentInstance.showFalse = showFalse;
    return ref;
  }

  confirmCanDeactivate(): Observable<boolean> {
    return this.openConfirmBool({
      title: 'All changes will be lost, Proceed?',
      trueButtonText: 'Yes, Proceed without saving',
      falseButtonText: "Oops, Don't proceed",
    }).afterClosed();
  }
}
