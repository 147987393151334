import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { makeKeyFromTitle } from 'src/app/utils/strings';
import { MediaPlatformFormatInfo } from '../../audio-formats.service';
import { CategoriesService, CategoryDTO } from '../../categories/categories.service';
import { CollectionInfoObsolete, CollectionsService } from '../../collections/collections.service';
import { CategoryTypes, ContentTypes, SlumberCategories, TenantAppRouter } from '../../constants';
import { PeopleService } from '../../person/people.service';
import { TagsService } from '../../tags.service';
import { checkSimilarity, getTenantAppRouterFromURL } from '../../utils';
import { ContentType } from '../track.model';
import { TracksService } from '../tracks.service';
import { TranscriptComponent } from '../transcript/transcript.component';

@Component({
  selector: 'app-track-form-elements',
  templateUrl: './track-form-elements.component.html',
  styleUrls: ['./track-form-elements.component.scss'],
})
export class TrackFormElementsComponent implements OnInit {
  @Input() trackFormGroup: FormGroup;
  @Input() mediaPlatformFormatsInfo: MediaPlatformFormatInfo[];
  @Input() parentActionType: 'createAction' | 'updateAction';

  categories: CategoryDTO[] = null;
  collections: CollectionInfoObsolete[] = null;
  contentTypes: ContentType[] = null;
  allTags: any = [];
  releasedAtControl: FormControl;
  unpublishedAtControl: FormControl;
  statusCodeControl: FormControl;
  selectedMixIdFormControl: FormControl;

  isDefaultSlumber = true;

  similarTrackWarning: string | null;

  constructor(
    private peopleService: PeopleService,
    private categoriesService: CategoriesService,
    private collectionsService: CollectionsService,
    private tagsService: TagsService,
    private tracksService: TracksService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
    if (defaultAppFromURL === TenantAppRouter.DSS) {
      this.isDefaultSlumber = false;
    }

    this.selectedMixIdFormControl = this.trackFormGroup.get('mixId') as FormControl;
    const t = this.trackFormGroup.get('track');
    this.releasedAtControl = t.get('releasedAt') as FormControl;
    this.unpublishedAtControl = t.get('unpublishedAt') as FormControl;
    this.statusCodeControl = t.get('statusCode') as FormControl;
    this.peopleService.reload(); // select author or narrator
    this.categoriesService
      .getCategoriesForType(CategoryTypes.Server)
      .pipe(
        tap((cats: CategoryDTO[]) => {
          for (const cat of cats) {
            if (cat.category.id === SlumberCategories.Music) {
              if (cat.category.categoryTranslation.title !== 'Music') {
                console.log('Expect music category at 13:', cat);
              }
            }
          }
        }),
      )
      .subscribe((cats: CategoryDTO[]) => (this.categories = cats));

    this.collectionsService.getCollections().subscribe((cols: CollectionInfoObsolete[]) => (this.collections = cols));

    this.tracksService.getContentTypes().subscribe((contentTypes: ContentType[]) => (this.contentTypes = contentTypes));

    this.tagsService.getTags().subscribe((tags) => (this.allTags = tags.map((tag) => [tag.title, tag.id])));

    const isUpdate = !!this.trackFormGroup.get('track.id');
    if (!isUpdate) {
      this.trackFormGroup.get('track.isPremium').valueChanges.subscribe((checked) => {
        const categoryIds = this.trackFormGroup.get('categories').value;

        if (this.isDefaultSlumber) {
          if (!checked && !categoryIds.includes(SlumberCategories.Free)) {
            this.trackFormGroup.get('categories').setValue([...categoryIds, SlumberCategories.Free]);
          } else if (checked && categoryIds.includes(SlumberCategories.Free)) {
            this.trackFormGroup
              .get('categories')
              .setValue([...categoryIds].filter((id) => id !== SlumberCategories.Free));
          }
        }
      });

      this.trackFormGroup.get('track.trackTranslation.title').valueChanges.subscribe((value) => {
        this.trackFormGroup.patchValue({
          track: { key: makeKeyFromTitle(value) },
        });
      });
    }
  }

  onCategorySelectionChange(event) {
    if (!event.isUserInput) {
      return;
    }
    const categoryId = parseInt(event.source.value, 10);

    if (this.isDefaultSlumber) {
      if (categoryId === SlumberCategories.Free) {
        if (this.trackFormGroup.get('track.isPremium').value === true && event.source.selected) {
          this.snackBar.open('You are adding a Premium track to the Free category', 'Close', { duration: 5000 });
        }
      } else if (categoryId === SlumberCategories.Music) {
        // The value in a formgroup represents the state before the selection change happened
        if (!this.trackFormGroup.get('categories').value.includes(categoryId)) {
          this.trackFormGroup.get('contentTypes').setValue([ContentTypes.Music]);
        }
      } else if (categoryId === SlumberCategories.Soundscapes) {
        if (!this.trackFormGroup.get('categories').value.includes(categoryId)) {
          this.trackFormGroup.get('contentTypes').setValue([ContentTypes.Soundscape]);
        }
      }
    }
  }

  onFocusOutEvent(event: any) {
    let trackId = 0;
    this.similarTrackWarning = null;
    if (this.trackFormGroup.get('track.id')) trackId = parseInt(this.trackFormGroup.get('track.id').value);
    if (!this.tracksService.trackList.tracks) return;

    const trackTitles = this.tracksService.trackList.tracks
      .filter((track) => track.id != trackId)
      .map((track) => track.title);
    const similarTitles = checkSimilarity(trackTitles, event.target.value);
    if (similarTitles.length > 0) {
      this.similarTrackWarning = `Similar track titles: ${similarTitles.join(', ')}`;
    }
  }

  editTranscript() {
    this.matDialog
      .open(TranscriptComponent, {
        width: '1200px',
        height: '802px',
        disableClose: true,
        data: { text: this.trackFormGroup.get('track.trackTranslation.transcript').value },
      })
      .afterClosed()
      .subscribe((text) => {
        if (text !== null) {
          this.trackFormGroup.get('track.trackTranslation.transcript').setValue(text);
        }
      });
  }
}
