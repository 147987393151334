<div
  class="example-list"
  [ngClass]="{ dragDisabled: !enableCheckbox }"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
  [cdkDropListDisabled]="!enableCheckbox"
>
  @for (item of items; track item) {
    <div class="example-box" cdkDrag>
      <div>
        @switch (item.kind) {
          @case (Kind.Track) {}
          @case (Kind.Collection) {
            📁
          }
          @case (Kind.Person) {
            👨‍🎤
          }
          @default {}
        }
      </div>
      <div>[{{ item.kindId }}]</div>
      <div>
        <span>{{ item.isFree ? '(F) ' : '' }}</span>
        {{ item.name }}
      </div>
      <div>({{ item.order }})</div>
      @if (enableCheckbox) {
        <div>
          <mat-checkbox
            matTooltip="Pinned"
            [labelPosition]="'after'"
            [checked]="item.isPinned"
            (change)="item.isPinned = !item.isPinned; this.change.emit()"
          >
          </mat-checkbox>
        </div>
      }
    </div>
  }
</div>
