@if (fileType == 'media') {
  <div (click)="$event.preventDefault()" class="platform-container">
    @for (platform of platforms.value; track platform) {
      <section class="platform-select-container">
        {{ platform.title }} -
        <mat-form-field
          appearance="fill"
          matTooltipPosition="above"
          [matTooltip]="
            parentActionType == 'createAction'
              ? 'Uploaded .wav files are converted to .mp3 by default. ' +
                'In order to change the audio format you must wait for the .wav file to upload completely, then press ' +
                'the Save button, then edit this track.'
              : 'When you change the audio format and press save it will ' +
                'take several minutes for the new audio format to take effect. For reference, a 60 minute .wav file ' +
                'will take 5 - 10 minutes to convert into a new format. The old audio format will still be used until ' +
                'processing of the new audio format is complete.'
          "
        >
          <mat-label>Audio Formats</mat-label>
          <mat-select
            [value]="platform.defaultAudioFormatId"
            (selectionChange)="onAudioFormatChanged($event, platform)"
            [disabled]="parentActionType == 'createAction'"
          >
            @for (audioFormat of audioFormats; track audioFormat) {
              <mat-option [value]="audioFormat.id">
                {{ audioFormat.title }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </section>
    }
  </div>
}

<div
  class="file-preview"
  [ngClass]="fileType === 'image' ? 'vertical': ''"
>
  @if (fileType === 'media') {
    <span style="width: 200px; margin-right: 10px">Uncompressed - </span>
  }
  <app-file-preview (click)="$event.preventDefault()" [fileType]="fileType" [fileId]="fileIdCtrl.value">
  </app-file-preview>
  @if (fileIdCtrl.value) {
    <button mat-button (click)="removeFilePreview($event)">
      <mat-icon alt="file">delete</mat-icon>
      @if (fileType === 'image') {
        <span>Delete</span>
      }
    </button>
  }
</div>

<section>
  @for (mediaPlatformFormatInfo of mediaPlatformFormatsInfo; track mediaPlatformFormatInfo) {
    <div
      class="file-preview"
      style="max-width: 476px"
    >
      <span style="width: 200px; margin-right: 10px">
        {{ mediaPlatformFormatInfo.platform.title }} |
        {{ mediaPlatformFormatInfo.mediaFileProcessed.audioFormat.title }} -
      </span>
      <app-file-preview
        (click)="$event.preventDefault()"
        [fileType]="fileType"
        [mediaPlatformFormatInfo]="mediaPlatformFormatInfo"
      >
      </app-file-preview>
    </div>
  }
</section>

<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    [accept]="fileType == 'image' ? 'image/*' : 'audio/*'"
    (change)="fileBrowseHandler($event)"
  />
  <mat-icon>cloud_upload</mat-icon>
  <h3>{{ fileType == 'image' ? 'Drag and drop file here' : 'Drag and drop .wav file here' }}</h3>
  <h3 style="margin-bottom: 0.75em">or</h3>
  <label for="fileDropRef">Browse for file</label>
  <p style="margin-top: 25px">{{ fileType == 'image' ? 'Minimum: 2048x2048. Recommended: 3840x3840.' : 'An .mp3 file is supported if the file size is over 1GB' }}</p>
</div>
<div class="files-list">
  @if (file) {
    <div class="single-file">
      @if (fileType == 'image') {
        <mat-icon>portrait</mat-icon>
      }
      @if (fileType == 'media') {
        <mat-icon>audiotrack</mat-icon>
      }
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        @if (file?.progress !== 100) {
          <mat-progress-spinner [diameter]="20" mode="determinate" [value]="file?.progress"> </mat-progress-spinner>
        }
        @if (finalisingChunksLoading) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      </div>
    </div>
  }
</div>

@if (existingTrackImages) {
  <app-upload-queue-item-autocomplete-picker
    [fileIdCtrl]="fileIdCtrl"
    [files]="existingTrackImages"
    [fileType]="'image'"
    (click)="$event.preventDefault()"
  >
  </app-upload-queue-item-autocomplete-picker>
}

@if (existingTrackMedia && existingTrackMedia.length > 0) {
  <app-upload-queue-item-autocomplete-picker
    [fileIdCtrl]="fileIdCtrl"
    [files]="existingTrackMedia"
    [fileType]="'media'"
    (click)="$event.preventDefault()"
  >
  </app-upload-queue-item-autocomplete-picker>
}
