<mat-nav-list>
  <a [routerLinkActive]="'active'" mat-list-item routerLink="login"> Account </a>
  @if ([Roles.Director, Roles.Manager, Roles.Viewer, Roles.Dev].indexOf(roleId) !== -1) {
    <!--    M A N A G E R   S E C T I O N -->
    @if (isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/people'"> People </a>
    }
    <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/tracks'"> Tracks </a>
    @if (isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/background-tracks'">
        Background Tracks
      </a>
    }
    @if (isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/mixes'"> Mixes </a>
    }
    <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/categories'"> Categories </a>
    @if (isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/collections'"> Collections </a>
    }
    @if (isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/home'"> Home </a>
    }
    @if (isDefaultSlumber) {
      <span style="width: 100%; display: flex; justify-content: center; padding: 0.5em">
        <button (click)="toggleUploadQueue()" mat-raised-button>
          <mat-icon style="margin-right: 0.5em"> cloud_upload </mat-icon>
          Uploads
        </button>
      </span>
    }
  }
  <mat-divider></mat-divider>
  <div>
    <!--    A D M I N   S E C T I O N -->
    @if (roleId == Roles.Director && isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/administer-users'"> Admin Users </a>
    }
    @if (roleId == 1 || (!(('' | slumberEnv) === 'prod') && roleId == Roles.Dev)) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/run-jobs'"> Run Jobs Manually </a>
    }
    @if ([Roles.Director, Roles.Manager, Roles.Viewer, Roles.Dev].indexOf(roleId) !== -1 && isDefaultSlumber) {
      <a [routerLinkActive]="'active'" mat-list-item [routerLink]="appName + '/explorer'"> Api Explorer </a>
    }
  </div>
</mat-nav-list>
