import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MixRoutingModule } from './mix-routing.module';
import { MixesComponent } from './mixes/mixes.component';
import { EditMixComponent } from './edit-mix/edit-mix.component';
import { CreateMixComponent } from './create-mix/create-mix.component';
import { SharedModule } from '../shared/shared.module';
import { MixFormElementsComponent } from './mix-form-elements/mix-form-elements.component';
import { BackgroundTrackPickerComponent } from './background-track-picker/background-track-picker.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    MixesComponent,
    EditMixComponent,
    CreateMixComponent,
    MixFormElementsComponent,
    BackgroundTrackPickerComponent,
  ],
  imports: [CommonModule, MixRoutingModule, SharedModule, MatTableModule, MatSortModule],
})
export class MixModule {}
