<ng-container [formGroup]="collectionFormGroup">
  @if (collectionFormGroup.get('collection.id')) {
    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="text" [formControl]="collectionFormGroup.get('collection.id') | formControl" />
    </mat-form-field>
  }
  <ng-container formGroupName="collection">
    <ng-container formGroupName="collectionTranslation">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input
          required
          matInput
          type="text"
          formControlName="title"
          (focusout)="onFocusOutEvent($event)"
          (input)="similarCollectionWarning = null"
        />
        @if (similarCollectionWarning) {
          <mat-hint>{{ similarCollectionWarning }}</mat-hint>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Detail</mat-label>
        <textarea required matInput type="text" formControlName="detail" rows="4"> </textarea>
      </mat-form-field>
    </ng-container>

    <app-publish-status
      [statusCodeControl]="statusCodeControl"
      [unpublishedAtControl]="unpublishedAtControl"
      [releasedAtControl]="releasedAtControl"
    >
    </app-publish-status>

    <label class="label">
      Image
      <app-media-upload
        style="margin-top: 0.5em; display: block"
        subfolder="collection"
        fileType="image"
        [fileIdCtrl]="collectionFormGroup.get('collection.imageFileId') | formControl"
      >
      </app-media-upload>
    </label>

    <mat-slide-toggle [labelPosition]="'after'" formControlName="isSeries">
      <span class="label" style="display: grid; align-items: center; grid-gap: 1em; grid-template-columns: 100px 1fr">
        Is Series
        <mat-icon
          matTooltip="If true, new tracks will be added to the end of the list. Also, client apps will provide better user recommendations by recommending each track in order"
        >
          info
        </mat-icon>
      </span>
    </mat-slide-toggle>
  </ng-container>

  @if (allItems == null) {
    <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
  }
  @if (allItems) {
    <app-chip-list2
      [allItems]="allItems"
      (toggle)="toggleItem($event)"
      [selectedItems]="selectedItems"
      [kind]="Kind.Track"
    >
    </app-chip-list2>
    <app-reorder-items2 (change)="updateFormGroupItems()" [items]="selectedItems"> </app-reorder-items2>
  }

  <div class="slumber-color-red">
    @if (collectionFormGroup.hasError('imageFileRequired')) {
      <div>Image File required for publishing</div>
    }
    @if (collectionFormGroup.hasError('cleanFormRequired')) {
      <div>Please provide a value for all required fields</div>
    }
    @if (showIsSeriesWarning()) {
      <div>This collection appears to be a series, if so, select the toggle "Is Series"</div>
    }
    @if (showCompilationTrackOrderWarning()) {
      <div>
        Compilation tracks in a series must be placed at the end of the list for recommendation algorithms to work
      </div>
    }
  </div>
</ng-container>
