import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PeopleList, PeopleService } from '../people.service';
import { CreatePersonComponent } from '../create-person/create-person.component';

type Opt = [string, any];

@Component({
  selector: 'app-person-picker',
  templateUrl: './person-picker.component.html',
  styleUrls: ['./person-picker.component.scss'],
})
export class PersonPickerComponent implements OnInit {
  @Input() personIdsControl: AbstractControl;
  @Input() label = '';
  @Input() loadPeople = false;
  @Input() showCreatePersonButton = true;

  selectedOptsFormControl = new FormControl([]);
  public peopleList: PeopleList;
  public peopleOpts: Opt[] = [];

  constructor(private peopleService: PeopleService, private matDialog: MatDialog) {}

  ngOnInit(): void {
    if (this.loadPeople) {
      this.peopleService.reload();
    }
    this.peopleList = this.peopleService.peopleList;
    this.peopleService.peopleList$.subscribe(() => this.setOpts());
    this.selectedOptsFormControl.valueChanges.subscribe((opts) => {
      const peopleIds = [];
      for (const [_, personId] of opts) {
        if (personId === null) {
          console.log('error null person id');
        }
        peopleIds.push(personId);
      }
      this.personIdsControl.setValue(peopleIds);
    });
  }

  setOpts() {
    const optFromPersonId = {};
    this.peopleOpts.length = 0;
    for (const personInfo of this.peopleService.peopleList.people) {
      const names = [];
      names.push(personInfo.person.id.toString());
      if (personInfo.person.personTranslation.firstName?.trim().length) {
        names.push(personInfo.person.personTranslation.firstName);
      }
      if (personInfo.person.personTranslation.lastName?.trim().length) {
        names.push(personInfo.person.personTranslation.lastName);
      }
      if (personInfo.person.personTranslation.displayName?.trim().length) {
        names.push(`(${personInfo.person.personTranslation.displayName})`);
      }
      const name = names.join(' ');
      const newOpt: Opt = [name, personInfo.person.id];
      this.peopleOpts.push(newOpt);
      optFromPersonId[personInfo.person.id] = newOpt;
    }
    const selectedOpts = [];
    for (const personId of this.personIdsControl.value) {
      selectedOpts.push(optFromPersonId[personId]);
    }
    this.selectedOptsFormControl.setValue(selectedOpts, { emitEvent: false });
  }

  createNewPerson() {
    this.matDialog
      .open(CreatePersonComponent, { width: '560px', height: '800px' })
      .afterClosed()
      .subscribe((v) => {
        if (v !== null) {
          this.peopleService.reload();
        }
      });
  }
}
