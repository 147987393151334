<mat-toolbar color="primary" class="toolbar">
  <button mat-icon-button (click)="toggle.emit()">
    <mat-icon>menu</mat-icon>
  </button>
  @if (appToggleVisible) {
    <mat-button-toggle-group
      hideSingleSelectionIndicator="true"
      name="default-app"
      [value]="selectedDefaultApp"
      (change)="onDefaultAppChange($event)"
    >
      @for (app of apps; track app) {
        <mat-button-toggle [value]="app">
          {{ app }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
  }
  <h1 class="app-name" [ngClass]="{ 'app-name-prod': server === 'Production' }">{{ server }} - {{ appVersion }}</h1>
</mat-toolbar>
