import { NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';

// Initialize Firebase
const app = provideFirebaseApp(() => initializeApp(environment.firebase));
const auth = provideAuth(() => getAuth());

@NgModule({
  declarations: [],
  imports: [],
  providers: [app, auth],
})
export class FirebaseStuffModule {}
