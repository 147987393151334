<div>
  @for (error of errors; track error) {
    <div class="error-row">
      <strong
        (click)="error.expanded = !error.expanded"
        [matBadge]="error.count.toString()"
        [matBadgePosition]="'above before'"
        [matBadgeOverlap]="true"
        [matBadgeSize]="'small'"
        matBadgeColor="accent"
        [matBadgeHidden]="error.count === 1"
      >
        <mat-icon>info</mat-icon>
        {{ error.title }}
      </strong>
      @if (error.message) {
        <div>
          {{ error.message }}
        </div>
      }
    </div>
  }
</div>
<div>
  <button (click)="close()" mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
</div>
