<h1>Create Mix</h1>
@if (!mixFormGroup) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (mixFormGroup) {
  <form (ngSubmit)="save()">
    <app-mix-form-elements [mixFormGroup]="mixFormGroup"> </app-mix-form-elements>
    <div>
      <a routerLink=".." mat-button> Cancel </a>
      <button [disabled]="mixFormGroup.invalid" type="submit" mat-button>Create Mix</button>
      @if (loadingCount) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </form>
}
