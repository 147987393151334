import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RunJobsRoutingModule } from './run-jobs-routing.module';
import { RunJobsComponent } from './run-jobs/run-jobs.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [RunJobsComponent],
  imports: [CommonModule, RunJobsRoutingModule, SharedModule],
})
export class RunJobsModule {}
