import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { TestUploadComponent } from './test-upload/test-upload.component';

const routes: Routes = [
  {
    path: 'image',
    component: MediaUploadComponent,
  },
  {
    path: 'test-upload', // <--- for testing
    component: TestUploadComponent,
  },
  { path: '**', redirectTo: 'test-upload' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UploadRoutingModule {}
