import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BackgroundTracksComponent } from './background-tracks/background-tracks.component';
import { EditBackgroundTrackComponent } from './edit-background-track/edit-background-track.component';
import { CreateBackgroundTrackComponent } from './create-background-track/create-background-track.component';
import { CanDeactivateGuard } from '../dialog/can-deactivate-guard.service';

const routes: Routes = [
  {
    path: '',
    component: BackgroundTracksComponent,
  },
  {
    path: 'edit/:id',
    component: EditBackgroundTrackComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'create',
    component: CreateBackgroundTrackComponent,
    canDeactivate: [CanDeactivateGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BackgroundTracksRoutingModule {}
