import { Component, HostListener, OnInit, Optional, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { LANGUAGE_ID } from '../../constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { PersonInfo } from '../people.model';
import { PeopleService } from '../people.service';
import { PersonFormElementsComponent } from '../person-form-elements/person-form-elements.component';

@Component({
  selector: 'app-create-person',
  templateUrl: './create-person.component.html',
  styleUrls: ['./create-person.component.scss'],
})
export class CreatePersonComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  personFormGroup = this.fb.group(
    {
      person: this.fb.group({
        gender: null,
        imageFileId: [null, Validators.required],
        personTranslation: this.fb.group({
          firstName: ['', Validators.required],
          lastName: ['', Validators.required],
          displayName: ['', Validators.required],
          detail: ['', Validators.required],
          languageId: LANGUAGE_ID,
        }),
      }),
    },
    { validators: this.publishingRequirementsValidator.bind(this) },
  );

  initialForm: any;

  @ViewChild(PersonFormElementsComponent)
  private personFormElementsComponent!: PersonFormElementsComponent;

  constructor(
    private fb: NonNullableFormBuilder,
    private peopleService: PeopleService,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
    @Optional() private ref: MatDialogRef<CreatePersonComponent>,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.personFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.personFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.initialForm = this.personFormGroup.value;
  }

  cancel() {
    if (this.ref === null) {
      let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
      this.router.navigateByUrl(`${defaultAppFromURL}/people`);
    } else {
      this.ref.close(null);
    }
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (this.initialForm) {
      const personPayload = this.peopleService.makeCreateOrUpdatePersonPayload(formGroup.getRawValue());
      const [, isClean] = this.peopleService.cleanForm(personPayload);
      if (isClean === false) {
        return { cleanFormRequired: true };
      }
    }
    return {};
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const personPayload = this.peopleService.makeCreateOrUpdatePersonPayload(this.personFormGroup.getRawValue());
      const [warnings] = this.peopleService.cleanForm(personPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });

      // Sending 0 as id as no id exists yet
      const duplicateDialog = this.personFormElementsComponent.displayNameComponent.checkForDuplicate(
        0,
        personPayload.person.personTranslation.firstName,
        personPayload.person.personTranslation.lastName,
        personPayload.person.personTranslation.displayName,
      );
      if (duplicateDialog !== null) {
        duplicateDialog.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.create(personPayload);
          }
        });
      } else {
        this.create(personPayload);
      }
    });
  }

  create(personPayload) {
    this.loadingCount += 1;
    this.peopleService
      .createPerson(personPayload)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe({
        next: (person: PersonInfo) => {
          if (this.ref === null) {
            // in standalone page, go back to people list
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/people`);
          } else {
            // creating person inside modal, close modal
            this.ref.close(person);
          }
        },
        error: (e) => {
          this.showErrorService.showError(Errors.PERSON_CREATE_ERROR);
        },
      });
  }
}
