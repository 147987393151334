import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Hosts } from './constants';
import startBugSnag from './bugsnag';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  mobileQuery: MediaQueryList;

  constructor(
    media: MediaMatcher,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private titleService: Title
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
  }

  ngOnInit(): void {
    // window.location.host
    // "admin.slumber.fm"
    let slumberEnv = 'slumber-env-dev';
    let title = 'Slumber Admin Console (Development)';
    let bugsnagStage = 'development-angular';
    if (window.location.host === Hosts.Prod) {
      slumberEnv = 'slumber-env-prod';
      title = 'Slumber Admin Console (Production)';
      bugsnagStage = 'production-angular';
    } else if (window.location.host === Hosts.Staging || window.location.host === Hosts.DevStaging) {
      slumberEnv = 'slumber-env-staging';
      title = 'Slumber Admin Console (Staging)';
      bugsnagStage = 'staging-angular';
    }
    this.renderer.addClass(document.body, slumberEnv);

    this.setTitle(title);
    startBugSnag(bugsnagStage);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
