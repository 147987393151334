import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { FileTypes } from '../../constants';
import { UploadQueueService } from '../upload-queue.service';

@Component({
  selector: 'app-upload-queue',
  templateUrl: './upload-queue.component.html',
  styleUrls: ['./upload-queue.component.scss'],
})
export class UploadQueueComponent implements OnInit {
  imageUploader: FileUploader = null;
  mediaUploader: FileUploader = null;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<UploadQueueComponent>,
    private uploadQueueService: UploadQueueService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.imageUploader = this.uploadQueueService.imageUploader;
    this.mediaUploader = this.uploadQueueService.mediaUploader;

    this.mediaUploader.onBuildItemForm = (fileItem: FileItem, form: FormData) => {
      form.append('subfolder', 'track');
      form.append('fileType', FileTypes.Media);
      form.append('filename', fileItem._file.name);
      form.append('size', fileItem._file.size.toString());
      form.append('durationSeconds', '0');
      form.append('isBulkUploader', `true`);
      form.append('contentType', fileItem._file.type);
      return { fileItem, form };
    };

    this.imageUploader.onBuildItemForm = (fileItem: FileItem, form: FormData) => {
      form.append('subfolder', 'track');
      form.append('fileType', FileTypes.Image);
      form.append('filename', fileItem._file.name);
      form.append('size', fileItem._file.size.toString());
      form.append('durationSeconds', '0');
      form.append('isBulkUploader', `true`);
      form.append('contentType', fileItem._file.type);
      return { fileItem, form };
    };

    this.imageUploader.onProgressItem = () => {
      setTimeout(() => {
        this.cd.detectChanges();
      }, 0);
    };

    this.mediaUploader.onProgressItem = () => {
      setTimeout(() => {
        this.cd.detectChanges();
      }, 0);
    };

    this.imageUploader.response.subscribe(
      () => {
        setTimeout(() => {
          this.cd.detectChanges();
        }, 0);
      },
      () => {
        setTimeout(() => {
          this.cd.detectChanges();
        }, 0);
        alert('unexpected error uploading image');
      },
    );
    this.mediaUploader.response.subscribe(
      () => {
        setTimeout(() => {
          this.cd.detectChanges();
        }, 0);
      },
      () => {
        setTimeout(() => {
          this.cd.detectChanges();
        }, 0);
        alert('unexpected error uploading media');
      },
    );
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}
