import { Injectable } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SlumberUploadTrackerService {
  // count how many uploads are happening
  public uploadCount = 0;

  constructor(private dialogService: DialogService) {}

  public confirmUploadsDone(): Observable<any> {
    if (this.uploadCount > 0) {
      return this.confirmOrphansAreOkay().pipe(filter((confirm) => confirm));
    }
    return of(true);
  }

  private confirmOrphansAreOkay() {
    return this.dialogService
      .openConfirm({
        title: 'Unfinished uploads will be lost (orphaned)',
        cancelButton: 'Wait (recommended)',
        okButton: 'Proceed Anyway',
      })
      .afterClosed();
  }
}
