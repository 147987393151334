import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Auth, idToken } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class UploadQueueService {
  imageUploader = new FileUploader({
    url: '/api/upload',
    autoUpload: true,
    removeAfterUpload: false,
  });
  mediaUploader = new FileUploader({
    url: '/api/upload',
    autoUpload: true,
    removeAfterUpload: false,
  });

  constructor(private afAuth: Auth) {
    idToken(this.afAuth).subscribe((idToken) => {
      if (idToken !== null) {
        this.imageUploader.authToken = idToken;
        this.mediaUploader.authToken = idToken;
      }
    });
  }
}
