import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getTenAMEasternNextDay } from 'src/app/utils/times';
import { StatusCodes } from '../../constants';
import { DialogService } from '../../dialog/dialog.service';

@Component({
  selector: 'app-publish-status',
  templateUrl: './publish-status.component.html',
  styleUrls: ['./publish-status.component.scss'],
})
export class PublishStatusComponent implements OnInit {
  @Input() releasedAtControl: FormControl;
  @Input() unpublishedAtControl: FormControl;
  @Input() statusCodeControl: FormControl;
  unpublishedAtInitialValue = null;
  publishedAtInitialValue = null;
  statusCodeCloneControl: FormControl;
  showDateTimePicker = false;
  publishOpts: any = [
    ['Draft', StatusCodes.DRAFT],
    ['Beta', StatusCodes.BETA],
    ['Immediately', StatusCodes.IMMEDIATE], // schedule for NOW
    ['Schedule', StatusCodes.SCHEDULE],
  ];
  alreadyPublished = false;

  constructor(private changeDetectorRef: ChangeDetectorRef, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.unpublishedAtInitialValue = this.unpublishedAtControl.value;
    this.publishedAtInitialValue = this.releasedAtControl.value;
    this.statusCodeCloneControl = new FormControl(this.statusCodeControl.value);
    if (this.statusCodeCloneControl.value === StatusCodes.PUBLISHED) {
      this.alreadyPublished = true;
    } else if (this.statusCodeCloneControl.value === StatusCodes.SCHEDULE) {
      this.showDateTimePicker = true;
    }
    this.statusCodeCloneControl.valueChanges.subscribe((v) => {
      if (v === -1) {
        // immediate
        this.statusCodeControl.setValue(StatusCodes.SCHEDULE, { emitEvent: true });
      } else {
        this.statusCodeControl.setValue(v);
      }
    });
  }

  unpublish() {
    this.dialogService
      .openConfirm({
        title: 'Confirm Unpublish',
        cancelButton: 'Cancel',
        okButton: 'Confirm',
      })
      .afterClosed()
      .subscribe((confirm) => {
        if (confirm) {
          if (this.alreadyPublished === false) {
            console.log('error, already published is false');
          }
          this.alreadyPublished = false;
          this.statusCodeCloneControl.setValue(StatusCodes.DRAFT, { emitEvent: false });
          this.statusCodeControl.setValue(StatusCodes.DRAFT, { emitEvent: false });
          this.releasedAtControl.setValue(null, { emitEvent: false });
          const now = new Date().toISOString();
          this.unpublishedAtControl.setValue(now, { emitEvent: false });
        }
      });
  }

  // For publishing immediately, we just schedule it and server takes care of the rest
  // to publish when the next cycle of job runs. We do not send any specific time for it,
  // since it gets set from server. We only send time for future scheduling.
  changeStatusCode(e) {
    const now = new Date().toISOString();
    this.showDateTimePicker = false;
    const statusCode = e.value;
    if (statusCode === StatusCodes.IMMEDIATE) {
      this.releasedAtControl.setValue(null, { emitEvent: false });
      this.unpublishedAtControl.setValue(this.unpublishedAtInitialValue, { emitEvent: false });
    } else if (statusCode === StatusCodes.SCHEDULE) {
      this.showDateTimePicker = true;
      this.releasedAtControl.setValue(getTenAMEasternNextDay(), { emitEvent: false });
      this.unpublishedAtControl.setValue(this.unpublishedAtInitialValue, { emitEvent: false });
    } else {
      this.releasedAtControl.setValue(null, { emitEvent: false });
      if (this.publishedAtInitialValue !== null) {
        this.unpublishedAtControl.setValue(now, { emitEvent: false });
      }
    }
    this.changeDetectorRef.detectChanges();
  }
}
