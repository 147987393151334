import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackgroundTracksRoutingModule } from './background-tracks-routing.module';
import { BackgroundTracksComponent } from './background-tracks/background-tracks.component';
import { SharedModule } from '../shared/shared.module';
import { EditBackgroundTrackComponent } from './edit-background-track/edit-background-track.component';
import { BackgroundTracksFormElementsComponent } from './background-tracks-form-elements/background-tracks-form-elements.component';
import { CreateBackgroundTrackComponent } from './create-background-track/create-background-track.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    BackgroundTracksComponent,
    EditBackgroundTrackComponent,
    BackgroundTracksFormElementsComponent,
    CreateBackgroundTrackComponent,
  ],
  imports: [CommonModule, BackgroundTracksRoutingModule, SharedModule, MatSortModule, MatTableModule],
})
export class BackgroundTracksModule {}
