<div class="container" [class.is-mobile]="mobileQuery.matches">
  <navigation-toolbar (toggle)="snav.toggle()"></navigation-toolbar>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav
      #snav
      [opened]="false"
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      [fixedTopGap]="56"
    >
      <navigation-sidenav></navigation-sidenav>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
