import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { PersonModule } from '../person/person.module';
import { SharedModule } from '../shared/shared.module';
import { CreateTrackComponent } from './create-track/create-track.component';
import { EditTrackComponent } from './edit-track/edit-track.component';
import { MixPickerComponent } from './mix-picker/mix-picker.component';
import { TrackFormElementsComponent } from './track-form/track-form-elements.component';
import { TrackPickerComponent } from './track-picker/track-picker.component';
import { TrackRoutingModule } from './track-routing.module';
import { TracksComponent } from './tracks/tracks.component';
import { TranscriptComponent } from './transcript/transcript.component';

@NgModule({
  declarations: [
    TracksComponent,
    CreateTrackComponent,
    EditTrackComponent,
    TrackFormElementsComponent,
    TrackPickerComponent,
    MixPickerComponent,
    TranscriptComponent,
  ],
  exports: [TrackPickerComponent],
  imports: [TrackRoutingModule, SharedModule, PersonModule, MatTableModule, MatSortModule],
})
export class TrackModule {}
