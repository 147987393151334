import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { checkSimilarity } from 'src/app/utils';
import { MixesService } from '../mixes.service';

@Component({
  selector: 'app-mix-form-elements',
  templateUrl: './mix-form-elements.component.html',
  styleUrls: ['./mix-form-elements.component.scss'],
})
export class MixFormElementsComponent implements OnInit {
  @Input() mixFormGroup: FormGroup;

  similarMixWarning: string | null;

  constructor(private mixesService: MixesService) {}

  ngOnInit(): void {}

  onFocusOutEvent(event: any) {
    let mixId = 0;
    this.similarMixWarning = null;
    if (this.mixFormGroup.get('mix.id')) mixId = parseInt(this.mixFormGroup.get('mix.id').value);
    if (!this.mixesService.mixes) return;

    const mixTitles = this.mixesService.mixes.filter((mix) => mix.mix.id != mixId).map((mix) => mix.mix.title);
    const similarTitles = checkSimilarity(mixTitles, event.target.value);
    if (similarTitles.length > 0) {
      this.similarMixWarning = `Similar mix titles: ${similarTitles.join(', ')}`;
    }
  }
}
