<div>
  {{ personInfo.person.id }}
</div>
<div class="img-container">
  @if (personInfo.person.imageFile && personInfo.person.imageFile.url) {
    <img [src]="personInfo.person.imageFile.url" />
  }
  @if (!personInfo.person.imageFile || !personInfo.person.imageFile.url) {
    <img src="/assets/person_blank.png" />
  }
</div>
<div
  [matTooltip]="
    personInfo.person.personTranslation.firstName +
    ' ' +
    personInfo.person.personTranslation.lastName +
    ' ' +
    personInfo.person.personTranslation.displayName
  "
  class="slumber-overflow-ellipsis"
>
  {{ personInfo.person.personTranslation.firstName }} {{ personInfo.person.personTranslation.lastName }}
  @if (personInfo.person.personTranslation.displayName) {
    <span> ({{ personInfo.person.personTranslation.displayName }}) </span>
  }
</div>
<div>
  @switch (personInfo.person.gender) {
    @case (1) {
      Male
    }
    @case (2) {
      Female
    }
    @case (9) {
      Other
    }
    @default {
      Unknown
    }
  }
</div>
<div [matTooltip]="personInfo.person.personTranslation.detail">
  {{ personInfo.person.personTranslation.detail }}
</div>
<div>
  @if (personInfo.isNarrator) {
    @if (personInfo.isAuthor) {
      Author, Narrator
    }
    @if (!personInfo.isAuthor) {
      Narrator
    }
  }
  @if (!personInfo.isNarrator) {
    @if (personInfo.isAuthor) {
      Author
    }
    @if (!personInfo.isAuthor) {
      -
    }
  }
</div>
