<ng-container [formGroup]="personFormGroup">
  @if (personFormGroup.get('person.id')) {
    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="text" [formControl]="personFormGroup.get('person.id') | formControl" />
    </mat-form-field>
  }

  <label class="label">
    Image
    <app-media-upload
      style="margin-top: 0.5em; display: block"
      subfolder="person"
      fileType="image"
      [fileIdCtrl]="personFormGroup.get('person').get('imageFileId') | formControl"
    >
    </app-media-upload>
  </label>

  <ng-container formGroupName="person">
    <ng-container formGroupName="personTranslation">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input required matInput type="text" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input required matInput type="text" formControlName="lastName" />
      </mat-form-field>

      @if (peopleList.loadingCount) {
        <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
      }
      @if (peopleList.people) {
        <app-display-name
          [firstNameControl]="personFormGroup.get('person').get('personTranslation.firstName') | formControl"
          [lastNameControl]="personFormGroup.get('person').get('personTranslation.lastName') | formControl"
          [displayNameControl]="personFormGroup.get('person').get('personTranslation.displayName') | formControl"
          [people]="peopleList.people"
        >
        </app-display-name>
      }
    </ng-container>

    <label class="label">
      Gender
      <mat-radio-group style="display: flex; flex-direction: column" formControlName="gender">
        @for (
          opt of [
            ['Male', 1],
            ['Female', 2]
          ];
          track opt
        ) {
          <mat-radio-button style="margin: 5px" [value]="opt[1]">
            {{ opt[0] }}
          </mat-radio-button>
        }
      </mat-radio-group>
    </label>

    <ng-container formGroupName="personTranslation">
      <mat-form-field>
        <mat-label>Detail</mat-label>
        <textarea required matInput type="text" formControlName="detail" rows="4"> </textarea>
      </mat-form-field>
    </ng-container>
  </ng-container>

  <div class="slumber-color-red">
    @if (personFormGroup.hasError('cleanFormRequired')) {
      <div>Please provide a value for all required fields</div>
    }
  </div>
</ng-container>
