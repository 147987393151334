import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonComponent } from './person/person.component';
import { PersonRoutingModule } from './person-routing.module';
import { DisplayNameComponent } from './display-name/display-name.component';
import { PersonPickerComponent } from './person-picker/person-picker.component';
import { PersonListComponent } from './person-list/person-list.component';
import { CreatePersonComponent } from './create-person/create-person.component';
import { EditPersonComponent } from './edit-person/edit-person.component';
import { PersonFormElementsComponent } from './person-form-elements/person-form-elements.component';
import { SharedModule } from '../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    PersonComponent,
    DisplayNameComponent,
    PersonPickerComponent,
    PersonListComponent,
    CreatePersonComponent,
    EditPersonComponent,
    PersonFormElementsComponent,
  ],
  exports: [PersonPickerComponent],
  imports: [CommonModule, PersonRoutingModule, SharedModule, MatTableModule, MatSortModule],
})
export class PersonModule {}
