import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { TenantAppRouter } from '../../constants';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { getTenantAppRouterFromURL } from '../../utils';
import { CategoriesService, CategoryInfoWithCount } from '../categories.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
  categories = new MatTableDataSource<CategoryInfoWithCount>();
  loadingCount = 0;
  isDefaultSlumber = true;

  displayedColumns: string[] = [
    'category.id',
    'category.categoryTranslation.title',
    'category.categoryTranslation.detail',
    'category.statusCode',
    'countTracks',
    'edit',
  ];

  constructor(
    private categoriesService: CategoriesService,
    private errorService: ShowErrorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.reloadCategories();
    let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
    if (defaultAppFromURL === TenantAppRouter.DSS) {
      this.isDefaultSlumber = false;
    }
  }

  reloadCategories() {
    this.loadingCount += 1;
    this.categoriesService
      .getCategoriesWithCounts()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((categories) => {
        this.categories.data = categories;
        this.categoriesService.categories = categories;
      });
  }

  onDrop(event: CdkDragDrop<MatTableDataSource<CategoryInfoWithCount>, any>) {
    if (this.loadingCount) {
      alert('slow down');
      return;
    }
    if (event.currentIndex !== event.previousIndex) {
      moveItemInArray(this.categories.data, event.previousIndex, event.currentIndex);
      this.categories.data = this.categories.data.slice();
      const categoryIds = this.categories.data.map((c) => c.category.id);
      this.loadingCount += 1;
      this.categoriesService
        .changeOrder(categoryIds)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe(
          () => {},
          () => {
            this.errorService.showError(Errors.RE_ORDERING_ERROR);
            moveItemInArray(this.categories.data, event.currentIndex, event.previousIndex);
          }
        );
    }
  }
}
