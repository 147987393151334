import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import Bugsnag from '@bugsnag/js';
import { MediaPlatformFormatInfo } from '../../audio-formats.service';
import { FileTypes } from '../../constants';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { ImageFile, MediaFile } from '../../track/track.model';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent implements OnInit {
  @Input() mediaPlatformFormatInfo: MediaPlatformFormatInfo;
  imageFile: ImageFile;
  mediaFile: MediaFile;
  _fileId: number;
  get fileId(): number {
    return this._fileId;
  }

  @Input('fileId')
  set fileId(id: number) {
    this.imageFile = null;
    this.mediaFile = null;
    this._fileId = id;
    if (id !== null) {
      if (this.fileType === FileTypes.Image) {
        this.http.get<ImageFile>(`/api/image-files/${id}`).subscribe((imageFile) => {
          this.imageFile = imageFile;
        });
      } else {
        this.http.get<MediaFile>(`/api/media-files/${id}`).subscribe((mediaFile) => {
          this.mediaFile = mediaFile;
        });
      }
    }
  }

  @Input() fileType: 'image' | 'media';

  constructor(
    private http: HttpClient,
    private dialogService: DialogService,
    private showErrorService: ShowErrorService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (this.mediaPlatformFormatInfo) {
      this.mediaFile = {
        id: this.mediaPlatformFormatInfo.mediaFileProcessed.id,
        url: this.mediaPlatformFormatInfo.mediaFileProcessed.url,
        size: this.mediaPlatformFormatInfo.mediaFileProcessed.size,
        path: this.mediaPlatformFormatInfo.mediaFileProcessed.path,
        languageId: this.mediaPlatformFormatInfo.mediaFileProcessed.mediaFile.languageId,
        durationMs: this.mediaPlatformFormatInfo.mediaFileProcessed.mediaFile.durationMs,
      };
    }
  }

  setDuration() {
    this.dialogService
      .openConfirmNumber({
        title: 'Enter Duration',
        label: 'minutes',
        icon: 'schedule',
        confirmButtonText: 'Save',
      })
      .afterClosed()
      .subscribe((result) => {
        if (result && result.number && this.mediaFile && this.mediaFile.id) {
          const min = result.number;
          this.mediaFile.durationMs = min * 60 * 1000;
          console.log(this.mediaFile, min);
          this.http.put('/api/media-files', this.mediaFile).subscribe(
            () => {
              this.snackBar.open('Duration updated', 'Close', { duration: 5000 });
            },
            (err) => {
              Bugsnag.notify(err);
              this.showErrorService.showError(Errors.DURATION_UPDATE_ERROR);
            }
          );
        }
      });
  }

  download(event, url) {
    event.preventDefault();

    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', url);
    link.click();
    link.remove();
  }
}
