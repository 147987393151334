<mat-form-field>
  <mat-label>{{ label + ' (' + selectedCount + ')' }}</mat-label>
  <mat-chip-grid #chipList aria-label="Tag selection">
    @for (chip of selectedOptsFormControl.value; track chip) {
      <mat-chip-row [removable]="true" (removed)="remove(chip)">
        {{ chip[0] }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    }
    <input
      [placeholder]="label"
      #chipsInput
      [formControl]="chipCurrentFC"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event); chipsInput.value = ''">
    @for (chip of chipsAllFiltered$ | async; track chip) {
      <mat-option [value]="chip[0]">
        {{ chip[0] }}
        @if (removeIfAddTwice && isSelected[chip[0]]) {
          <mat-icon>cancel</mat-icon>
        }
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
