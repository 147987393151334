import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { AudioFormatsService, MediaPlatformFormatInfo } from '../../audio-formats.service';
import { ContentTypes, LANGUAGE_ID, StatusCodes, TenantAppRouter } from '../../constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { TracksService } from '../tracks.service';

@Component({
  selector: 'app-create-track',
  templateUrl: './create-track.component.html',
  styleUrls: ['./create-track.component.scss'],
})
export class CreateTrackComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  isDefaultSlumber = true;
  trackFormGroup: FormGroup;

  initialForm: any;
  mediaPlatformFormatsInfo: MediaPlatformFormatInfo[] = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private trackService: TracksService,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
    private audioFormatsService: AudioFormatsService,
    private snackBar: MatSnackBar,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.trackFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.trackFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
    if (defaultAppFromURL === TenantAppRouter.DSS) {
      this.isDefaultSlumber = false;
    }

    let defaultCategories = [];
    if (this.isDefaultSlumber) {
      defaultCategories.push(1); // 1 = category All
    }
    this.audioFormatsService.getPlatforms().subscribe((platforms) => {
      this.trackFormGroup = this.fb.group(
        {
          track: this.fb.group({
            key: '',
            isPremium: true,
            imageFileId: null,
            mediaFileId: null,
            contentTypeId: null,
            statusCode: StatusCodes.DRAFT,
            releasedAt: null,
            unpublishedAt: null,
            translationContext: '',
            trackTranslation: this.fb.group({
              title: ['', Validators.required],
              detail: '',
              transcript: '',
              languageId: LANGUAGE_ID,
            }),
          }),
          narrators: [[]],
          authors: [[]],
          categories: [defaultCategories],
          collections: [[]],
          tags: [[]],
          mixId: null,
          platforms: [platforms],
        },
        { validators: this.publishingRequirementsValidator.bind(this) },
      );

      this.initialForm = this.trackFormGroup.value;
    });
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    return this.trackService.checkFormError(formGroup, 0, this.isDefaultSlumber);
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const trackPayload = this.trackService.makeCreateOrUpdateTrackPayload(this.trackFormGroup.getRawValue());

      const [warnings, _] = this.trackService.cleanForm(trackPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });

      if (
        trackPayload.track.contentTypeId !== ContentTypes.Music &&
        trackPayload.mixId === null &&
        this.isDefaultSlumber
      ) {
        if (trackPayload.track.statusCode === StatusCodes.DRAFT || trackPayload.track.statusCode === StatusCodes.BETA) {
          this.snackBar.open('A mix is currently missing. It will be required for publishing', 'Close', {
            duration: 5000,
          });
        } else {
          this.snackBar.open('A mix is currently missing. It is required for publishing', 'Close', { duration: 5000 });
          return;
        }
      }

      this.create(trackPayload);
    });
  }

  create(trackPayload) {
    this.loadingCount += 1;
    this.trackService
      .createTrack(trackPayload)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe({
        next: () => {
          this.saved = true;
          let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
          this.router.navigateByUrl(`${defaultAppFromURL}/tracks`);
        },
        error: (e) => {
          this.showErrorService.showError(Errors.TRACK_CREATE_ERROR);
        },
      });
  }
}
