import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GetTenantAppRouterFromTenantApp, Hosts, StorageKeys, TenantApp, TenantAppRouter } from '../../../constants';
import { getTenantAppRouterFromURL } from '../../../utils';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'navigation-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Output() toggle = new EventEmitter();
  appVersion = '';
  server = 'Dev';

  apps = [TenantApp.Slumber, TenantApp.DSS];
  selectedDefaultApp: TenantApp;
  appToggleVisible = true;

  constructor(private router: Router, private navigationService: NavigationService) {}

  isForbiddenRouteForAppToggle = (url: string): boolean => {
    return url.includes('create') || url.includes('edit') || url.includes('login');
  };

  ngOnInit() {
    if (window.location.host === Hosts.Prod) {
      this.server = 'Production';
    } else if (window.location.host === Hosts.Staging || window.location.host === Hosts.DevStaging) {
      this.server = 'Staging';
    }

    this.appVersion = environment.appVersion;

    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.appToggleVisible = !this.isForbiddenRouteForAppToggle(route.url);
        let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
        if (!defaultAppFromURL) {
          return;
        }

        this.selectedDefaultApp = defaultAppFromURL == TenantAppRouter.Slumber ? this.apps[0] : this.apps[1];

        this.navigationService.getProperties().subscribe((properties) => {
          let lastResetDBTimestampProperty = properties.find((property) => {
            return property.name === 'last_db_reset_timestamp';
          });

          if (lastResetDBTimestampProperty) {
            let lastResetDBTime = new Date(lastResetDBTimestampProperty.value * 1000).toLocaleString('en-US', {
              timeZoneName: 'short',
            });
            this.appVersion = environment.appVersion + ' - Last DB Reset [' + lastResetDBTime + ']';
          }
        });
      }
    });
  }

  onDefaultAppChange(event): void {
    this.selectedDefaultApp = this.apps.find((app) => {
      return app === event.value;
    });
    const tenantApp = GetTenantAppRouterFromTenantApp(this.selectedDefaultApp);
    if (!tenantApp) {
      alert('unknown app name');
      return;
    }
    localStorage.setItem(StorageKeys.DEFAULT_APP, tenantApp);
    window.location.replace('');
  }
}
