import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollectionsRoutingModule } from './collections-routing.module';
import { CollectionsListComponent } from './collections-list/collections-list.component';
import { SharedModule } from '../shared/shared.module';
import { EditCollectionComponent } from './edit-collection/edit-collection.component';
import { CollectionFormElementsComponent } from './collection-form-elements/collection-form-elements.component';
import { TrackModule } from '../track/track.module';
import { CollectionPickerComponent } from './collection-picker/collection-picker.component';
import { CreateCollectionComponent } from './create-collection/create-collection.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [
    CollectionsListComponent,
    EditCollectionComponent,
    CollectionFormElementsComponent,
    CollectionPickerComponent,
    CreateCollectionComponent,
  ],
  exports: [CollectionPickerComponent],
  imports: [CommonModule, CollectionsRoutingModule, SharedModule, TrackModule, MatSortModule, MatTableModule],
})
export class CollectionsModule {}
