<h1>Edit Category</h1>
@if (!categoryFormGroup) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (categoryFormGroup) {
  <form (ngSubmit)="save()">
    <app-category-form-elements [categoryFormGroup]="categoryFormGroup" [categoryDTO]="categoryDTO">
    </app-category-form-elements>
    <div style="display: flex; justify-content: space-between">
      <span>
        <a routerLink="../.." mat-button> Cancel </a>
        <button [disabled]="categoryFormGroup.invalid" type="submit" mat-button>Update Category</button>
      </span>
      <button (click)="deleteCategory()" type="button" mat-button [disabled]="!isDefaultSlumber">
        <mat-icon>delete</mat-icon>
        Delete Category
      </button>
    </div>
    @if (loadingCount) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </form>
}
