import bugsnag from '@bugsnag/js';
import { environment } from '../environments/environment';

// This service initializes Bugsnag and makes it available to the root module.

export function startBugSnag(stage: string) {
  bugsnag.start({
    // get your own api key at bugsnag.com
    apiKey: environment.bugsnagApiKey,

    // if you track deploys or use source maps, make sure to set the correct version.
    appVersion: environment.appVersion,

    // Bugsnag can track the number of “sessions” that happen in your application, and calculate a crash rate for each release.
    // autoCaptureSessions: true,

    // defines the release stage for all events that occur in this app.
    releaseStage: stage,

    //  defines which release stages bugsnag should report. e.g. ignore dev errors.
    enabledReleaseStages: ['staging-angular', 'production-angular'],
  });

  // review app.component.ts to see examples of how this client can interact with your code.

  // See our documentation for futher options and examples https://docs.bugsnag.com/
}
export default startBugSnag;
