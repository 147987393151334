import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { MaterialModule } from '../../material/material.module';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DatetimePickerComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, NgxMatTimepickerModule],
  exports: [DatetimePickerComponent],
})
export class DatetimePickerModule {}
