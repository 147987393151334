import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { PersonInfo } from '../people.model';
import { PeopleService } from '../people.service';
import { PersonFormElementsComponent } from '../person-form-elements/person-form-elements.component';

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html',
  styleUrls: ['./edit-person.component.scss'],
})
export class EditPersonComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  personFormGroup: FormGroup;

  initialForm: any;

  @ViewChild(PersonFormElementsComponent)
  private personFormElementsComponent!: PersonFormElementsComponent;

  constructor(
    private fb: NonNullableFormBuilder,
    private peopleService: PeopleService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.personFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.personFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.loadingCount += 1;
    this.peopleService
      .getPerson(this.activeRoute.snapshot.params.id)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((p: PersonInfo) => {
        this.personFormGroup = this.fb.group(
          {
            person: this.fb.group({
              id: { value: p.person.id, disabled: true },
              gender: p.person.gender,
              imageFileId: [p.person.imageFileId, Validators.required],
              personTranslation: this.fb.group({
                id: { value: p.person.personTranslation.id, disabled: true },
                firstName: [p.person.personTranslation.firstName, Validators.required],
                lastName: [p.person.personTranslation.lastName, Validators.required],
                displayName: [p.person.personTranslation.displayName, Validators.required],
                detail: [p.person.personTranslation.detail, Validators.required],
                languageId: [p.person.personTranslation.languageId],
              }),
            }),
          },
          { validators: this.publishingRequirementsValidator.bind(this) },
        );
        this.initialForm = this.personFormGroup.value;
      });
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (this.initialForm) {
      if (deepEqual(this.initialForm, formGroup.value)) {
        return { changeRequired: true };
      }
      const personPayload = this.peopleService.makeCreateOrUpdatePersonPayload(formGroup.getRawValue());
      const [_, isClean] = this.peopleService.cleanForm(personPayload);
      if (isClean === false) {
        return { cleanFormRequired: true };
      }
    }
    return {};
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const personPayload = this.peopleService.makeCreateOrUpdatePersonPayload(this.personFormGroup.getRawValue());
      const [warnings, _] = this.peopleService.cleanForm(personPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });

      const duplicateDialog = this.personFormElementsComponent.displayNameComponent.checkForDuplicate(
        personPayload.person.id,
        personPayload.person.personTranslation.firstName,
        personPayload.person.personTranslation.lastName,
        personPayload.person.personTranslation.displayName,
      );

      if (duplicateDialog !== null) {
        duplicateDialog.afterClosed().subscribe((confirm) => {
          if (confirm) {
            this.update(personPayload);
          }
        });
      } else {
        this.update(personPayload);
      }
    });
  }

  update(personPayload) {
    this.loadingCount += 1;
    this.peopleService
      .updatePerson(personPayload)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe({
        next: () => {
          this.saved = true;
          let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
          this.router.navigateByUrl(`${defaultAppFromURL}/people`);
        },
        error: (e) => {
          this.showErrorService.showError(Errors.PERSON_UPDATE_ERROR);
        },
      });
  }
}
