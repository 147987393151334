<h1 class="slumber-text-align-center">Administration Users</h1>
<div class="max-900">
  <button (click)="reloadAdmins()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>
  <button (click)="addAdministrator()" mat-button>Add New Administration User</button>
  @if (loadingCount) {
    <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
  }
  <div class="admins-grid">
    @for (adminRow of adminRows; track adminRow) {
      @if (!adminRow.deleted) {
        <div>[{{ adminRow.admin.id }}]</div>
        <div>
          {{ adminRow.admin.email }}
        </div>
        <mat-form-field>
          <mat-label>Role</mat-label>
          <mat-select [formControl]="adminRow.roleCtrl">
            <mat-option [value]="1"> Director </mat-option>
            <mat-option [value]="2"> Manager </mat-option>
            <mat-option [value]="3"> Viewer </mat-option>
            <mat-option [value]="4"> Developer </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="icon-container">
          <button (click)="delete(adminRow)" mat-icon-button aria-label="delete">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      }
      @if (adminRow.deleted || adminRow.loadingCount) {
        <div class="span-all">
          @if (adminRow.loadingCount > 0) {
            <mat-progress-bar class="span-all" mode="indeterminate"></mat-progress-bar>
          }
          @if (adminRow.deleted) {
            <div>Deleted</div>
          }
        </div>
      }
    }
  </div>
</div>
