@if (fileType == 'image' && imageFile && imageFile.url) {
  <div>
    <img [src]="imageFile.url" style="width: 100px" />
  </div>
}
@if (fileType == 'media' && mediaFile && mediaFile.url) {
  <div style="display: flex; flex-direction: row; align-items: center; padding-top: 5px; gap: 5px">
    <audio controls>
      <source [src]="mediaFile.url" />
      Your browser does not support the audio element.
    </audio>
    <mat-icon style="cursor: pointer" fontIcon="file_download" (click)="download($event, mediaFile.url)" alt="file"
      >download</mat-icon
    >
  </div>
}
