import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { AudioFormatsService, MediaPlatformFormatInfo } from '../../audio-formats.service';
import { LANGUAGE_ID, StatusCodes } from '../../constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { BackgroundTracksService } from '../background-tracks.service';

@Component({
  selector: 'app-create-background-track',
  templateUrl: './create-background-track.component.html',
  styleUrls: ['./create-background-track.component.scss'],
})
export class CreateBackgroundTrackComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  backgroundTrackFormGroup: FormGroup;

  initialForm: any;
  mediaPlatformFormatsInfo: MediaPlatformFormatInfo[] = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private backgroundTrackService: BackgroundTracksService,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
    private audioFormatsService: AudioFormatsService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.backgroundTrackFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.backgroundTrackFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.audioFormatsService.getPlatforms().subscribe((platforms) => {
      this.backgroundTrackFormGroup = this.fb.group(
        {
          backgroundTrack: this.fb.group({
            imageFileId: null,
            mediaFileId: null,
            isMusic: false,
            statusCode: [StatusCodes.DRAFT, Validators.required],
            releasedAt: null,
            unpublishedAt: null,
            backgroundTrackTranslation: this.fb.group({
              title: ['', Validators.required],
              languageId: LANGUAGE_ID,
            }),
          }),
          platforms: [platforms],
        },
        { validators: this.publishingRequirementsValidator.bind(this) },
      );

      this.initialForm = this.backgroundTrackFormGroup.value;
    });
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    return this.backgroundTrackService.checkFormError(formGroup, 0);
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const backgroundTrackPayload = this.backgroundTrackService.makeCreateOrUpdateBackgroundTrackPayload(
        this.backgroundTrackFormGroup.getRawValue(),
      );
      const [warnings, _] = this.backgroundTrackService.cleanForm(backgroundTrackPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.backgroundTrackService
        .createBackgroundTrack(backgroundTrackPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/background-tracks`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.BACKGROUND_CREATE_ERROR);
          },
        });
    });
  }
}
