import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormElementsModule } from '../form-elements/form-elements.module';
import { ReorderCategoryItemsComponent } from './reorder-category-items/reorder-category-items.component';
import { ReorderItems2Component } from './reorder-items2/reorder-items2.component';
import { SlumberEnvPipe } from './slumber-env.pipe';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { FormControlPipe } from './form-control.pipe';

@NgModule({
  declarations: [ReorderCategoryItemsComponent, ReorderItems2Component, SlumberEnvPipe, FormControlPipe],
  imports: [CommonModule, MaterialModule, RouterModule, FormElementsModule],
  exports: [
    RouterModule,
    FormElementsModule,
    ReorderCategoryItemsComponent,
    ReorderItems2Component,
    SlumberEnvPipe,
    FormControlPipe,
  ],
})
export class SharedModule {}
