import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, debounceTime, merge, of } from 'rxjs';
import { formatAMPM, makeISOString } from 'src/app/utils/times';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit {
  @Input() dateTimeFormControl: FormControl; // sets value to isoString
  @Input() label: string;
  timeCtrl = new FormControl<string | null>(null);
  dateCtrl = new FormControl<string | null>(null);
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor() {}

  ngOnInit(): void {
    merge(of(this.dateTimeFormControl.value), this.dateTimeFormControl.valueChanges)
      .pipe(debounceTime(50))
      .subscribe((dateTimeString) => {
        this.setFormControls(dateTimeString);
      });

    combineLatest([this.dateCtrl.valueChanges, this.timeCtrl.valueChanges])
      .pipe(debounceTime(50))
      .subscribe(([dateValue, timeValue]) => {
        this.updateISO(dateValue, timeValue);
      });
  }

  // set the initial value of the date and time control
  setFormControls(dateTimeString: string) {
    if (dateTimeString !== this.dateCtrl.value) {
      this.dateCtrl.setValue(dateTimeString);
    }
    const date = new Date(dateTimeString);

    const timeString = formatAMPM(date);
    if (this.timeCtrl.value !== timeString) {
      this.timeCtrl.setValue(timeString);
    }
  }

  // dateValue is in the format of '2023-07-04T18:48:04.571Z'
  // timeValue is in the format of '11:59 AM'
  updateISO(dateValue, timeValue) {
    if (dateValue === null) {
      return;
    }
    if (timeValue === null || timeValue === 'Invalid DateTime') {
      return;
    }
    const isoString = makeISOString(dateValue, timeValue);
    if (isoString !== this.dateTimeFormControl.value) {
      this.dateTimeFormControl.setValue(isoString);
    }
  }
}
