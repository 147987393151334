<label class="label">
  Audio
  <app-media-upload
    style="margin-top: 0.5em; display: block"
    subfolder="track"
    fileType="media"
    [fileIdCtrl]="testIdCtrl"
  >
  </app-media-upload>
</label>

<div>Value: {{ testIdCtrl.value }}</div>
