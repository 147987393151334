export const getTenAMEasternNextDay = () => {
  // Get the date 10 AM in the current timezone
  const tenAM = new Date();
  tenAM.setHours(10, 0, 0, 0);

  // Get the time difference between the two times
  const eastern = new Date(tenAM.toLocaleString('en-US', { timeZone: 'America/New_York' }));
  const timeDiff = eastern.getTime() - tenAM.getTime();
  const tenAMEastern = new Date(tenAM.getTime() - timeDiff);

  // Add one day to the date
  const tomorrow = new Date(tenAMEastern);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow.toISOString();
};

// dateValue is in the format of '2023-07-04T18:48:04.571Z'
// timeValue is in the format of '11:59 AM'
export const makeISOString = (dateValue: string, timeValue: string) => {
  const date = new Date(dateValue);
  const [timeOfDay, amPM] = timeValue.split(' '); // '11:59 AM'
  const [hours, minutes] = timeOfDay.split(':');
  let extra = 0;
  if (amPM === 'PM' && hours !== '12') {
    // to handle PM we add extra 12 hours
    // 12:00 PM is 00:00, 01:00 PM is 13:00, 02:00 PM is 14:00, etc.
    extra = 12;
  } else if (amPM === 'AM' && hours === '12') {
    // to handle 12AM we subtract extra 12 hours
    // 12:00 AM is 00:00, 01:00 AM is 01:00, 02:00 AM is 02:00, etc.
    extra = -12;
  }
  // sets hours and minutes
  date.setHours(parseInt(hours) + extra, parseInt(minutes), 0, 0);
  return date.toISOString().split('.')[0] + 'Z'; // removing the milliseconds "2023-07-27T05:08:50.820Z" -> "2023-07-27T05:08:50Z"
};

// date is in the format of '2023-07-04T18:48:04.571Z'
// returns time in the format of '11:59 AM'
export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let amPM = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + ' ' + amPM;
};
