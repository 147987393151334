import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { AudioFormatsService, MediaPlatformFormatInfo, Platform } from 'src/app/audio-formats.service';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { BackgroundTrackInfo, BackgroundTracksService } from '../background-tracks.service';

@Component({
  selector: 'app-edit-background-track',
  templateUrl: './edit-background-track.component.html',
  styleUrls: ['./edit-background-track.component.scss'],
})
export class EditBackgroundTrackComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  backgroundTrackFormGroup: FormGroup;

  constructor(
    private fb: NonNullableFormBuilder,
    private backgroundTrackService: BackgroundTracksService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
    private audioFormatsService: AudioFormatsService,
  ) {}

  initialForm: any;
  backgroundTrackInfo: BackgroundTrackInfo;
  mediaPlatformFormatsInfo: MediaPlatformFormatInfo[] = [];

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.backgroundTrackFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.backgroundTrackFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.loadingCount += 1;
    this.backgroundTrackService
      .getBackgroundTrack(this.activeRoute.snapshot.params.id)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((backgroundTrackInfo: BackgroundTrackInfo) => {
        this.backgroundTrackInfo = backgroundTrackInfo;

        this.audioFormatsService.getPlatforms().subscribe((platforms) => {
          if (backgroundTrackInfo.backgroundTrack.mediaFileId) {
            this.audioFormatsService
              .getMediaPlatformFormatsInfo(backgroundTrackInfo.backgroundTrack.mediaFileId)
              .subscribe((mediaPlatformFormatsInfo) => {
                this.mediaPlatformFormatsInfo = mediaPlatformFormatsInfo;

                mediaPlatformFormatsInfo.forEach((mediaPlatformFormatInfo) => {
                  platforms.forEach((platform, index) => {
                    if (platform.id === mediaPlatformFormatInfo.platform.id) {
                      platforms[index].defaultAudioFormatId = mediaPlatformFormatInfo.mediaFileProcessed.audioFormat.id;
                    }
                  });
                });

                this.initForm(backgroundTrackInfo, platforms);
              });
          } else {
            this.initForm(backgroundTrackInfo, platforms);
          }
        });
      });
  }

  initForm(backgroundTrackInfo: BackgroundTrackInfo, platforms: Platform[]): void {
    this.backgroundTrackFormGroup = this.fb.group(
      {
        backgroundTrack: this.fb.group({
          id: { value: backgroundTrackInfo.backgroundTrack.id, disabled: true },
          imageFileId: backgroundTrackInfo.backgroundTrack.imageFileId,
          mediaFileId: backgroundTrackInfo.backgroundTrack.mediaFileId,
          isMusic: backgroundTrackInfo.backgroundTrack.isMusic,
          statusCode: [backgroundTrackInfo.backgroundTrack.statusCode, Validators.required],
          releasedAt: backgroundTrackInfo.backgroundTrack.releasedAt,
          unpublishedAt: backgroundTrackInfo.backgroundTrack.unpublishedAt,
          backgroundTrackTranslation: this.fb.group({
            id: backgroundTrackInfo.backgroundTrack.backgroundTrackTranslation.id,
            backgroundTrackId: backgroundTrackInfo.backgroundTrack.backgroundTrackTranslation.backgroundTrackId,
            title: [backgroundTrackInfo.backgroundTrack.backgroundTrackTranslation.title, Validators.required],
            languageId: backgroundTrackInfo.backgroundTrack.backgroundTrackTranslation.languageId,
          }),
        }),
        platforms: [platforms],
      },
      { validators: this.publishingRequirementsValidator.bind(this) },
    );

    this.initialForm = this.backgroundTrackFormGroup.value;
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    if (deepEqual(this.initialForm, formGroup.value)) {
      return { changeRequired: true };
    }
    return this.backgroundTrackService.checkFormError(formGroup, parseInt(formGroup.get('backgroundTrack.id').value));
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const backgroundTrackPayload = this.backgroundTrackService.makeCreateOrUpdateBackgroundTrackPayload(
        this.backgroundTrackFormGroup.getRawValue(),
      );
      const [warnings] = this.backgroundTrackService.cleanForm(backgroundTrackPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.backgroundTrackService
        .updateBackgroundTrack(backgroundTrackPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/background-tracks`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.BACKGROUND_UPDATE_ERROR);
          },
        });
    });
  }
}
