import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, idToken, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { Observable, from } from 'rxjs';
import { concatMap, first, tap } from 'rxjs/operators';
import { LibraryVersion, StorageKeys } from './constants';
import { ShowErrorService } from './error-reporting/show-error.service';
import { Errors } from './errors';
import { getTenantAppRouterFromURL } from './utils';

interface ServerError {
  title: string;
  description: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    public afAuth: Auth,
    private showErrorService: ShowErrorService,
    private router: Router,
  ) {}

  public logout() {
    signOut(this.afAuth).then(
      () => {
        localStorage.removeItem(StorageKeys.VISITOR_ID);
        console.log('logout done');
        this.router.navigate(['/login']);
      },
      () => {
        alert('logout failed');
      },
    );
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      !(
        request.url.startsWith('/api/') ||
        request.url.startsWith(`/${LibraryVersion.v_3_0}/`) ||
        request.url.startsWith(`/${LibraryVersion.v_3_1}/`) ||
        request.url.startsWith(`/${LibraryVersion.v_3_2}/`)
      )
    ) {
      alert(`unknown url ${request.url}`);
      return next.handle(request);
    }

    let mainRequest = request;

    // Do not prepend the tenant app for login request
    if (request.url.startsWith('/api/') && !this.router.url.includes('login')) {
      let appName = getTenantAppRouterFromURL(this.router.url);

      if (!appName) {
        Bugsnag.notify(`unknown app name ${appName}, url ${this.router.url}`);
        alert('unknown app name');
        return next.handle(request);
      }

      let updatedRequestURL = request.url.replace('api', `${appName}/api`);
      mainRequest = request.clone({ url: updatedRequestURL });
    }

    return from(this.getVisitorId()).pipe(
      first(),
      concatMap((visitorId) => {
        return this.handle(mainRequest, next, visitorId);
      }),
    );
  }

  handle(request: HttpRequest<unknown>, next: HttpHandler, visitorId: string): Observable<HttpEvent<unknown>> {
    return idToken(this.afAuth).pipe(
      first(),
      concatMap((idToken) => {
        if (!idToken) {
          Bugsnag.notify('id token was null');
          alert('your session is stale, please login and try again');
        }
        request = request.clone({
          setHeaders: {
            Authorization: `${idToken}`,
            VisitorId: visitorId,
          },
        });
        return next.handle(request).pipe(
          tap({
            error: (err: HttpErrorResponse) => {
              if (err?.status === 404 || err?.status === 401) {
                Bugsnag.notify('an unexpected ' + err?.status + ' occurred ' + request.url + err?.error.message);
                this.showErrorService.showError(Errors.SERVER_ERROR, err?.error.message);
                if (err?.status === 401) {
                  setTimeout(() => {
                    this.logout();
                    this.showErrorService.dismiss();
                  }, 4000);
                }
              } else {
                let description;
                if (err?.error) {
                  description = err.error.description;
                }
                if (!description) {
                  // err.Error is HTMLEncoded, need to be decoded accordingly
                  // https://stackoverflow.com/a/44549390/2314765

                  let parser = new DOMParser();
                  let dom = parser.parseFromString('<!doctype html><body>' + err.error, 'text/html');
                  let decodedString = dom.body.textContent;

                  try {
                    let parsedError: ServerError = JSON.parse(decodedString);
                    description = parsedError.description;
                  } catch (e) {
                    try {
                      // remove escape character
                      let parsedError: ServerError = JSON.parse(decodedString.replaceAll('\\', ''));
                      description = parsedError.description;
                    } catch (error) {
                      Bugsnag.notify(error);
                    }
                  }
                }

                Bugsnag.notify('an error occurred ' + request.url + ' ' + err.status + ' ' + description);
                this.showErrorService.showError(Errors.SERVER_ERROR, description);
              }
            },
          }),
        );
      }),
    );
  }

  async getVisitorId(): Promise<string> {
    let visitorId = localStorage.getItem(StorageKeys.VISITOR_ID);

    if (!visitorId) {
      // We will use a random UUID as visitor ID. As long as the admin uses the same browser, we store it in localstorage.
      // When the admin logs out, the localstorage will be cleared.
      visitorId = crypto.randomUUID();
      localStorage.setItem(StorageKeys.VISITOR_ID, visitorId);
    }
    return visitorId;
  }
}
