import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export enum Kind {
  Track = 0,
  Person = 1,
  Collection = 2,
}

export interface Item {
  kindId: number;
  name: string;
  selected: boolean;
  kind: Kind;
  order: number;
  isPinned: boolean;
  isFree: boolean; // Only applicable for tracks that are free
}

@Component({
  selector: 'app-chip-list2',
  templateUrl: './chip-list2.component.html',
  styleUrls: ['./chip-list2.component.scss'],
})
export class ChipList2Component implements OnInit {
  @Input() disabled = false;
  @Input() kind: Kind = Kind.Person;
  label: string;
  selectedCount = 0;
  searchTextCtrl = new FormControl<string | null>(null);
  filteredItems: Observable<Item[]>;
  @Input() selectedItems: Item[];
  @Input() allItems: Item[];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @Output() toggle = new EventEmitter<Item>();

  constructor() {
    this.filteredItems = this.searchTextCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(50),
      map((value: string | Item) => this._filter(value))
    );
  }

  ngOnInit(): void {
    switch (+this.kind) {
      case Kind.Track:
        this.label = 'Select Tracks';
        break;
      case Kind.Collection:
        this.label = 'Select Collections';
        break;
      case Kind.Person:
        this.label = 'Select People';
        break;
      default:
        console.log('unknown collection item kind');
        break;
    }

    for (const item of this.selectedItems) {
      if (item.selected && item.kind === this.kind) {
        this.selectedCount += 1;
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const item: Item = event.option.value;
    item.selected = !item.selected;
    this.selectedCount += item.selected ? 1 : -1;
    this.toggle.emit(item); // add or remove item for selectedItems
  }

  private _filter(value: string | Item): Item[] {
    if (value instanceof Object) {
      // when selected we get the item instead of the searchText
      value = '';
    }
    const searchText = value.toLowerCase();
    return this.allItems.filter((item: Item) => {
      if (item.kind !== this.kind || !item.name) {
        return false;
      }
      if (searchText === '') {
        return true;
      }
      const words = item.name.replace('(', '').replace(')', '').toLowerCase().trim();
      for (const word of words.split(' ')) {
        if (word.indexOf(searchText) === 0) {
          return true;
        }
      }
      return searchText.length > 3 && words.includes(searchText);
    });
  }

  remove(item: Item) {
    item.selected = false;
    this.selectedCount = this.selectedCount - 1;
    this.toggle.emit(item); // add or remove item for selectedItems
  }
}
