import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { toCapitalizeFirstLetter } from 'src/app/utils/strings';
import { BarkError } from './error.model';

@Component({
  selector: 'error-reporting-errors-list-bottom-sheet',
  templateUrl: './errors-list-bottom-sheet.component.html',
  styleUrls: ['./errors-list-bottom-sheet.component.scss'],
})
export class ErrorsListBottomSheetComponent implements OnInit {
  errors: BarkError[] = [];

  constructor(private ref: MatBottomSheetRef<ErrorsListBottomSheetComponent>, private cd: ChangeDetectorRef) {}

  addError(title: string, message: string) {
    const error: BarkError = { title: toCapitalizeFirstLetter(title), message, count: 1, expanded: false };
    // called externally for simplicity
    const recentErr = this.errors.length ? this.errors[0] : null;
    if (recentErr && recentErr.title === error.title && recentErr.message === error.message) {
      this.errors = [...this.errors];
      recentErr.count += 1;
    } else {
      this.errors = [error, ...this.errors];
    }
    if (this.errors.length > 5) {
      this.errors.pop();
    }
    this.cd.detectChanges(); // since called externally
  }

  ngOnInit() {}

  close() {
    this.ref.dismiss();
  }
}
