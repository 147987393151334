<ng-container [formGroup]="backgroundTrackFormGroup">
  @if (backgroundTrackFormGroup.get('backgroundTrack.id')) {
    <mat-form-field>
      <mat-label>Id</mat-label>
      <input matInput type="text" [formControl]="backgroundTrackFormGroup.get('backgroundTrack.id') | formControl" />
    </mat-form-field>
  }

  <ng-container formGroupName="backgroundTrack">
    <ng-container formGroupName="backgroundTrackTranslation">
      <mat-form-field>
        <mat-label>Title</mat-label>
        <input
          required
          matInput
          type="text"
          formControlName="title"
          (focusout)="onFocusOutEvent($event)"
          (input)="similarBackgroundTrackWarning = null"
        />
        @if (similarBackgroundTrackWarning) {
          <mat-hint>{{ similarBackgroundTrackWarning }}</mat-hint>
        }
      </mat-form-field>
    </ng-container>

    <mat-slide-toggle [labelPosition]="'after'" formControlName="isMusic">
      <span class="label" style="display: grid; align-items: center; grid-gap: 1em; grid-template-columns: 100px 1fr">
        Is Music
        <mat-icon
          matTooltip="if true, this background track will not be available to regular tracks that are also music."
        >
          info
        </mat-icon>
      </span>
    </mat-slide-toggle>

    <app-publish-status
      [statusCodeControl]="statusCodeControl"
      [unpublishedAtControl]="unpublishedAtControl"
      [releasedAtControl]="releasedAtControl"
    >
    </app-publish-status>

    <label class="label">
      Image
      <app-media-upload
        style="margin-top: 0.5em; display: block"
        subfolder="background_track"
        fileType="image"
        [fileIdCtrl]="backgroundTrackFormGroup.get('backgroundTrack.imageFileId') | formControl"
      >
      </app-media-upload>
    </label>

    <label class="label">
      Audio
      <app-media-upload
        style="margin-top: 0.5em; display: block"
        subfolder="background_track"
        fileType="media"
        [mediaPlatformFormatsInfo]="mediaPlatformFormatsInfo"
        [platforms]="backgroundTrackFormGroup.get('platforms') | formControl"
        [parentActionType]="parentActionType"
        [fileIdCtrl]="backgroundTrackFormGroup.get('backgroundTrack.mediaFileId') | formControl"
      >
      </app-media-upload>
    </label>
  </ng-container>

  <div class="slumber-color-red">
    @if (backgroundTrackFormGroup.hasError('scheduleDate')) {
      <div>Scheduled without a publish date</div>
    }
    @if (backgroundTrackFormGroup.hasError('imageFileRequired')) {
      <div>Image file required for publishing</div>
    }
    @if (backgroundTrackFormGroup.hasError('mediaFileRequired')) {
      <div>Media file required for publishing</div>
    }
    @if (backgroundTrackFormGroup.hasError('audioProcessingRequired')) {
      <div>Media file did not process properly. Please contact the administrator</div>
    }
    @if (backgroundTrackFormGroup.hasError('cleanFormRequired')) {
      <div>Please provide a value for all required fields</div>
    }
  </div>
</ng-container>
