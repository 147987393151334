<h1 style="margin-bottom: 1em">Admin Jobs</h1>

<div class="jobs-container">
  <section>
    <div>
      <button mat-raised-button (click)="runAllScheduledJobs()">Run All Scheduled Jobs</button>
    </div>

    <div style="height: 1em">
      @if (jobs.length == 0) {
        <mat-progress-spinner [diameter]="20" mode="indeterminate"> </mat-progress-spinner>
      }
    </div>
    @for (job of jobs; track job) {
      <div>
        <button style="margin-left: 1em; margin-top: 0.25em" mat-flat-button (click)="runJob(job)">
          Run {{ job }}
        </button>
      </div>
    }
  </section>
  <section>
    <h3>These jobs need to be run individually</h3>
    <div style="height: 1em">
      @if (onDemandJobs.length == 0) {
        <mat-progress-spinner [diameter]="20" mode="indeterminate"> </mat-progress-spinner>
      }
    </div>
    @for (job of onDemandJobs; track job) {
      <div>
        <button style="margin-left: 1em; margin-top: 0.25em" mat-flat-button (click)="runJob(job)">
          Run {{ job }}
        </button>
      </div>
    }
  </section>
</div>

<div class="links-container">
  <h1>Links</h1>
  <a mat-raised-button href="https://apps.sematext.com/ui/logs" target="_blank">Sematext - Cloud Logging</a>
</div>
