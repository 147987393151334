<div class="container">
  @if (peopleList.loadingCount == 0) {
    <app-chip-list
      [disableAdd]="true"
      [label]="label"
      [optsAll]="peopleOpts"
      [selectedOptsFormControl]="selectedOptsFormControl"
    >
    </app-chip-list>
  }
  @if (peopleList.loadingCount) {
    <mat-progress-bar> </mat-progress-bar>
  }
  @if (showCreatePersonButton) {
    <button type="button" (click)="createNewPerson()" mat-button>Create New</button>
  }
</div>
