@switch (ctrl.kind) {
  @case ('image') {
    <div>
      <div style="margin-top: 1em">
        <label class="label">
          {{ ctrl.label }}
          <app-media-upload
            style="margin-top: 0.5em; display: block"
            [subfolder]="ctrl.options.subfolder"
            [fileType]="'image'"
            [fileIdCtrl]="ctrl.formControl"
          >
          </app-media-upload>
        </label>
      </div>
    </div>
  }
  @case ('media') {
    <div>
      <div style="margin-top: 1em">
        <label class="label">
          {{ ctrl.label }}
          <app-media-upload
            style="margin-top: 0.5em; display: block"
            [subfolder]="ctrl.options.subfolder"
            [fileType]="'media'"
            [fileIdCtrl]="ctrl.formControl"
          >
          </app-media-upload>
        </label>
      </div>
    </div>
  }
  @case ('portal') {
    <ng-template [cdkPortalOutlet]="ctrl.componentPortal" (attached)="ctrl.options.portalAttached($event, ctrl, keys)">
    </ng-template>
  }
  @case ('text') {
    <mat-form-field>
      <mat-label>{{ ctrl.label }}</mat-label>
      <input matInput type="text" [formControl]="ctrl.formControl" />
    </mat-form-field>
  }
  @case ('textarea') {
    <mat-form-field>
      <mat-label>{{ ctrl.label }}</mat-label>
      <textarea style="height: 300px" matInput type="text" [formControl]="ctrl.formControl"> </textarea>
    </mat-form-field>
  }
  @case ('select') {
    <div class="select-container">
      <mat-form-field>
        <mat-label>{{ ctrl.label }}</mat-label>
        <mat-select [multiple]="ctrl.options.multiple" [formControl]="ctrl.formControl">
          @for (opt of ctrl.options.opts; track opt) {
            <mat-option [value]="opt[1]">
              {{ opt[0] }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (ctrl.options.addSelectOption) {
        <button type="button" mat-button (click)="ctrl.options.addSelectOption(ctrl.options.opts)">Create New</button>
      }
    </div>
  }
  @case ('chip-list') {
    <app-chip-list [label]="ctrl.label" [optsAll]="ctrl.options.opts" [selectedOptsFormControl]="ctrl.formControl">
    </app-chip-list>
  }
  @case ('radio') {
    <!--    radio group not compatible with mat-form-field    -->
    <label class="label">
      {{ ctrl.label }}
      <mat-radio-group style="display: flex; flex-direction: column" [formControl]="ctrl.formControl">
        @for (opt of ctrl.options.opts; track opt) {
          <mat-radio-button style="margin: 5px" [value]="opt[1]">
            {{ opt[0] }}
          </mat-radio-button>
        }
      </mat-radio-group>
    </label>
  }
  @case ('checkbox') {
    <mat-checkbox [labelPosition]="'after'" [formControl]="ctrl.formControl">
      <span class="label">
        {{ ctrl.label }}
      </span>
    </mat-checkbox>
  }
  @case ('toggle') {
    <mat-slide-toggle [labelPosition]="'after'" [formControl]="ctrl.formControl">
      <span class="label">
        {{ ctrl.label }}
      </span>
    </mat-slide-toggle>
  }
  @case ('datetime') {
    <div style="margin-bottom: 1em">
      <app-datetime-picker [label]="ctrl.label" [dateTimeFormControl]="ctrl.formControl"> </app-datetime-picker>
    </div>
  }
  @case ('custom') {
    <ng-container *ngTemplateOutlet="ctrl.options.template; context: { ctrl: ctrl, keys: keys }"> </ng-container>
  }
  @default {
    <div>Unknown ctrl kind</div>
  }
}
