<h1>Create Collection</h1>
<form (ngSubmit)="save()">
  <app-collection-form-elements [collectionFormGroup]="collectionFormGroup"> </app-collection-form-elements>

  <div>
    <a routerLink=".." mat-button> Cancel </a>

    <button [disabled]="collectionFormGroup.invalid" type="submit" mat-button>Create Collection</button>

    @if (loadingCount) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </div>
</form>
