import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PeopleService } from '../people.service';
import { DisplayNameComponent } from '../display-name/display-name.component';

@Component({
  selector: 'app-person-form-elements',
  templateUrl: './person-form-elements.component.html',
  styleUrls: ['./person-form-elements.component.scss'],
})
export class PersonFormElementsComponent implements OnInit {
  peopleList = this.peopleService.peopleList;
  @Input() personFormGroup: FormGroup;

  @ViewChild(DisplayNameComponent)
  displayNameComponent!: DisplayNameComponent;

  constructor(private peopleService: PeopleService) {
    peopleService.reload();
  }

  ngOnInit(): void {}
}
