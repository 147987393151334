import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import Bugsnag from '@bugsnag/js';
import { ErrorsListBottomSheetComponent } from './errors-list-bottom-sheet/errors-list-bottom-sheet.component';

@Injectable({
  providedIn: 'root',
})
export class ShowErrorService {
  ref: MatBottomSheetRef<ErrorsListBottomSheetComponent> = null;

  constructor(private bottomSheet: MatBottomSheet) {}

  showError(title = 'Generic error, something went wrong, please try again', message = '') {
    Bugsnag.notify(`showError title:${title} message:${message}`);
    if (!this.ref) {
      this.display();
    }
    this.ref.instance.addError(title, message);
  }

  showWarning(title = 'Generic warning, something went wrong', message = '') {
    if (!this.ref) {
      this.display();
    }
    this.ref.instance.addError(title, message);
  }

  dismiss() {
    this.ref.instance.close();
  }

  display() {
    this.ref = this.bottomSheet.open(ErrorsListBottomSheetComponent);
    this.ref.afterDismissed().subscribe(() => {
      this.ref = null;
    });
  }
}
