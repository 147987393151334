<h1 class="slumber-text-align-center">Tracks</h1>
<div class="slumber-add-container">
  <button (click)="reloadTracks()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>
  <a routerLink="create" mat-button> Add New Track </a>
</div>

@if (trackList.loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="tracks-table-container">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Sleep" #input />
  </mat-form-field>
  <table mat-table [dataSource]="tracks" matSort matSortActive="id" matSortDisableClear matSortDirection="desc">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>

    <ng-container matColumnDef="detail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Detail</th>
      <td mat-cell *matCellDef="let element">{{ element.detail }}</td>
    </ng-container>

    <ng-container matColumnDef="isPremium">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Premium</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        @if (element.isPremium) {
          <mat-icon>check</mat-icon>
        }
        @if (!element.isPremium) {
          <mat-icon>close</mat-icon>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="statusTitle">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.statusTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="releasedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Released At</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.releasedAt | date: 'medium' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="'edit/' + element.id">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
