import { NgModule } from '@angular/core';
import { canActivate, customClaims } from '@angular/fire/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdministerUsersComponent } from './administer-users/administer-users.component';
import { GetStageFromHost, Roles, Stages, StorageKeys, TenantAppRouter, ValidateTenantAppRouter } from './constants';
import { LoginComponent } from './shared/navigation/login/login.component';
import { ParsedToken } from '@angular/fire/auth';

const AdminOnly = () =>
  pipe(
    customClaims,
    map((claims: ParsedToken) => claims.administrator_role === Roles.Director || ['login']),
  );

const AdminManagerViewerOrDeveloper = () =>
  pipe(
    customClaims,
    map(
      (claims: ParsedToken) =>
        [Roles.Director, Roles.Manager, Roles.Viewer, Roles.Dev].indexOf(claims.administrator_role as number) !== -1 || ['login'],
    ),
  );

const AdminOrDeveloperOnStaging = () =>
  pipe(
    customClaims,
    map((claims: ParsedToken) => {
      if (claims.administrator_role === Roles.Director) {
        return true;
      }
      if (claims.administrator_role === Roles.Dev && GetStageFromHost() !== Stages.Prod) {
        return true;
      }
      return ['login'];
    }),
  );

let appName = TenantAppRouter.Slumber;
let storedDefaultApp = localStorage.getItem(StorageKeys.DEFAULT_APP);

if (storedDefaultApp) {
  const tenantApp = ValidateTenantAppRouter(storedDefaultApp);
  if (tenantApp) {
    appName = tenantApp;
  }
}

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: ':tenant/administer-users',
    component: AdministerUsersComponent,
    ...canActivate(AdminOnly),
  },
  {
    path: ':tenant/people',
    loadChildren: () => import('./person/person.module').then((m) => m.PersonModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/person',
    loadChildren: () => import('./person/person.module').then((m) => m.PersonModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/tracks',
    loadChildren: () => import('./track/track.module').then((m) => m.TrackModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/background-tracks',
    loadChildren: () => import('./background-tracks/background-tracks.module').then((m) => m.BackgroundTracksModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/upload',
    loadChildren: () => import('./upload/upload.module').then((m) => m.UploadModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/mixes',
    loadChildren: () => import('./mix/mix.module').then((m) => m.MixModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/categories',
    loadChildren: () => import('./categories/categories.module').then((m) => m.CategoriesModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  {
    path: ':tenant/collections',
    loadChildren: () => import('./collections/collections.module').then((m) => m.CollectionsModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  { path: ':tenant/home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
  {
    path: ':tenant/run-jobs',
    loadChildren: () => import('./run-jobs/run-jobs.module').then((m) => m.RunJobsModule),
    ...canActivate(AdminOrDeveloperOnStaging),
  },
  {
    path: ':tenant/explorer',
    loadChildren: () => import('./api-explorer/api-explorer.module').then((m) => m.ApiExplorerModule),
    ...canActivate(AdminManagerViewerOrDeveloper),
  },
  { path: '**', redirectTo: `${appName}/tracks` },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
