import { Component, Input, OnInit } from '@angular/core';

export interface ConfirmNumberOpts {
  title: string;
  label: string;
  icon: string;
  confirmButtonText: string;
}

@Component({
  selector: 'app-confirm-number',
  templateUrl: './confirm-number.component.html',
  styleUrls: ['./confirm-number.component.scss'],
})
export class ConfirmNumberComponent implements OnInit {
  @Input() confirmNumberOpts: ConfirmNumberOpts;
  returnNumber: number = null; // returns object {number: returnNumber}

  constructor() {}

  ngOnInit(): void {}
}
