import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MixInfo, MixesService } from '../mixes.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-mixes',
  templateUrl: './mixes.component.html',
  styleUrls: ['./mixes.component.scss'],
})
export class MixesComponent implements OnInit, AfterViewInit {
  constructor(private mixesService: MixesService) {}

  loadingCount = 0;
  mixes = new MatTableDataSource<MixInfo>();
  displayedColumns: string[] = ['mix.id', 'mix.title', 'mixTrackCount', 'mixBackgroundTrackCount', 'edit'];

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.readMixes();
  }

  readMixes() {
    this.loadingCount += 1;
    this.mixesService
      .getMixes()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((mixes) => {
        this.mixes.data = mixes;
        this.mixesService.mixes = mixes;
      });
  }

  ngAfterViewInit() {
    this.mixes.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'mix.id':
          return item.mix.id;
        case 'mix.title':
          return item.mix.title;
        default:
          return item[property];
      }
    };
    this.mixes.sort = this.sort;
  }
}
