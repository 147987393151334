<h1>Edit Track</h1>
@if (!trackFormGroup) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (trackFormGroup) {
  <form (ngSubmit)="save()">
    <app-track-form-elements
      [trackFormGroup]="trackFormGroup"
      [mediaPlatformFormatsInfo]="mediaPlatformFormatsInfo"
      parentActionType="updateAction"
    >
    </app-track-form-elements>
    <div style="display: flex; justify-content: space-between">
      <span>
        <a routerLink="../.." mat-button> Cancel </a>
        <button [disabled]="trackFormGroup.invalid" type="submit" mat-button>
          Update Track{{ !trackFormGroup.get('narrators').value.length && isDefaultSlumber ? ' without Narrator' : '' }}
        </button>
      </span>
      <button (click)="deleteTrack()" type="button" mat-button>
        <mat-icon>delete</mat-icon>
        Delete Track
      </button>
    </div>
    @if (loadingCount) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </form>
}
