import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CategoriesRoutingModule } from './categories-routing.module';
import { CategoryListComponent } from './category-list/category-list.component';
import { SharedModule } from '../shared/shared.module';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { CategoryFormElementsComponent } from './category-form-elements/category-form-elements.component';
import { TrackModule } from '../track/track.module';
import { PersonModule } from '../person/person.module';
import { CollectionsModule } from '../collections/collections.module';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [CategoryListComponent, EditCategoryComponent, CategoryFormElementsComponent, CreateCategoryComponent],
  imports: [
    CommonModule,
    CategoriesRoutingModule,
    SharedModule,
    TrackModule,
    PersonModule,
    CollectionsModule,
    MatTableModule,
  ],
})
export class CategoriesModule {}
