import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';
import { MixesService, MixInfo } from '../../mix/mixes.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';

@Component({
  selector: 'app-mix-picker',
  templateUrl: './mix-picker.component.html',
  styleUrls: ['./mix-picker.component.scss'],
})
export class MixPickerComponent implements OnInit {
  @Input() selectedMixIdFormControl: FormControl;
  myControl = new FormControl<MixInfo | null>(null);
  filteredMixes: Observable<MixInfo[]>;
  loadingCount = 0;
  mixes: MixInfo[];

  constructor(private mixesService: MixesService, private showErrorService: ShowErrorService) {}

  ngOnInit() {
    this.loadingCount += 1;
    this.mixesService
      .getMixes()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe(
        (mixes: MixInfo[]) => {
          for (const mix of mixes) {
            if (mix.mix.id === this.selectedMixIdFormControl.value) {
              this.myControl.setValue(mix);
            }
          }
          this.mixes = mixes;
          this.filteredMixes = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value) => (typeof value === 'string' ? value : value?.mix?.title)),
            map((name) => (name ? this._filter(name) : this.mixes.slice()))
          );
        },
        () => {
          this.showErrorService.showError(Errors.MIX_LOADING_ERROR);
        }
      );
  }

  displayFn = (mix: MixInfo) => {
    return mix?.mix?.title || '';
  };

  private _filter(s: string): MixInfo[] {
    const filterValue = s.toLowerCase();
    return this.mixes.filter((mix) => mix.mix.title.toLowerCase().indexOf(filterValue) === 0);
  }
}
