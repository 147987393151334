<div class="banner">
  <div>
    <img src="/assets/icons/Slumber_Icon-1024.png" />
  </div>
  <h4>Welcome to</h4>
  <h1 class="center">Slumber Dashboard</h1>
  @if (sendingToTracks) {
    <h1 class="center">Sending to /Tracks..</h1>
  }
</div>
<div class="content">
  @if (user | async; as user) {
    <div class="user">
      <button (click)="verifyLogin(user, true)" mat-button>
        <mat-icon>refresh</mat-icon>
      </button>
      @if (claims != null) {
        <table>
          <tr>
            <td></td>
            <td>
              <div class="img-container">
                <img [src]="user.photoURL" />
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>{{ user.displayName || 'No Name' }}</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ claims.email || 'no email!' }}</td>
          </tr>
          <tr>
            <td>Email verified</td>
            <td>{{ claims.email_verified }}</td>
          </tr>
          <tr>
            <td>Administrator Role</td>
            @switch (claims.administrator_role) {
              @case (1) {
                <td>1 (Director)</td>
              }
              @case (2) {
                <td>2 (Manager)</td>
              }
              @case (3) {
                <td>3 (Viewer)</td>
              }
              @case (4) {
                <td>4 (Developer)</td>
              }
              @default {
                <td>
                  You have no administrative authorities!
                  <ul style="margin-left: 1em">
                    <li>Try click refresh</li>
                    <li>Or change email / google account</li>
                    <li>Contact an admin to grant access</li>
                  </ul>
                </td>
              }
            }
          </tr>
          <tr>
            <td>Administrator User ID</td>
            <td>{{ claims.administrator_id || 'None Set! please click refresh!' }}</td>
          </tr>
        </table>
      }
      @if (claims == null) {
        @if (claimsLoading > 0) {
          <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
        }
        @if (claimsLoading > 0) {
          <span>Verifying Login</span>
        }
        @if (claimsLoading <= 0) {
          <span>Login Unsuccessful. Please try with a valid account</span>
        }
      }
      <button mat-button color="primary" (click)="logout()">Logout</button>
    </div>
  } @else {
    <button mat-button color="primary" (click)="login()">
      <mat-icon>lock</mat-icon>
      Login
    </button>
  }
</div>
