<mat-form-field class="example-chip-list">
  <mat-label>{{ label + ' (' + selectedCount + ')' }}</mat-label>
  <mat-chip-grid #chipList aria-label="Item selection" [disabled]="disabled">
    @for (item of selectedItems; track item) {
      @if (item.kind == kind) {
        <mat-chip-row [removable]="true" (removed)="remove(item)">
          {{ item.kindId }} {{ item.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
      }
    }
    <input #searchTextInput [formControl]="searchTextCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList" />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event); searchTextInput.value = ''">
    @for (item of filteredItems | async; track item) {
      <mat-option [value]="item">
        {{ item.kindId }} {{ item.name }}
        @if (item.selected) {
          <mat-icon>cancel</mat-icon>
        }
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
