import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ImageFile, MediaFile } from '../../track/track.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-upload-queue-item-autocomplete-picker',
  templateUrl: './upload-queue-item-autocomplete-picker.component.html',
  styleUrls: ['./upload-queue-item-autocomplete-picker.component.scss'],
})
export class UploadQueueItemAutocompletePickerComponent implements OnInit {
  @Input() fileIdCtrl: FormControl;
  @Input() files: (ImageFile | MediaFile)[];
  @Input() fileType: 'image' | 'media';
  myControl = new FormControl<ImageFile | MediaFile | null>(null);
  filteredFiles: Observable<(ImageFile | MediaFile)[]>;

  constructor() {}

  ngOnInit() {
    for (const file of this.files) {
      if (file.id === this.fileIdCtrl.value) {
        this.myControl.setValue(file);
      }
    }
    this.filteredFiles = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return typeof value === 'string' ? value : value?.path;
      }),
      map((name) => (name ? this._filter(name) : this.files.slice()))
    );
  }

  displayFn = (file: ImageFile | MediaFile) => {
    if (file && file.id && file.path) {
      return `${file.id} ${file.path}`;
    }
    return '';
  };

  private _filter(s: string): (ImageFile | MediaFile)[] {
    const filterValue = s.toLowerCase().split(' ').join('_'); // space to _
    return this.files.filter((file) => {
      return file.path.toLowerCase().indexOf(filterValue) !== -1;
    });
  }
}
