import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MobileClient } from '../constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiExplorerService {
  constructor(private http: HttpClient) {}

  getLibrary(client: MobileClient, url: string): Observable<any> {
    let key = environment.iOSApiKey;
    if (client === MobileClient.Android) {
      key = environment.AndroidApiKey;
    }

    const headers = { 'x-api-key': key, 'X-Device-Timestamp': '1' };
    return this.http.get<any>(url + '&ts=' + Date.now(), { headers });
  }
}
