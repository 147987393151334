import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { MixInfo, MixesService } from '../mixes.service';

@Component({
  selector: 'app-edit-mix',
  templateUrl: './edit-mix.component.html',
  styleUrls: ['./edit-mix.component.scss'],
})
export class EditMixComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  mixFormGroup: FormGroup;
  initialForm: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private mixesService: MixesService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.mixFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.mixFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.loadingCount += 1;
    this.mixesService
      .getMix(this.activeRoute.snapshot.params.id)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((b: MixInfo) => {
        this.mixFormGroup = this.fb.group(
          {
            mix: this.fb.group({
              id: { value: b.mix.id, disabled: true },
              title: b.mix.title,
            }),
            selectedBackgroundTracks: [b.selectedBackgroundTracks, [Validators.minLength(1), Validators.required]],
          },
          { validators: this.publishingRequirementsValidator.bind(this) },
        );
        this.initialForm = this.mixFormGroup.value;
      });
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    if (deepEqual(this.initialForm, formGroup.value)) {
      return { changeRequired: true };
    }
    return this.mixesService.checkFormError(formGroup, parseInt(formGroup.get('mix.id').value));
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const mixPayload = this.mixesService.makeCreateOrUpdateMixPayload(this.mixFormGroup.getRawValue());
      const [warnings, _] = this.mixesService.cleanForm(mixPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.mixesService
        .updateMix(mixPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/mixes`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.MIX_UPDATE_ERROR);
          },
        });
    });
  }
}
