import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UploadRoutingModule } from './upload-routing.module';
import { FileUploadModule } from 'ng2-file-upload';
import { MediaUploadComponent } from './media-upload/media-upload.component';
import { MaterialModule } from '../material/material.module';
import { TestUploadComponent } from './test-upload/test-upload.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadQueueComponent } from './upload-queue/upload-queue.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { UploadQueueItemComponent } from './upload-queue-item/upload-queue-item.component';
import { UploadQueueItemAutocompletePickerComponent } from './upload-queue-item-autocomplete-picker/upload-queue-item-autocomplete-picker.component';
import { DndDirective } from './media-upload/dnd2.directive';

@NgModule({
  declarations: [
    MediaUploadComponent,
    TestUploadComponent, // <--- for testing
    UploadQueueComponent,
    FilePreviewComponent,
    UploadQueueItemComponent,
    UploadQueueItemAutocompletePickerComponent,
    DndDirective,
  ],
  imports: [CommonModule, UploadRoutingModule, FileUploadModule, MaterialModule, FormsModule, ReactiveFormsModule],
  exports: [MediaUploadComponent],
})
export class UploadModule {}
