import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ContentTypes, StatusCodes } from '../constants';
import { ShowErrorService } from '../error-reporting/show-error.service';
import { Errors } from '../errors';
import { isTrimmed } from '../utils';
import { ContentType, TrackListInfo, TrackResponse } from './track.model';

export interface TrackList {
  loadingCount: number;
  tracks: TrackListInfo[];
}

@Injectable({
  providedIn: 'root',
})
export class TracksService {
  public trackList: TrackList = {
    loadingCount: 0,
    tracks: [],
  };
  trackList$ = new Subject();

  constructor(private http: HttpClient, private showErrorService: ShowErrorService) {}

  reloadTracks() {
    this.trackList.loadingCount += 1;
    this.getTracks()
      .pipe(
        finalize(() => {
          this.trackList.loadingCount -= 1;
          this.trackList$.next(this.trackList);
        })
      )
      .subscribe(
        (tracks: TrackListInfo[]) => {
          this.trackList.tracks = tracks;
        },
        () => this.showErrorService.showError(Errors.TRACK_LOADING_ERROR)
      );
  }

  getTrack(trackId: number): Observable<TrackResponse> {
    return this.http.get<TrackResponse>(`/api/tracks/${trackId}`);
  }

  updateTrack<T>(trackPayload: T) {
    return this.http.put<T>(`/api/tracks`, trackPayload);
  }

  createTrack<T>(trackPayload: T) {
    return this.http.post<T>(`/api/tracks`, trackPayload);
  }

  getContentTypes(): Observable<ContentType[]> {
    return this.http.get<ContentType[]>(`/api/content-types`);
  }

  makeCreateOrUpdateTrackPayload(trackFormGroupValue) {
    const trackPayload = {
      ...trackFormGroupValue,
    };
    trackPayload.tags = trackFormGroupValue.tags.map(([title, id]) => {
      return { title, id };
    });
    if (trackPayload.track.imageFileId === 0) {
      delete trackPayload.track.imageFileId;
    }
    if (trackPayload.track.mediaFileId === 0) {
      delete trackPayload.track.mediaFileId;
    }
    return trackPayload;
  }

  deleteTrack(trackId: number) {
    return this.http.delete(`/api/tracks/${trackId}`);
  }

  getTracks() {
    return this.http.get<TrackListInfo[]>('/api/tracks');
  }

  cleanForm(trackPayload: any): [string[], boolean] {
    const warnings: string[] = [];
    if (isTrimmed(trackPayload.track.trackTranslation.detail) === false) {
      trackPayload.track.trackTranslation.detail = trackPayload.track.trackTranslation.detail.trim();
      if (!trackPayload.track.trackTranslation.detail) {
        return [warnings, false];
      }
      warnings.push('Detail has been trimmed automatically');
    }
    if (isTrimmed(trackPayload.track.trackTranslation.title) === false) {
      trackPayload.track.trackTranslation.title = trackPayload.track.trackTranslation.title.trim();
      if (!trackPayload.track.trackTranslation.title) {
        return [warnings, false];
      }
      warnings.push('Title has been trimmed automatically');
    }
    if (isTrimmed(trackPayload.track.translationContext) === false) {
      trackPayload.track.translationContext = trackPayload.track.translationContext.trim();
      if (!trackPayload.track.translationContext) {
        return [warnings, false];
      }
      warnings.push('Translation context has been trimmed automatically');
    }
    return [warnings, true];
  }

  checkFormError(formGroup: FormGroup, trackId: number, isDefaultSlumber: boolean) {
    const track = formGroup.get('track');
    const statusCode = track.get('statusCode').value;
    if (statusCode === StatusCodes.SCHEDULE || statusCode === StatusCodes.PUBLISHED) {
      if (isDefaultSlumber === false && !track.get('translationContext').value) {
        return { translationContextRequired: true };
      }
      if (!track.get('imageFileId').value) {
        return { imageFileRequired: true };
      }
      if (!track.get('mediaFileId').value) {
        return { mediaFileRequired: true };
      }
      if (
        isDefaultSlumber &&
        track.get('contentTypeId').value !== ContentTypes.Music &&
        formGroup.get('mixId').value === null
      ) {
        return { mixRequired: true };
      }
    } else if (statusCode === StatusCodes.PROCESSING_AUDIO_ERROR_CODE) {
      return { audioProcessingRequired: true };
    }

    const trackPayload = this.makeCreateOrUpdateTrackPayload(formGroup.getRawValue());
    const [, isClean] = this.cleanForm(trackPayload);
    if (isClean === false) {
      return { cleanFormRequired: true };
    }

    if (track.get('trackTranslation.title').value === trackPayload.track.key) {
      return { titleAndKeySame: true };
    }

    if (this.trackList.tracks) {
      for (const trackListInfo of this.trackList.tracks) {
        if (trackListInfo.id === trackId) continue;
        if (track.get('trackTranslation.title').value.toLowerCase() === trackListInfo.title.toLowerCase()) {
          return { trackTitleExists: true };
        }
      }
    }

    return {};
  }
}
