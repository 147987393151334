@if (loadingCount == 0) {
  <app-chip-list
    [disableAdd]="true"
    [label]="label"
    [optsAll]="trackOpts"
    [selectedOptsFormControl]="selectedOptsFormControl"
  >
  </app-chip-list>
}
@if (loadingCount) {
  <mat-progress-bar> </mat-progress-bar>
}

@if (selectedBackgroundTracks.value?.length) {
  <div class="volume-grid">
    @for (selectedBackgroundTrack of selectedBackgroundTracks.value; track selectedBackgroundTrack) {
      <div>
        {{ titleFromBackgroundTrackIdMap[selectedBackgroundTrack.backgroundTrackId] || 'Error no title' }}
      </div>
      <div>
        <mat-slider [min]="0" [max]="100" discrete>
          <input
            matSliderThumb
            [value]="selectedBackgroundTrack.volume"
            (change)="onVolumeChange($event, selectedBackgroundTrack)"
          />
        </mat-slider>
      </div>
      <div>
        <input
          matInput
          (change)="onVolumeChange(selectedBackgroundTrack.volume, selectedBackgroundTrack)"
          [(ngModel)]="selectedBackgroundTrack.volume"
          type="number"
          [min]="0"
          [max]="100"
        />
      </div>
    }
  </div>
}

<h4 style="margin-top: 0.5em">Total {{ selectedOptsFormControl.value.length }}</h4>

<a (click)="showHotkeys = !showHotkeys" class="slider-hotkeys-link">
  <mat-icon>info</mat-icon>
  <span> Show Slider hotkeys </span>
</a>
<div class="hotkey-text">
  @if (showHotkeys) {
    <pre>
      Right arrow	Increment the slider value by one step (decrements in RTL).
      Up arrow	Increment the slider value by one step.
      Left arrow	Decrement the slider value by one step (increments in RTL).
      Down arrow	Decrement the slider value by one step.
      Page up	Increment the slider value by 10 steps.
      Page down	Decrement the slider value by 10 steps.
      End	Set the value to the maximum possible.
      Home	Set the value to the minimum possible.
      Tab  Next slider
    </pre>
  }
</div>
