<h1>Create Person</h1>
<form (ngSubmit)="save()">
  <app-person-form-elements [personFormGroup]="personFormGroup"> </app-person-form-elements>

  <div>
    <a (click)="cancel()" mat-button> Cancel </a>
    <button [disabled]="personFormGroup.invalid" type="submit" mat-button>Create Person</button>

    @if (personFormGroup.invalid && personFormGroup.get('person').get('personTranslation.displayName').valid) {
      <button type="submit" mat-button>Incomplete, Save Anyway</button>
    }

    @if (loadingCount) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </div>
</form>
