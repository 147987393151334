import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApiExplorerRoutingModule } from './api-explorer-routing.module';
import { ApiExplorerComponent } from './api-explorer/api-explorer.component';
import { SharedModule } from '../shared/shared.module';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@NgModule({
  declarations: [ApiExplorerComponent],
  imports: [
    CommonModule,
    ApiExplorerRoutingModule,
    SharedModule,
    NgxJsonViewerModule,
    ClipboardModule,
    MatButtonToggleModule,
  ],
})
export class ApiExplorerModule {}
