import { Component, OnInit } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { idTokenResult } from '@angular/fire/auth-guard';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { NavigationEnd, Router } from '@angular/router';
import Bugsnag from '@bugsnag/js';
import { Roles, TenantAppRouter } from '../../../constants';
import { UploadQueueComponent } from '../../../upload/upload-queue/upload-queue.component';
import { getTenantAppRouterFromURL } from '../../../utils';

@Component({
  selector: 'navigation-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  Roles = Roles;
  roleId = 0;
  bottomSheetRef: MatBottomSheetRef<UploadQueueComponent, any> = null;
  isDefaultSlumber = true;
  appName: TenantAppRouter = TenantAppRouter.Slumber;

  constructor(
    private afAuth: Auth,
    private bottomSheet: MatBottomSheet,
    private router: Router,
  ) {}

  ngOnInit() {
    idTokenResult(authState(this.afAuth)).subscribe((token) => {
      this.roleId = 0;
      if (token == null) {
        return;
      }
      this.roleId = token.claims.administrator_role as number || 0;
      Bugsnag.setUser(token.claims?.administrator_id as string, token.claims?.email as string, 'name not set');
    });

    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
        if (defaultAppFromURL === TenantAppRouter.DSS) {
          this.isDefaultSlumber = false;
          this.appName = TenantAppRouter.DSS;
        }
      }
    });
  }

  toggleUploadQueue() {
    if (this.bottomSheetRef === null) {
      this.bottomSheetRef = this.bottomSheet.open(UploadQueueComponent, {
        hasBackdrop: false,
      });
      this.bottomSheetRef.afterDismissed().subscribe(() => {
        this.bottomSheetRef = null;
      });
    } else {
      this.bottomSheetRef.dismiss();
    }
  }
}
