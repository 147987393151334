import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { checkSimilarity } from 'src/app/utils';
import { Item, Kind } from '../../form-elements/chip-list2/chip-list2.component';
import { ItemsHelperService } from '../../items-helper.service';
import { CollectionsService } from '../collections.service';

@Component({
  selector: 'app-collection-form-elements',
  templateUrl: './collection-form-elements.component.html',
  styleUrls: ['./collection-form-elements.component.scss'],
})
export class CollectionFormElementsComponent implements OnInit {
  @Input() collectionFormGroup: FormGroup;
  selectedItems: Item[];
  allItems: Item[];
  Kind = Kind;

  statusCodeControl: FormControl;
  releasedAtControl: FormControl;
  unpublishedAtControl: FormControl;

  similarCollectionWarning: string | null;

  constructor(public itemsHelperService: ItemsHelperService, private collectionsService: CollectionsService) {}

  ngOnInit(): void {
    this.loadItems();

    const c = this.collectionFormGroup.get('collection');
    this.statusCodeControl = c.get('statusCode') as FormControl;
    this.releasedAtControl = c.get('releasedAt') as FormControl;
    this.unpublishedAtControl = c.get('unpublishedAt') as FormControl;
  }

  loadItems() {
    this.itemsHelperService.allItems$(this.collectionFormGroup.get('items').value).subscribe((res) => {
      this.selectedItems = res.selectedItems;
      this.allItems = res.allItems;
    });
  }

  // Show warning if the "isSeries" toggle is off and (the collection is a series,
  // or if one of the tracks in the collection is like a series track).
  showIsSeriesWarning() {
    const isSeriesToggle = this.collectionFormGroup.get('collection.isSeries').value;
    if (isSeriesToggle == true) return false;

    const title = this.collectionFormGroup.get('collection.collectionTranslation.title').value;
    const detail = this.collectionFormGroup.get('collection.collectionTranslation.detail').value;

    return (
      this.collectionsService.checkIsSeries(title, detail) ||
      this.collectionsService.checkIfTracksArePartOfASeries(this.selectedItems)
    );
  }

  // "Full Book" tracks must be the last item in the series.
  showCompilationTrackOrderWarning() {
    let compilationTrack = this.collectionsService.checkIfTracksContainCompilation(this.selectedItems);
    return compilationTrack && compilationTrack.order !== this.selectedItems.length;
  }

  toggleItem(item: Item) {
    const isSeries = this.collectionFormGroup.get('collection.isSeries').value;
    this.itemsHelperService.toggleItem(item, this.selectedItems, isSeries);
    this.updateFormGroupItems();
  }

  updateFormGroupItems() {
    // called when item is toggled, or when item is drag & dropped
    this.collectionFormGroup
      .get('items')
      .setValue(this.itemsHelperService.selectedItemsToSlumberItems(this.selectedItems));
  }

  onFocusOutEvent(event: any) {
    let collectionId = 0;
    this.similarCollectionWarning = null;
    if (this.collectionFormGroup.get('collection.id'))
      collectionId = parseInt(this.collectionFormGroup.get('collection.id').value);
    if (!this.collectionsService.collections) return;

    const collectionTitles = this.collectionsService.collections
      .filter((collection) => collection.collection.id != collectionId)
      .map((collection) => collection.collection.collectionTranslation.title);
    const similarTitles = checkSimilarity(collectionTitles, event.target.value);
    if (similarTitles.length > 0) {
      this.similarCollectionWarning = `Similar collection titles: ${similarTitles.join(', ')}`;
    }
  }
}
