<mat-form-field>
  <mat-label>Display Name</mat-label>
  <input matInput type="text" [formControl]="displayNameControl" />
</mat-form-field>

<ng-template #duplicatePeopleTemplate let-dups="dups">
  <div>
    @for (person of dups; track person) {
      <app-person style="margin-bottom: 1em" [personInfo]="person"> </app-person>
    }
  </div>
</ng-template>
