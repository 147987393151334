<mat-form-field>
  <mat-label>
    {{ fileIdCtrl.value ? 'File ' + fileIdCtrl.value + ' Selected' : 'Select File' }}
  </mat-label>
  <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" />

  @if (fileIdCtrl.value) {
    <button matSuffix mat-icon-button aria-label="Clear" (click)="fileIdCtrl.setValue(null); myControl.setValue(null)">
      <mat-icon>close</mat-icon>
    </button>
  }

  <mat-autocomplete
    (optionSelected)="fileIdCtrl.setValue($event.option.value.id)"
    #auto="matAutocomplete"
    [displayWith]="displayFn"
  >
    @for (file of filteredFiles | async; track file) {
      <mat-option style="height: 5em" [value]="file">
        <div style="display: flex; justify-content: space-between; align-items: center">
          @if (fileType == 'image' && file && file.url) {
            <div>
              <img [src]="file.url" style="width: 60px; height: 60px" />
            </div>
          }
          @if (fileType == 'media' && file && file.url) {
            <div>
              <audio controls>
                <source [src]="file.url" />
                Your browser does not support the audio element.
              </audio>
            </div>
          }
          <div>
            {{ file?.path ? file.path.split('_').join(' ') : '-' }}
          </div>
          <div>{{ file?.size / 1024 / 1024 | number: '.2' }} MB</div>
        </div>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
