<h1 class="slumber-text-align-center">Collections</h1>

<div class="slumber-add-container">
  <button (click)="reloadCollections()" mat-icon-button>
    <mat-icon> refresh </mat-icon>
  </button>
  <a routerLink="create" mat-button> Add New Collection </a>
</div>
@if (loadingCount) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}

<section class="collections-table-container">
  <table
    mat-table
    [dataSource]="collections"
    matSort
    matSortActive="collection.id"
    matSortDisableClear
    matSortDirection="asc"
  >
    <ng-container matColumnDef="collection.id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
      <td mat-cell *matCellDef="let element">{{ element.collection.id }}</td>
    </ng-container>

    <ng-container matColumnDef="collection.imageFile.url">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="img-container">
        @if (element.collection.imageFile && element.collection.imageFile.url !== '(unknown)') {
          <img [src]="element.collection.imageFile.url" alt="" />
        } @else {
          <img src="/assets/placeholder.png" alt="" />
        }
        <ng-template #placeholder>
          <img src="/assets/placeholder.png" alt="" />
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="collection.collectionTranslation.title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">
        {{ element.collection.collectionTranslation ? element.collection.collectionTranslation.title : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="collection.collectionTranslation.detail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Detail</th>
      <td mat-cell *matCellDef="let element">
        {{ element.collection.collectionTranslation ? element.collection.collectionTranslation.detail : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="collection.isSeries">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Series</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        {{ element.collection.isSeries ? 'True' : 'False' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="collection.statusCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">{{ element.collection.statusCode }}</td>
    </ng-container>

    <ng-container matColumnDef="countTracks">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Count</th>
      <td mat-cell *matCellDef="let element" style="text-align: center">{{ element.countTracks }}</td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="'edit/' + element.collection.id">
          <mat-icon>edit</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
