<div class="item-preview">
  @if (thumb) {
    <div class="img-container">
      <img [src]="thumb" />
    </div>
  }
  @if (audio) {
    <div class="audio-container">
      <audio controls>
        <source [src]="audio" />
        Your browser does not support the audio element.
      </audio>
    </div>
  }
</div>
<div class="item-details">
  <div class="description">
    {{ fileName }}
  </div>
  <div>({{ fileSize / 1024 / 1024 | number: '.2' }} MB)</div>
</div>
<div class="action-container">
  @switch (progress ? progress : -1) {
    @case (100) {
      @if (isSuccess) {
        <mat-icon>check</mat-icon>
      }
      @if (isError) {
        <mat-icon>error</mat-icon>
      }
    }
    @case (-1) {
      <ng-content> </ng-content>
    }
    @default {
      <mat-progress-spinner [diameter]="20" mode="determinate" [value]="progress"> </mat-progress-spinner>
    }
  }
</div>
