import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { makeKeyFromTitle } from 'src/app/utils/strings';
import { CategoryTypes, SlumberCategories, TenantAppRouter } from '../../constants';
import { DialogService } from '../../dialog/dialog.service';
import { Item, Kind } from '../../form-elements/chip-list2/chip-list2.component';
import { ItemsHelperService } from '../../items-helper.service';
import { TrackResponse } from '../../track/track.model';
import { TracksService } from '../../track/tracks.service';
import { checkSimilarity, getTenantAppRouterFromURL } from '../../utils';
import { CategoriesService, CategoryDTO, CategoryType } from '../categories.service';

@Component({
  selector: 'app-category-form-elements',
  templateUrl: './category-form-elements.component.html',
  styleUrls: ['./category-form-elements.component.scss'],
})
export class CategoryFormElementsComponent implements OnInit {
  @Input() categoryFormGroup: FormGroup;
  @Input() categoryDTO: CategoryDTO;
  categoryTypes: CategoryType[];
  selectedItems: Item[];
  allItems: Item[];
  Kind = Kind;

  statusCodeControl: FormControl;
  releasedAtControl: FormControl;
  unpublishedAtControl: FormControl;

  isDefaultSlumber = true;
  selectedCategoryType: CategoryType;
  serverComputedCategoryTypedId = CategoryTypes.ServerComputed;

  similarCategoryWarning: string | null;

  constructor(
    public itemsHelperService: ItemsHelperService,
    public trackService: TracksService,
    private categoriesService: CategoriesService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadItems();

    const c = this.categoryFormGroup.get('category');
    this.statusCodeControl = c.get('statusCode') as FormControl;
    this.releasedAtControl = c.get('releasedAt') as FormControl;
    this.unpublishedAtControl = c.get('unpublishedAt') as FormControl;

    let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
    if (defaultAppFromURL === TenantAppRouter.DSS) {
      this.isDefaultSlumber = false;
    }

    const isUpdate = !!this.categoryFormGroup.get('category.id');
    if (!isUpdate) {
      this.categoryFormGroup.get('category.categoryTranslation.title').valueChanges.subscribe((value) => {
        this.categoryFormGroup.patchValue({
          category: { key: makeKeyFromTitle(value) },
        });
      });
    }
  }

  loadItems() {
    this.itemsHelperService.allItems$(this.categoryFormGroup.get('items').value).subscribe((res) => {
      this.selectedItems = res.selectedItems;
      this.allItems = res.allItems;
    });

    this.categoriesService.getCategoryTypes().subscribe((categoryTypes) => {
      this.categoryTypes = categoryTypes;
      categoryTypes.forEach((categoryType) => {
        if (categoryType.id === this.categoryFormGroup.get('category.categoryTypeId').value) {
          this.selectedCategoryType = categoryType;
        }
      });
    });
  }

  toggleItem(item: Item) {
    if (item.kind === Kind.Track && item.selected) {
      this.trackService.getTrack(item.kindId).subscribe((trackResponse: TrackResponse) => {
        // Check if the track is premium and is being added to a free category
        if (
          trackResponse.track.isPremium &&
          this.categoryDTO &&
          this.categoryDTO.category.id === SlumberCategories.Free
        ) {
          this.dialogService
            .openConfirmBool({
              title: `Are you sure you want to add a Premium track to the Free category?`,
              trueButtonText: 'Yes, add it',
              falseButtonText: 'Cancel',
            })
            .afterClosed()
            .subscribe((isConfirm) => {
              if (!isConfirm) {
                item.selected = false;
                this.itemsHelperService.toggleItem(item, this.selectedItems, false, this.isDefaultSlumber);
                this.updateFormGroupItems();
              }
            });
        }
      });
    }

    this.itemsHelperService.toggleItem(item, this.selectedItems, false, this.isDefaultSlumber);
    this.updateFormGroupItems();
  }

  updateFormGroupItems() {
    // called when item is toggled, or when item is drag & dropped
    this.categoryFormGroup
      .get('items')
      .setValue(this.itemsHelperService.selectedItemsToSlumberItems(this.selectedItems));
  }

  onFocusOutEvent(event: any) {
    let categoryId = 0;
    this.similarCategoryWarning = null;
    if (this.categoryFormGroup.get('category.id'))
      categoryId = parseInt(this.categoryFormGroup.get('category.id').value);
    if (!this.categoriesService.categories) return;

    const categoryTitles = this.categoriesService.categories
      .filter((category) => category.category.id != categoryId)
      .map((category) => category.category.categoryTranslation.title);
    const similarTitles = checkSimilarity(categoryTitles, event.target.value);
    if (similarTitles.length > 0) {
      this.similarCategoryWarning = `Similar category titles: ${similarTitles.join(', ')}`;
    }
  }
}
