import { ValidateTenantAppRouter } from './constants';

export function isTrimmed(s) {
  return !/(^\s)|(\s$)/.test(s);
}

export function getTenantAppRouterFromURL(url: string): string | null {
  let splitUrl = url.split('/');
  if (splitUrl.length > 1) {
    let tenant = splitUrl[1];
    return ValidateTenantAppRouter(tenant);
  }
  return null;
}

// Calculate the Levenshtein distance to get the similarity index of two strings.
// We do it by counting the amount of single character modifications needed to make
// to a word to get the to the other word.
// https://itnext.io/levenshtein-distance-in-typescript-6de81ea2fb63
export const levenshteinDistance = (string1: string, string2: string): number => {
  const matrix: number[][] = [];

  const string1Lower = string1.toLowerCase().trim();
  const string2Lower = string2.toLowerCase().trim();

  const string1Length = string1Lower.length;
  const string2Length = string2Lower.length;

  // Initialize the matrix
  for (let i = 0; i <= string1Length; i++) {
    matrix[i] = [];
    matrix[i][0] = i;
  }
  for (let j = 0; j <= string2Length; j++) {
    matrix[0][j] = j;
  }

  // Calculate the Levenshtein distance
  for (let i = 1; i <= string1Length; i++) {
    for (let j = 1; j <= string2Length; j++) {
      if (string1Lower[i - 1] === string2Lower[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = 1 + Math.min(matrix[i - 1][j], matrix[i][j - 1], matrix[i - 1][j - 1]);
      }
    }
  }

  return matrix[string1Length][string2Length];
};

// Check if a string is similar to any of the strings in a list. Threshold is the
// minimum amount of single character modifications needed to make to a string to
// get the to the other string. By default, we use 2 as the default threshold.
export const checkSimilarity = (strings: string[], string: string, threshold: number = 2): string[] => {
  const similarStrings: string[] = [];

  for (const _string of strings) {
    const distance = levenshteinDistance(_string, string);
    if (distance <= threshold) similarStrings.push(_string);
  }

  return similarStrings;
};
