import { Component, Input, OnInit } from '@angular/core';
import { CtrlContainer } from '../../dialog/confirm/confirm.component';

@Component({
  selector: 'app-form-element,[app-form-element]',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
})
export class FormElementComponent implements OnInit {
  @Input() ctrl: CtrlContainer;
  @Input() keys: any;

  constructor() {}

  ngOnInit(): void {}
}
