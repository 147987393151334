import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import Bugsnag from '@bugsnag/js';
import { from } from 'rxjs';
import { map, mergeMap, tap, timeout } from 'rxjs/operators';
import { AudioFormat, AudioFormatsService, MediaPlatformFormatInfo } from '../../audio-formats.service';
import { FileFormats, FileTypes } from '../../constants';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { ImageFile, MediaFile } from '../../track/track.model';
import { SlumberUploadTrackerService } from '../slumber-upload-tracker.service';

export interface FileUploadResponse {
  fullUrl: string;
  fileId: number;
  size: number;
}

@Component({
  selector: 'app-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent implements OnInit, OnDestroy {
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  file: any;

  @Input() fileIdCtrl: FormControl;
  @Input() platforms: FormControl;
  @Input() fileType: 'image' | 'media';
  @Input() parentActionType: 'createAction' | 'updateAction';
  @Input() subfolder: 'person' | 'track' | 'collection' | 'background_track';
  @Input() fileNameSuggestion;
  @Input() mediaPlatformFormatsInfo: MediaPlatformFormatInfo[];

  existingTrackImages: ImageFile[];
  existingTrackMedia: MediaFile[];
  finalisingChunksLoading = false;
  audioFormats: AudioFormat[];
  mediaProcessingInterval: any;

  constructor(
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private http: HttpClient,
    private showErrorService: ShowErrorService,
    private audioFormatsService: AudioFormatsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    if ('person track collection background_track'.split(' ').indexOf(this.subfolder) === -1) {
      throw new Error('invalid subfolder');
    }
    this.loadRecentImageAndMediaFiles();
    if (this.fileType !== FileTypes.Media) {
      return;
    }
    this.loadAudioFormats();
    this.processMediaPlatformFormatsInfo(this.mediaPlatformFormatsInfo);
  }

  ngOnDestroy(): void {
    clearInterval(this.mediaProcessingInterval);
  }

  onAudioFormatChanged(event, platform) {
    const updatedPlatforms = this.platforms.value.map((p) => {
      let defaultAudioFormatId = p.defaultAudioFormatId;
      if (p.id === platform.id) {
        defaultAudioFormatId = event.value;
      }
      return {
        id: p.id,
        title: p.title,
        defaultAudioFormatId,
      };
    });
    this.platforms.setValue(updatedPlatforms);
  }

  /**
   * Handle file drop event and validate the file before starting the upload process.
   *
   * @param $event - The event object containing the dropped file.
   */
  onFileDropped($event) {
    const file = $event[0];
    const format = file.name.split('.').pop().toLowerCase();

    if (this.fileType === FileTypes.Media) {
      this.handleMediaFileUpload(file, format);
    } else if (this.fileType === FileTypes.Image) {
      this.handleImageFileUpload(file, format);
    } else {
      this.handleFileError(Errors.UNSUPPORTED_FILE_ERROR);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event) {
    this.onFileDropped($event.target.files);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  /**
   * Removes the file preview from the UI.
   *
   * @param event - The event object containing the file preview.
   */
  removeFilePreview(event) {
    event.preventDefault();
    this.mediaPlatformFormatsInfo = [];
    this.invalidateFile();
  }

  /**
   * Invalidates the file.
   */
  private invalidateFile() {
    this.file = null;
    this.fileDropEl.nativeElement.value = '';
    this.fileIdCtrl.setValue(null);
  }

  /**
   * Validate a media file based on its format and size. And then upload to server.
   *
   * @param file - The uploaded image file.
   * @param format - The file format to be validated.
   */
  private handleMediaFileUpload(file: File, format: string) {
    const oneGBInBytes = 1024 * 1024 * 1024; // 1GB in bytes

    // If file size is less than 1 GB, only allow uncompressed WAV files.
    if (file.size <= oneGBInBytes && format !== FileFormats.WAV) {
      this.handleFileError(Errors.UNCOMPRESSED_FILE_ERROR);
      return;
    }

    // If file size is greater than 1 GB, allow both uncompressed WAV and MP3 files.
    if (file.size > oneGBInBytes && !(format === FileFormats.WAV || format === FileFormats.MP3)) {
      this.handleFileError(Errors.UNSUPPORTED_FILE_ERROR);
      return;
    }

    this.startUpload(file);
  }

  /**
   * Validate an image file based on its format and resolution. And then upload to server.
   *
   * @param file - The uploaded image file.
   * @param format - The file format to be validated.
   */
  private handleImageFileUpload(file: File, format: string) {
    if (!(format === FileFormats.JPG || format === FileFormats.JPEG || format === FileFormats.PNG)) {
      this.handleFileError(Errors.UNSUPPORTED_FILE_ERROR);
      return;
    }

    const minimumResolution = 2048;
    this.validateImageResolution(file, minimumResolution)
      .then((isValid) => {
        if (isValid) {
          this.startUpload(file);
        }
      })
      .catch((error) => {
        this.invalidateFile();
        this.showErrorService.showError(error);
      });
  }

  /**
   * Handle file validation errors by invalidating the file and showing an error message.
   *
   * @param errorMessage - The error message to be displayed.
   */
  private handleFileError(errorMessage: string) {
    this.invalidateFile();
    this.showErrorService.showError(errorMessage);
  }

  /**
   * Validate the resolution of the uploaded image file.
   *
   * @param file - The uploaded image file.
   * @param minimumResolution - The minimum required resolution for the image.
   * @returns A promise that resolves to true if the image meets the minimum resolution, otherwise rejects with an error message.
   */
  private validateImageResolution(file: File, minimumResolution: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const uploadedImage = new Image();
        uploadedImage.onload = () => {
          if (uploadedImage.width < minimumResolution || uploadedImage.height < minimumResolution) {
            reject(`The uploaded image resolution of ${uploadedImage.width}x${uploadedImage.height} is too small.
                  The minimum image resolution is ${minimumResolution}x${minimumResolution}.
                  The recommended resolution is 3840x3840 or larger.`);
          } else {
            resolve(true);
          }
        };
        uploadedImage.src = e.target.result as string;
      };
      fileReader.readAsDataURL(file);
    });
  }

  /**
   * Start the upload process by setting the file and calling the upload function.
   *
   * @param file - The file to be uploaded.
   */
  private startUpload(file: File) {
    this.file = file;
    this.fileDropEl.nativeElement.value = '';
    this.uploadFileInChunks();
  }

  /**
   * Simulate the upload process by splitting the file into chunks and uploading each chunk concurrently.
   */
  private uploadFileInChunks() {
    const MB = 1024 * 1024;
    const CHUNK_SIZE = 99 * MB;
    const TIMEOUT_DURATION = 60000 * 10; // 10 minutes
    const CONCURRENCY_LIMIT = 4;

    this.fileIdCtrl.setValue(null);
    const curFile = this.file;
    curFile.progress = 0;
    curFile.loaded = 0;

    // Split file into chunks
    const chunks = this.createFileChunks(curFile, CHUNK_SIZE);
    const totalChunks = chunks.length;

    // Upload chunks concurrently
    this.slumberUploadTrackerService.uploadCount += 1;
    const fullChunkBody = [];

    from(chunks)
      .pipe(mergeMap((chunk) => this.uploadChunk(chunk, curFile, TIMEOUT_DURATION), CONCURRENCY_LIMIT))
      .subscribe({
        next: (chunkBody) => {
          if (chunkBody) {
            fullChunkBody.push(chunkBody);
          }
          if (fullChunkBody.length === totalChunks) {
            this.finalizeUpload(fullChunkBody, curFile);
          }
        },
        error: (err) => {
          console.log(err);
          Bugsnag.notify(err);
          this.showErrorService.showError(Errors.CHUNK_UPLOAD_ERROR);
          this.slumberUploadTrackerService.uploadCount -= 1;
        },
      });
  }

  /**
   * Splits the given file into chunks of the specified size.
   *
   * @param file - The file to be split into chunks.
   * @param chunkSize - The size of each chunk in bytes.
   * @returns An array of objects, each containing a chunk and its serial number.
   */
  private createFileChunks(file: File, chunkSize: number): { chunk: Blob; serial: number }[] {
    const chunks = [];
    for (let offset = 0, serial = 1; offset < file.size; offset += chunkSize, serial++) {
      const chunk = file.slice(offset, offset + chunkSize);
      chunks.push({ chunk, serial });
    }
    return chunks;
  }

  /**
   * Uploads a single chunk of the file to the server.
   *
   * @param chunkBody - An object containing the chunk and its serial number.
   * @param curFile - The current file being uploaded.
   * @param timeoutDuration - The maximum time to wait for the upload to complete, in milliseconds.
   * @returns An observable that emits the response from the server.
   */
  private uploadChunk(chunkBody: { chunk: Blob; serial: number }, curFile: any, timeoutDuration: number) {
    const uploadChunkPath = '/api/upload/chunk';
    const chunkFormData: FormData = new FormData();
    chunkFormData.append('serial', chunkBody.serial.toString());
    chunkFormData.append('file', chunkBody.chunk);

    let prevLoaded = 0;

    return this.http.post(uploadChunkPath, chunkFormData, { reportProgress: true, observe: 'events' }).pipe(
      timeout(timeoutDuration),
      tap((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          curFile.loaded += event.loaded - prevLoaded;
          prevLoaded = event.loaded;
          curFile.progress = (100 * curFile.loaded) / curFile.size;
        }
      }),
      map((event: HttpEvent<{ tmpFileName: string }>) => {
        if (event.type === HttpEventType.Response) {
          return event.body;
        }
      }),
    );
  }

  /**
   * Finalizes the upload process by sending the metadata of all uploaded chunks to the server.
   *
   * @param fullChunkBody - An array of objects containing the temporary file names and serial numbers of the uploaded chunks.
   * @param curFile - The current file being uploaded.
   */
  private finalizeUpload(fullChunkBody: { tmpFileName: string; serial: number }[], curFile: any) {
    curFile.progress = 100;

    const sortedFullChunkBody = fullChunkBody.sort((a, b) => a.serial - b.serial);
    const chunkNames = sortedFullChunkBody.map((chunk) => chunk.tmpFileName);

    const path = '/api/upload';
    const formData: FormData = new FormData();
    formData.append('tmpFileNames', chunkNames.join(' '));
    formData.append('subfolder', this.subfolder);
    formData.append('fileType', this.fileType);
    formData.append('filename', curFile.name);
    formData.append('size', curFile.size.toString());
    formData.append('durationSeconds', '0');
    formData.append('contentType', curFile.type);

    this.finalisingChunksLoading = true;
    this.http.post<FileUploadResponse>(path, formData).subscribe({
      next: (resp) => {
        this.handleUploadSuccess(resp, curFile);
      },
      error: (err) => {
        this.handleUploadError(err);
      },
    });
  }

  /**
   * Handles the success response of the final upload request.
   *
   * @param resp - The response from the server containing the file ID.
   * @param curFile - The current file being uploaded.
   */
  private handleUploadSuccess(resp: FileUploadResponse, curFile: any) {
    if (curFile === this.file) {
      this.finalisingChunksLoading = false;
      this.fileIdCtrl.setValue(resp.fileId);
      this.slumberUploadTrackerService.uploadCount -= 1;

      if (this.fileType === FileTypes.Media) {
        this.snackBar.open('Uploaded media is being processed in background', 'Close', { duration: 5000 });
        this.pollMediaProcessing();
      }
    } else {
      console.log('file selection changed before upload completed');
      this.slumberUploadTrackerService.uploadCount -= 1;
    }
  }

  /**
   * Handles errors that occur during the final upload request.
   *
   * @param err - The error object.
   */
  private handleUploadError(err: any) {
    Bugsnag.notify(err);
    this.showErrorService.showError(err.error.description);
    this.finalisingChunksLoading = false;
    this.slumberUploadTrackerService.uploadCount -= 1;
  }

  /**
   * Polls the server to check if the media file processing has completed.
   */
  private pollMediaProcessing() {
    this.mediaProcessingInterval = setInterval(() => {
      this.loadMediaFileProcessed(this.fileIdCtrl.value);
    }, 3000);
  }

  /**
   * Loads the processed media file information from the server along with platform and audio formats.
   * @param mediaId - The ID of the media file.
   */
  private loadMediaFileProcessed(mediaId: number) {
    if (!mediaId || mediaId === 0) {
      return;
    }
    this.audioFormatsService.getMediaPlatformFormatsInfo(mediaId).subscribe((mediaPlatformFormatsInfo) => {
      this.processMediaPlatformFormatsInfo(mediaPlatformFormatsInfo);

      if (this.mediaPlatformFormatsInfo.length >= this.platforms.value.length) {
        clearInterval(this.mediaProcessingInterval);
      }
    });
  }

  /**
   * Loads the 15 most recent image files and media files from the server.
   */
  private loadRecentImageAndMediaFiles() {
    if (this.subfolder === 'track' && this.fileType === FileTypes.Image) {
      this.http
        .get<ImageFile[]>('/api/image-files?limit=15') // load 15 most recent ones
        .subscribe((imageFiles) => (this.existingTrackImages = imageFiles));
    }

    if (this.subfolder === 'track' && this.fileType === FileTypes.Media) {
      this.http
        .get<MediaFile[]>('/api/media-files?limit=15') // load 15 most recent ones
        .subscribe((mediaFiles) => {
          this.existingTrackMedia = mediaFiles.filter((media) => {
            const format = media.url.split('.').pop();
            if (format === FileFormats.WAV) {
              return media;
            }
          });
        });
    }
  }

  /**
   * Loads the available audio formats from the server.
   */
  private loadAudioFormats() {
    this.audioFormatsService.getAudioFormats().subscribe((formats) => {
      this.audioFormats = formats;
    });
  }

  /**
   * Processes the media platform formats information and filters out unprocessed media files.
   * @param mediaPlatformFormatsInfo
   */
  private processMediaPlatformFormatsInfo(mediaPlatformFormatsInfo: MediaPlatformFormatInfo[]) {
    if (mediaPlatformFormatsInfo.length === 0) {
      return;
    }

    this.mediaPlatformFormatsInfo = [];
    mediaPlatformFormatsInfo.forEach((mediaPlatformFormatInfo) => {
      if (mediaPlatformFormatInfo.mediaFileProcessed.url !== '') {
        this.mediaPlatformFormatsInfo.push(mediaPlatformFormatInfo);
      }
    });
  }
}
