import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PeopleList, PeopleService } from '../people.service';
import { HttpClient } from '@angular/common/http';
import { PersonInfo } from '../people.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.scss'],
})
export class PersonListComponent implements OnInit, AfterViewInit {
  peopleList = this.peopleService.peopleList;
  people = new MatTableDataSource<PersonInfo>();
  displayedColumns: string[] = [
    'person.id',
    'person.imageFile.url',
    'person.personTranslation.displayName',
    'person.gender',
    'person.personTranslation.detail',
    'isNarrator',
    'edit',
  ];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private http: HttpClient, private peopleService: PeopleService) {}

  ngOnInit() {
    this.reloadPeople();
  }

  reloadPeople() {
    this.peopleService.reload();
    this.peopleService.peopleList$.subscribe((newData: PeopleList) => {
      this.people.data = newData.people;
    });
  }

  ngAfterViewInit() {
    this.people.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'person.id':
          return item.person.id;
        case 'person.gender':
          return item.person.gender;
        case 'person.personTranslation.displayName':
          return item.person.personTranslation.displayName;
        case 'person.personTranslation.detail':
          return item.person.personTranslation.detail;
        default:
          return item[property];
      }
    };
    this.people.sort = this.sort;
  }
}
