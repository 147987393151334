<h1>Create Background Track</h1>
@if (!backgroundTrackFormGroup) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (backgroundTrackFormGroup) {
  <form (ngSubmit)="save()">
    <app-background-tracks-form-elements
      [backgroundTrackFormGroup]="backgroundTrackFormGroup"
      [mediaPlatformFormatsInfo]="mediaPlatformFormatsInfo"
      parentActionType="createAction"
    >
    </app-background-tracks-form-elements>
    <div>
      <a routerLink=".." mat-button> Cancel </a>
      <button [disabled]="backgroundTrackFormGroup.invalid" type="submit" mat-button>Create Background Track</button>
      @if (loadingCount) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </form>
}
