import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MediaFile } from './track/track.model';

export interface AudioFormat {
  id: number;
  title: string;
  format: string;
}

export interface Platform {
  id: number;
  title: string;
  defaultAudioFormatId: number;
}

export interface MediaFileProcessed {
  id: number;
  path: string;
  url: string;
  size: number;
  mediaFileId: number;
  audioFormatId: number;
  mediaFile: MediaFile;
  audioFormat: AudioFormat;
}

export interface MediaPlatformFormatInfo {
  mediaFileProcessed: MediaFileProcessed;
  platform: Platform;
}

@Injectable({
  providedIn: 'root',
})
export class AudioFormatsService {
  constructor(private http: HttpClient) {}

  getAudioFormats(): Observable<AudioFormat[]> {
    return this.http.get<AudioFormat[]>('/api/audio-formats');
  }

  getPlatforms(): Observable<Platform[]> {
    return this.http.get<Platform[]>('/api/platforms');
  }

  getMediaPlatformFormatsInfo(mediaId: number): Observable<MediaPlatformFormatInfo[]> {
    const url = `/api/media-files-processed/${mediaId}`;
    return this.http.get<MediaPlatformFormatInfo[]>(url);
  }
}
