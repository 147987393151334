import { Component, Input, OnInit } from '@angular/core';

export interface ConfirmBoolOpts {
  title: string;
  trueButtonText: string;
  falseButtonText: string;
}

@Component({
  selector: 'app-confirm-bool',
  templateUrl: './confirm-bool.component.html',
  styleUrls: ['./confirm-bool.component.scss'],
})
export class ConfirmBoolComponent implements OnInit {
  @Input() confirmBoolOpts: ConfirmBoolOpts;
  @Input() showFalse = true;

  constructor() {}

  ngOnInit(): void {}
}
