import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, merge, of } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

type Opt = [string, any | null];

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
})
export class ChipListComponent implements OnInit {
  @Input() selectedOptsFormControl: AbstractControl;
  @Input() optsAll: Opt[];
  @Input() label: string;
  @Input() disableAdd = false;
  @Input() removeIfAddTwice = true;
  isSelected = {};
  selectedCount = 0;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCurrentFC = new FormControl<string | null>(null); // for autocomplete
  chipsAllFiltered$: Observable<Opt[]>;

  constructor() {}

  ngOnInit(): void {
    if (this.selectedOptsFormControl.value === null) {
      console.log('error, setting array for chip-list');
      this.selectedOptsFormControl.setValue([]);
    }
    this.chipsAllFiltered$ = this.chipCurrentFC.valueChanges.pipe(
      startWith(null as string),
      debounceTime(50),
      map((s: string | null) => this._filter(s))
    ); // autocomplete suggestions

    merge(of(this.selectedOptsFormControl.value), this.selectedOptsFormControl.valueChanges).subscribe(
      (chips: Opt[]) => {
        this.selectedCount = chips.length;
        this.isSelected = {};
        for (const opt of chips) {
          this.isSelected[opt[0]] = true;
        }
      }
    );
  }

  add(event: MatChipInputEvent): void {
    let value = event.value;
    // Reset the input value
    if (event.input) {
      event.input.value = '';
    }

    if ((value || '').trim()) {
      const chips = this.selectedOptsFormControl.value.slice();
      value = value.trim().toLowerCase();
      const alreadySelected = chips.some((opt: Opt) => opt[0].toLowerCase() === value);
      if (alreadySelected) {
        if (this.removeIfAddTwice) {
          this.selectedOptsFormControl.setValue(chips.filter((opt: Opt) => opt[0].toLowerCase() !== value));
        }
      } else {
        let alreadyExists = false;
        let opt: Opt = [value, null];
        for (const existingOpt of this.optsAll) {
          if (existingOpt[0].toLowerCase() === value) {
            opt = existingOpt;
            alreadyExists = true;
          }
        }
        if (!this.disableAdd || alreadyExists) {
          chips.push(opt);
          this.selectedOptsFormControl.setValue(chips);
        }
      }
    }
    this.chipCurrentFC.setValue(null);
  }

  remove(chip: string): void {
    const chips = this.selectedOptsFormControl.value.filter(
      (opt: Opt) => opt[0].toLowerCase() !== chip[0].toLowerCase()
    );
    this.selectedOptsFormControl.setValue(chips);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const chips = this.selectedOptsFormControl.value.slice();
    const optStr = event.option.value;
    const alreadySelected = this.selectedOptsFormControl.value.some((opt: Opt) => opt[0] === optStr);
    if (alreadySelected) {
      if (this.removeIfAddTwice) {
        this.selectedOptsFormControl.setValue(chips.filter((opt: Opt) => opt[0] !== optStr));
      }
    } else {
      let addedCount = 0;
      for (const opt of this.optsAll) {
        if (opt[0] === optStr) {
          addedCount += 1;
          chips.push(opt);
        }
      }
      if (addedCount === 0) {
        console.log('opt not found, adding with null id', optStr);
        chips.push([optStr, null]);
      } else if (addedCount > 1) {
        console.log('error, opt added multiple times');
      }
      this.selectedOptsFormControl.setValue(chips);
    }
    this.chipCurrentFC.setValue(null);
  }

  private _filter(value: string | null): Opt[] {
    if (value === null || value === '') {
      return this.optsAll.slice();
    }
    const filterValue = value.toLowerCase();
    return this.optsAll.filter((chip) => {
      const chipWord = chip[0].replace('(', '').replace(')', '').toLowerCase().trim();
      const chipWords = chipWord.split(' ');
      for (const word of chipWords) {
        if (word.indexOf(filterValue) === 0) {
          return true;
        }
      }
      return filterValue.length > 3 && chipWord.includes(filterValue);
    });
  }
}
