import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { AudioFormatsService, MediaPlatformFormatInfo, Platform } from 'src/app/audio-formats.service';
import { ContentTypes, StatusCodes, TenantAppRouter } from '../../constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { TrackResponse } from '../track.model';
import { TracksService } from '../tracks.service';

@Component({
  selector: 'app-edit-track',
  templateUrl: './edit-track.component.html',
  styleUrls: ['./edit-track.component.scss'],
})
export class EditTrackComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  isDefaultSlumber = true;
  trackFormGroup: FormGroup = null;

  initialForm: any;
  trackResponse: TrackResponse;
  mediaPlatformFormatsInfo: MediaPlatformFormatInfo[] = [];

  constructor(
    private fb: NonNullableFormBuilder,
    private trackService: TracksService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
    private audioFormatsService: AudioFormatsService,
    private snackBar: MatSnackBar,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.trackFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.trackFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.loadingCount += 1;
    this.trackService
      .getTrack(this.activeRoute.snapshot.params.id)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((trackResponse: TrackResponse) => {
        this.trackResponse = trackResponse;

        this.audioFormatsService.getPlatforms().subscribe((platforms: Platform[]) => {
          if (trackResponse.track.mediaFileId) {
            this.audioFormatsService
              .getMediaPlatformFormatsInfo(trackResponse.track.mediaFileId)
              .subscribe((mediaPlatformFormatsInfo) => {
                this.mediaPlatformFormatsInfo = mediaPlatformFormatsInfo;

                mediaPlatformFormatsInfo.forEach((mediaPlatformFormatInfo) => {
                  platforms.forEach((platform, index) => {
                    if (platform.id === mediaPlatformFormatInfo.platform.id) {
                      platforms[index].defaultAudioFormatId = mediaPlatformFormatInfo.mediaFileProcessed.audioFormat.id;
                    }
                  });
                });

                this.initForm(trackResponse, platforms);
              });
          } else {
            this.initForm(trackResponse, platforms);
          }
        });
      });

    let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
    if (defaultAppFromURL === TenantAppRouter.DSS) {
      this.isDefaultSlumber = false;
    }
  }

  initForm(trackResponse: TrackResponse, platforms: Platform[]): void {
    this.trackFormGroup = this.fb.group(
      {
        track: this.fb.group({
          key: { value: trackResponse.track.key, disabled: true },
          id: { value: trackResponse.track.id, disabled: true },
          translationContext: trackResponse.track.translationContext,
          isPremium: trackResponse.track.isPremium,
          imageFileId: trackResponse.track.imageFileId,
          mediaFileId: trackResponse.track.mediaFileId,
          contentTypeId: trackResponse.track.contentTypeId,
          statusCode: [trackResponse.track.statusCode, Validators.required],
          releasedAt: trackResponse.track.releasedAt,
          unpublishedAt: trackResponse.track.unpublishedAt,
          trackTranslation: this.fb.group({
            id: trackResponse.track.trackTranslation.id,
            trackId: trackResponse.track.trackTranslation.trackId,
            title: [trackResponse.track.trackTranslation.title, Validators.required],
            detail: trackResponse.track.trackTranslation.detail,
            transcript: trackResponse.track.trackTranslation.transcript,
            languageId: trackResponse.track.trackTranslation.languageId,
          }),
        }),
        narrators: [trackResponse.narrators],
        authors: [trackResponse.authors],
        categories: [[...new Set(trackResponse.categories)]], // remove duplicates
        collections: [trackResponse.collections],
        tags: [trackResponse.tags.map((tag) => [tag.title, tag.id])],
        mixId: trackResponse.mixId,
        platforms: [platforms],
      },
      { validators: this.publishingRequirementsValidator.bind(this) },
    );

    this.initialForm = this.trackFormGroup.value;
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    if (deepEqual(this.initialForm, formGroup.value)) {
      return { changeRequired: true };
    }
    return this.trackService.checkFormError(
      formGroup,
      parseInt(formGroup.get('track.id').value),
      this.isDefaultSlumber,
    );
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const trackPayload = this.trackService.makeCreateOrUpdateTrackPayload(this.trackFormGroup.getRawValue());
      const [warnings, _] = this.trackService.cleanForm(trackPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });

      if (
        trackPayload.track.contentTypeId !== ContentTypes.Music &&
        trackPayload.mixId === null &&
        this.isDefaultSlumber
      ) {
        if (trackPayload.track.statusCode === StatusCodes.DRAFT || trackPayload.track.statusCode === StatusCodes.BETA) {
          this.snackBar.open('A mix is currently missing. It will be required for publishing', 'Close', {
            duration: 5000,
          });
        } else {
          this.snackBar.open('A mix is currently missing. It is required for publishing', 'Close', { duration: 5000 });
          return;
        }
      }

      this.update(trackPayload);
    });
  }

  update(trackPayload) {
    this.loadingCount += 1;
    this.trackService
      .updateTrack(trackPayload)
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe({
        next: () => {
          this.saved = true;
          let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
          this.router.navigateByUrl(`${defaultAppFromURL}/tracks`);
        },
        error: (e) => {
          this.showErrorService.showError(Errors.TRACK_UPDATE_ERROR);
        },
      });
  }

  deleteTrack() {
    this.dialogService
      .openConfirmBool({
        title: 'Are you sure you want to delete this track',
        trueButtonText: 'Delete',
        falseButtonText: 'Cancel',
      })
      .afterClosed()
      .subscribe((confirmDelete) => {
        if (confirmDelete) {
          this.loadingCount += 1;
          this.trackService
            .deleteTrack(this.activeRoute.snapshot.params.id)
            .pipe(finalize(() => (this.loadingCount -= 1)))
            .subscribe({
              next: () => {
                this.saved = true;
                let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
                this.router.navigateByUrl(`${defaultAppFromURL}/tracks`);
              },
              error: (e) => {
                this.showErrorService.showError(Errors.TRACK_DELETE_ERROR);
              },
            });
        }
      });
  }
}
