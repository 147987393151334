import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { isTrimmed } from '../utils';

export interface Mix {
  id: number;
  title: string;
}

export interface SelectedBackgroundTrack {
  backgroundTrackId: number;
  volume: number;
}

export interface MixInfo {
  mix: Mix;
  mixBackgroundTrackCount: number;
  mixTrackCount: number;
  selectedBackgroundTracks: SelectedBackgroundTrack[];
  v;
}

@Injectable({
  providedIn: 'root',
})
export class MixesService {
  mixes: MixInfo[];
  constructor(private http: HttpClient) {}

  getMixes(): Observable<MixInfo[]> {
    return this.http.get<MixInfo[]>('/api/mixes');
  }

  getMix(id: any) {
    return this.http.get<MixInfo>(`/api/mixes/${id}`);
  }

  makeCreateOrUpdateMixPayload(mixFormGroupValue) {
    return {
      ...mixFormGroupValue,
    };
  }

  updateMix<T>(mixPayload: T) {
    return this.http.put<T>(`/api/mixes`, mixPayload);
  }

  createMix<T>(mixPayload: T) {
    return this.http.post<T>(`/api/mixes`, mixPayload);
  }

  cleanForm(mixPayload: any): [string[], boolean] {
    const warnings: string[] = [];

    if (isTrimmed(mixPayload.mix.title) === false) {
      mixPayload.mix.title = mixPayload.mix.title.trim();
      if (!mixPayload.mix.title) {
        return [warnings, false];
      }
      warnings.push('Title has been trimmed automatically');
    }
    return [warnings, true];
  }

  checkFormError(formGroup: FormGroup, mixId: number) {
    const mixPayload = this.makeCreateOrUpdateMixPayload(formGroup.getRawValue());
    const [, isClean] = this.cleanForm(mixPayload);
    if (isClean === false) {
      return { cleanFormRequired: true };
    }

    if (this.mixes) {
      for (const mix of this.mixes) {
        if (mix.mix.id === mixId) continue;
        if (formGroup.get('mix.title').value.toLowerCase() === mix.mix.title.toLowerCase()) {
          return { mixTitleExists: true };
        }
      }
    }

    return {};
  }
}
