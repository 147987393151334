import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatetimePickerModule } from './datetime-picker/datetime-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { ChipListComponent } from './chip-list/chip-list.component';
import { FormElementComponent } from './form-element/form-element.component';
import { UploadModule } from '../upload/upload.module';
import { PublishStatusComponent } from './publish-status/publish-status.component';
import { ChipList2Component } from './chip-list2/chip-list2.component';

@NgModule({
  declarations: [ChipListComponent, FormElementComponent, PublishStatusComponent, ChipList2Component],
  imports: [CommonModule, DatetimePickerModule, FormsModule, ReactiveFormsModule, MaterialModule, UploadModule],
  exports: [
    CommonModule,
    DatetimePickerModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    UploadModule,
    FormElementComponent,
    ChipListComponent,
    ChipList2Component,
    PublishStatusComponent,
  ],
})
export class FormElementsModule {}
