import { Component, OnInit } from '@angular/core';
import { ApiExplorerService } from '../api-explorer.service';
import { LibraryVersion, MobileClient, SwaggerDocumentationLibrary } from '../../constants';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-api-explorer',
  templateUrl: './api-explorer.component.html',
  styleUrls: ['./api-explorer.component.scss'],
})
export class ApiExplorerComponent implements OnInit {
  apiResponse: any;
  apiURLs: URL[] = [];
  selectedMobileClient: MobileClient;
  selectedApiURL: URL;

  constructor(
    private apiExplorerService: ApiExplorerService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) {}

  copyTapped() {
    this.clipboard.copy(JSON.stringify(this.apiResponse));
    this.snackBar.open('The full library JSON is copied to the clipboard', 'Close', { duration: 3000 });
  }

  documentationTapped() {
    window.open(SwaggerDocumentationLibrary, '_blank');
  }

  ngOnInit(): void {
    const params = 'lastBuildTimestamp=0&languageId=1';

    Object.keys(LibraryVersion).forEach((version) => {
      const forceLibraryPath = `/${LibraryVersion[version]}/forceLibrary?${params}`;
      const bypassEdgeCachePath = `/${LibraryVersion[version]}/library?bypassEdgeCache=true&${params}`;
      const forceLibraryApiURL = new URL(`${window.location.protocol}//${window.location.host}${forceLibraryPath}`);
      const bypassEdgeCacheApiURL = new URL(
        `${window.location.protocol}//${window.location.host}${bypassEdgeCachePath}`
      );

      this.apiURLs.push(bypassEdgeCacheApiURL, forceLibraryApiURL);
    });

    this.selectedMobileClient = MobileClient.IOS;
    this.selectedApiURL = this.apiURLs[0];
    this.loadData(this.selectedMobileClient, this.selectedApiURL);
  }

  onApiURLChanged(event): void {
    this.apiResponse = null;
    this.selectedApiURL = event.value;
    this.loadData(this.selectedMobileClient, this.selectedApiURL);
  }

  onMobileClientChange(event): void {
    this.apiResponse = null;
    this.selectedMobileClient = event.value;
    this.loadData(this.selectedMobileClient, this.selectedApiURL);
  }

  loadData(mobileClient: MobileClient, apiURL: URL) {
    this.apiExplorerService.getLibrary(mobileClient, apiURL.pathname + apiURL.search).subscribe((data) => {
      this.apiResponse = data;
    });
  }
}
