<div class="label">{{ label }} (in {{ timeZone }})</div>
<div class="container">
  <mat-form-field style="width: 100%">
    <mat-label> Day </mat-label>
    <input
      (focus)="picker.open()"
      matInput
      [formControl]="dateCtrl"
      [placeholder]="label + ' Day'"
      [matDatepicker]="picker"
    />

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Time</mat-label>
    <input matInput
           name="time"
           [formControl]="timeCtrl"
           [ngxMatTimepicker]="pickerTime"
           placeholder="10:00 AM"
           readonly />
    <mat-icon matSuffix (click)="pickerTime.open()">
      watch_later
    </mat-icon>
  </mat-form-field>
  <ngx-mat-timepicker #pickerTime enableKeyboardInput="true"></ngx-mat-timepicker>
</div>
