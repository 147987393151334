import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { deepEqual } from 'fast-equals';
import { finalize } from 'rxjs/operators';
import { CategoryTypes, LANGUAGE_ID, StatusCodes } from '../../constants';
import { CanComponentDeactivate } from '../../dialog/can-deactivate-guard.service';
import { DialogService } from '../../dialog/dialog.service';
import { ShowErrorService } from '../../error-reporting/show-error.service';
import { Errors } from '../../errors';
import { SlumberUploadTrackerService } from '../../upload/slumber-upload-tracker.service';
import { getTenantAppRouterFromURL } from '../../utils';
import { CategoriesService } from '../categories.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss'],
})
export class CreateCategoryComponent implements OnInit, CanComponentDeactivate {
  saved = false;
  loadingCount = 0;
  categoryFormGroup = this.fb.group(
    {
      category: this.fb.group({
        key: '',
        statusCode: [StatusCodes.DRAFT, Validators.required],
        releasedAt: null,
        unpublishedAt: null,
        categoryTypeId: { value: CategoryTypes.Server, disabled: true },
        translationContext: '',
        categoryTranslation: this.fb.group({
          title: ['', Validators.required],
          detail: '',
          languageId: LANGUAGE_ID,
        }),
      }),
      items: [[]],
    },
    { validators: this.publishingRequirementsValidator.bind(this) },
  );

  initialForm: any;

  constructor(
    private fb: NonNullableFormBuilder,
    private categoriesService: CategoriesService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private showErrorService: ShowErrorService,
    private slumberUploadTrackerService: SlumberUploadTrackerService,
    private dialogService: DialogService,
  ) {}

  @HostListener('window:beforeunload')
  canCloseTab() {
    return this.categoryFormGroup.untouched;
  }

  canDeactivate() {
    if (!deepEqual(this.initialForm, this.categoryFormGroup.value) && !this.saved) {
      return this.dialogService.confirmCanDeactivate();
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.initialForm = this.categoryFormGroup.value;
  }

  publishingRequirementsValidator(formGroup: FormGroup) {
    if (!this.initialForm) return {};
    return this.categoriesService.checkFormError(formGroup, 0);
  }

  save() {
    this.slumberUploadTrackerService.confirmUploadsDone().subscribe(() => {
      const categoryPayload = this.categoriesService.makeCreateOrUpdateCategoryPayload(
        this.categoryFormGroup.getRawValue(),
      );
      const [warnings, _] = this.categoriesService.cleanForm(categoryPayload);
      warnings.forEach((warning) => {
        this.showErrorService.showWarning(warning);
      });
      this.loadingCount += 1;
      this.categoriesService
        .createCategory(categoryPayload)
        .pipe(finalize(() => (this.loadingCount -= 1)))
        .subscribe({
          next: () => {
            this.saved = true;
            let defaultAppFromURL = getTenantAppRouterFromURL(this.router.url);
            this.router.navigateByUrl(`${defaultAppFromURL}/categories`);
          },
          error: (e) => {
            this.showErrorService.showError(Errors.CATEGORY_CREATE_ERROR);
          },
        });
    });
  }
}
