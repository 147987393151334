<h1>Edit Collection</h1>
@if (!collectionFormGroup) {
  <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
}
@if (collectionFormGroup) {
  <form (ngSubmit)="save()">
    <app-collection-form-elements [collectionFormGroup]="collectionFormGroup"> </app-collection-form-elements>
    <div>
      <a routerLink="../.." mat-button> Cancel </a>
      <button [disabled]="collectionFormGroup.invalid" type="submit" mat-button>Update Collection</button>
      @if (loadingCount) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  </form>
}
