import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { BackgroundTrackInfo, BackgroundTracksService } from '../background-tracks.service';

@Component({
  selector: 'app-background-tracks',
  templateUrl: './background-tracks.component.html',
  styleUrls: ['./background-tracks.component.scss'],
})
export class BackgroundTracksComponent implements OnInit, AfterViewInit {
  loadingCount = 0;
  backgroundTracks = new MatTableDataSource<BackgroundTrackInfo>();

  displayedColumns: string[] = [
    'backgroundTrack.id',
    'backgroundTrack.imageFile.url',
    'backgroundTrack.backgroundTrackTranslation.title',
    'backgroundTrack.isMusic',
    'mixCount',
    'edit',
  ];

  @ViewChild(MatSort) sort: MatSort;

  constructor(private backgroundTrackService: BackgroundTracksService) {}

  ngOnInit(): void {
    this.readBackgroundTracks();
  }

  readBackgroundTracks() {
    this.loadingCount += 1;
    this.backgroundTrackService
      .getBackgroundTracks()
      .pipe(finalize(() => (this.loadingCount -= 1)))
      .subscribe((backgroundTracks) => {
        this.backgroundTracks.data = backgroundTracks;
        this.backgroundTrackService.backgroundTracks = backgroundTracks;
      });
  }

  ngAfterViewInit() {
    this.backgroundTracks.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'backgroundTrack.id':
          return item.backgroundTrack.id;
        case 'backgroundTrack.backgroundTrackTranslation.title':
          return item.backgroundTrack.backgroundTrackTranslation.title;
        case 'backgroundTrack.isMusic':
          return item.backgroundTrack.isMusic;
        default:
          return item[property];
      }
    };
    this.backgroundTracks.sort = this.sort;
  }
}
