import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { checkSimilarity } from 'src/app/utils';
import { MediaPlatformFormatInfo } from '../../audio-formats.service';
import { BackgroundTracksService } from '../background-tracks.service';

@Component({
  selector: 'app-background-tracks-form-elements',
  templateUrl: './background-tracks-form-elements.component.html',
  styleUrls: ['./background-tracks-form-elements.component.scss'],
})
export class BackgroundTracksFormElementsComponent implements OnInit {
  @Input() backgroundTrackFormGroup: FormGroup;
  @Input() mediaPlatformFormatsInfo: MediaPlatformFormatInfo[];
  @Input() parentActionType: 'createAction' | 'updateAction';

  statusCodeControl: FormControl;
  releasedAtControl: FormControl;
  unpublishedAtControl: FormControl;

  similarBackgroundTrackWarning: string | null;

  constructor(private backgroundTracksService: BackgroundTracksService) {}

  ngOnInit(): void {
    const c = this.backgroundTrackFormGroup.get('backgroundTrack');
    this.statusCodeControl = c.get('statusCode') as FormControl;
    this.releasedAtControl = c.get('releasedAt') as FormControl;
    this.unpublishedAtControl = c.get('unpublishedAt') as FormControl;
  }

  onFocusOutEvent(event: any) {
    let backgroundTrackId = 0;
    this.similarBackgroundTrackWarning = null;
    if (this.backgroundTrackFormGroup.get('backgroundTrack.id'))
      backgroundTrackId = parseInt(this.backgroundTrackFormGroup.get('backgroundTrack.id').value);
    if (!this.backgroundTracksService.backgroundTracks) return;

    const backgroundTrackTitles = this.backgroundTracksService.backgroundTracks
      .filter((backgroundTrack) => backgroundTrack.backgroundTrack.id != backgroundTrackId)
      .map((backgroundTrack) => backgroundTrack.backgroundTrack.backgroundTrackTranslation.title);
    const similarTitles = checkSimilarity(backgroundTrackTitles, event.target.value);
    if (similarTitles.length > 0) {
      this.similarBackgroundTrackWarning = `Similar background track titles: ${similarTitles.join(', ')}`;
    }
  }
}
